import React from 'react';
import './StaticHeroBanner.css';
import { useNavigate } from 'react-router-dom';
import { ReactTyped } from 'react-typed';


const StaticHeroBanner = () => {
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate('/about'); // Replace with your desired route
    };

    return (
        <div className="Static-hero main-home-hero-container">
            <div className="container">
                <div className="row align-items-center Static-hero">
                    <div className="col-lg-6">
                        <div className="static-content">
                            <h4 className='main-head'>Welcome to the Raza Foundation</h4>
                            <h1 className='head-h1'>
                                Empowering Communities with&nbsp;
                                <ReactTyped
                                    strings={["Compassion", "Care & Precision", "Lots of Love"]}
                                    typeSpeed={95}
                                    backSpeed={70}
                                    loop
                                />
                            </h1>

                            <p className='para_main'>Raza Foundation is at the forefront of humanitarian efforts, delivering essential services in food, health, education, and welfare. We strive to uplift underprivileged communities across Pakistan, ensuring support in over 50 critical areas of daily life.</p>
                            <button onClick={handleNavigate} className="connect-button d-flex">  <span>Explore Now</span>
                            {/* <img src='/images/icons/arrow-right.svg' alt='' className='arrow-icon' /> */}
                            </button>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <img src="/images/creatives/static-banner.png" alt="Banner" className="img-fluid" />
                        {/* <img src="/images/creatives/static-banner.webp" alt="Banner" className="img-fluid" /> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StaticHeroBanner;