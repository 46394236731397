import React from 'react'
import VisionAndMission from '../components/VisionAndMission/VisionAndMission'
import ChildHomeHero from '../components/ChildHomeHero/ChildHomeHero'
import NewsLetter from '../components/NewsLetter/NewsLetter'

const QuranEducationPage = () => {
    return (
        <div>


            <ChildHomeHero
                title="Introduction" bluespan="Educated in Quran" Para="The Educated in Quran service is dedicated to offering in-depth Quranic education to
                people seeking to enhance their understanding and practice of Islam. This initiative
                provides classes and resources to help individuals of all ages learn and reflect on the
                Quran's teachings. As emphasized in the Hadith, the Prophet Muhammad (PBUH) said,
                “The best of you are those who learn the Quran and teach it.” Through this service, we
                strive to fulfill this noble tradition, enabling individuals to grow spiritually and ethically
                through the guidance of the Quran." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
                button="Donate Now"
                image="/images/creatives/Educated-In-Quran.webp"
                background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" />

                <NewsLetter />
        </div>
    )
}

export default QuranEducationPage