import React from 'react'
import CommonHeroComponent from '../components/CommonHeroComponent/CommonHeroComponent'
import VisionAndMission from '../components/VisionAndMission/VisionAndMission'

const EducationPage = () => {
  return (
    <div>
      <CommonHeroComponent
        Heading="Education"
        Para="We provide education to poor and needy children, helping them build a better future through knowledge and skills."
        backgroundImg="/images/creatives/Education-Hero.jpg"
      />

      <VisionAndMission heading="About" bluespan=" Education" Para="Education is a powerful tool for breaking the cycle of poverty and empowering individuals. In Islam, seeking knowledge is highly valued. At Raza Foundation, we are committed to providing quality education to underprivileged children, enabling them to build a brighter future."
        image="/images/creatives/Education-One.png"
        
        background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" />

      <VisionAndMission flexdirection="row-reverse" heading="How Your Education" bluespan=" Donation Adds Value" Para="Donating to Raza Foundation's education programs helps provide essential resources like books, uniforms, and tuition fees for needy children. This act of charity aligns with Islamic teachings, promoting learning and growth. Your support helps transform lives, paving the way for educated and empowered communities." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
        image="/images/creatives/Education-Two.png"
      />

      <VisionAndMission heading="Ease of Donating " bluespan=" Education and Its Impact" Para="Supporting education through Raza Foundation is straightforward and highly rewarding. Our efficient process ensures your donations directly benefit students in need. By investing in education, you help build a knowledgeable and self-sufficient future generation, creating lasting change." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
        image="/images/creatives/Education-Three.png"
         />
    </div>
  )
}

export default EducationPage;