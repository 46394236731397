import React, { useState } from 'react';
import { Container, Card, Modal, Button } from 'react-bootstrap';
import './VideoSlider.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';

function VideoSlider() {
    const [showModal, setShowModal] = useState(false);
    const [playingVideo, setPlayingVideo] = useState(null);
    const [swiperInstance, setSwiperInstance] = useState(null);

    const cards = [
        {
            title: "12 Rabi ul Awal Youm e Wafat ya Youm e Wiladat? | بارہ ربیع الاول یوم وفات یا یوم ولادت؟",
            image: "/images/creatives/youtube-one.jpg",
            videoUrl: "https://www.youtube.com/embed/5ufpNffkqEA"
        },
        {
            title: "Mehfil e Milad ki Haqeeqat | Reality of Gatherings of Milad | Eid Milad un Nabi",
            image: "/images/creatives/youtube-two.jpg",
            videoUrl: "https://www.youtube.com/embed/VATU2qlhSm8"
        },
        {
            title: "Majid With Mufti | Kia Mah e Safar Manhoos hai ? | Month of Safar | English Subtitles",
            image: "/images/creatives/youtube-three.jpg",
            videoUrl: "https://www.youtube.com/embed/x7PUd4Qv4ZA"
        },
        {
            title: "Things To Know While having Fast || روزے کے ضَروری مَسائِل || Roze ke Masail",
            image: "/images/creatives/youtube-four.jpg",
            videoUrl: "https://www.youtube.com/embed/jnqaTf2YHZM"
        },
        {
            title: "Wo Konsi Sharait Hain Jin Ka Kayal Na Kiya Jae To Zakat Ada Nahi Hogi?",
            image: "/images/creatives/youtube-five.jpg",
            videoUrl: "https://www.youtube.com/embed/1mmxrfhdRVc"
        },
        {
            title: "Things To Know While having Fast || روزے کے ضَروری مَسائِل || Roze ke Masail",
            image: "/images/creatives/youtube-four.jpg",
            videoUrl: "https://www.youtube.com/embed/jnqaTf2YHZM"
        },
    ];

    const handleVideoClick = (videoUrl) => {
        setPlayingVideo(videoUrl);
        setShowModal(true);
        if (swiperInstance) {
            swiperInstance.autoplay.stop(); // Stop autoplay when modal opens
        }
    };

    const handleClose = () => {
        setShowModal(false);
        setPlayingVideo(null); // Reset video when modal closes
        if (swiperInstance) {
            swiperInstance.autoplay.start(); // Restart autoplay when modal closes
        }
    };

    return (
        <div className='video-slider-container container'>
            <div className='video-slider-text'>
                <h2 className="head-h3 text-center">Videos</h2>
                <p className='para_main text-center py-4'>Convallis vivamus at cras porta nibh velit aliquam eget in faucibus mi tristique aliquam ultrices sit cras nascetur Convallis vivamus at</p>
            </div>
            <Container className='video-grid'>
                <Swiper
                    spaceBetween={30}
                    slidesPerView={1}
                    breakpoints={{
                        640: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 30,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 40,
                        },
                    }}
                    loop={true}
                    autoplay={{ delay: 1500, disableOnInteraction: false }}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="video-slider"
                    onSwiper={setSwiperInstance}
                >
                    {cards.map((card, index) => (
                        <SwiperSlide key={index}>
                            <Card className="custom-card">
                                <div className='video-thumbnail' onClick={() => handleVideoClick(card.videoUrl)}>
                                    <Card.Img variant="top" src={card.image} className="card-thumbnail-image" />
                                    <button className="play-pause-button" onClick={() => handleVideoClick(card.videoUrl)}>
                                        ▶ {/* Show play icon */}
                                    </button>
                                </div>
                                <Card.Body className='card-body'>
                                    <Card.Title className='card-title'>{card.title}</Card.Title>
                                </Card.Body>
                            </Card>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Container>

            {/* Modal for video playback */}
            <Modal show={showModal} onHide={handleClose} size="lg" centered className="video-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Video Player</Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex justify-content-center align-items-center">
                    {playingVideo && (
                        <iframe
                            title="Video Player"
                            src={`${playingVideo}?autoplay=1`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            className="video-iframe"
                        />
                    )}
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default VideoSlider;
