import React from 'react'
import { useNavigate } from 'react-router-dom'
import "./PlainCenteredText.css"

function PlainCenteredText({
    heading="",
    para="",
    btn=false,
    btnText="",
    btnRef="/",
}) {
    const navigate = useNavigate()
  return (
    <div className='PlainCenteredText-container py-5'>
        <div className='container text-center py-5 px-lg-5'>
            <h3 className='main-heading body-heading'>{heading}</h3>
            <p className='para_main px-4 my-2 mb-4'>{para}</p>

            {
                btn &&
                <button 
                    className='connect-button'
                    onClick={()=>{navigate(btnRef)}}
                >
                    {btnText}
                </button>
            }

        </div>
    </div>
  )
}

export default PlainCenteredText