import React from 'react';
import { Link } from 'react-router-dom';
import './ChildHomeHero.css';

const ChildHomeHero = (props) => {
  let linkTo = '#';

  switch (props.button) {
    case 'About Us':
      linkTo = '/about';
      break;
    case 'Contact Us':
      linkTo = '/contact';
      break;
    case 'Services':
      linkTo = '/services';
      break;
    case 'Donate Now':
      linkTo = props.serviceId ? `/donations?service=${props.serviceId}` : '/donations';
      break;
    case 'Blog':
      linkTo = '/blog';
      break;
    default:
      linkTo = '#';
  }

  return (
    <div className="child-hero-container" style={{ background: props.background, padding: props.padding }}>
      <div className='container'>
        <div className={`row  ${props.flexdirection === 'row-reverse' ? 'flex-lg-row-reverse' : 'flex-lg-row'} flex-column`}>
          <div className="col-lg-6 right-section" style={{ padding: props.paddingImg }}>
            <img src={props.image} className='large-img' alt='banner-img' />
          </div>
          <div className="col-lg-6 left-section">
            <h2 className='secondary-h1'>{props.title}{props.aboutTitle}</h2>
            <h1 className='head-h1'>{props.heading}
              <span className='bluespan'>{props.bluespan}</span></h1>
            <div className="d-flex flex-container">
              <div className="text-container">
                <div>
                  <p className='para_main'>{props.Para}</p>
                  <br />
                  <p className='bluepara'>{props.bluepara}</p>
                  {props.button && (
                    <div className="button-container">
                      <Link to={linkTo} className='connect-button'>
                        {props.button}
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChildHomeHero;
