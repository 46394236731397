import React, { useState, useEffect, useRef } from 'react';
import './HappyCustomer.css';

// Stats with values in raw number format (no commas or symbols)
const stats = [
  { icon: '/images/icons/Lives-Saved.svg', value: 10000, label: 'Lives Saved' },
  { icon: '/images/icons/Education.svg', value: 5000, label: 'Education' },
  { icon: '/images/icons/meals-provided.svg', value: 15000, label: 'Meals Provided' },
  { icon: '/images/icons/Mosque-Construction.svg', value: 50, label: 'Mosques Constructed' },
  { icon: '/images/icons/people-supported-in-crises.svg', value: 20000, label: 'People Supported in Crises' }
];

const HappyCustomer = () => {
  const [counts, setCounts] = useState(stats.map(() => 0));
  const [hasAnimated, setHasAnimated] = useState(false);
  const sectionRef = useRef(null);

  const startCounting = (endValues) => {
    const duration = 6000;
    const fps = 120;
    const intervalTime = duration / fps;
    const steps = Math.ceil(duration / intervalTime);

    const newCounts = [...counts];

    endValues.forEach((endValue, index) => {
      const stepIncrement = endValue / steps;

      let currentStep = 0;
      const intervalId = setInterval(() => {
        currentStep++;
        newCounts[index] = Math.min(Math.round(stepIncrement * currentStep), endValue);

        setCounts([...newCounts]);

        if (currentStep >= steps) {
          clearInterval(intervalId);
        }
      }, intervalTime);
    });
  };


  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !hasAnimated) {
          setHasAnimated(true);
          startCounting(stats.map(stat => stat.value));
        }
      },
      { threshold: 0.3 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, [hasAnimated, counts]);

  return (
    <div className='customer-container' ref={sectionRef}>
      <div className="container text-center my-3">
        <h1 className='head-h1'>Every Donation, A New Beginning</h1>
        <p className='body-paragraph'>
          Witness the incredible impact of your generosity. See how Raza Foundation changes lives and brings hope to communities in need.
        </p>
        <div className="row content-customers">
          {stats.map((stat, index) => (
            <div className="col-md" key={index}>
              <img src={stat.icon} className="mb-3 custom-icons" alt={stat.label} />
              <h3 className="display-4" style={{ color: '#1F2F54' }}>
                {counts[index].toLocaleString()}+
              </h3>
              <p style={{ color: '#717C93' }}>{stat.label}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HappyCustomer;
