import React from 'react'
import CommonHeroComponent from '../components/CommonHeroComponent/CommonHeroComponent'
import VisionAndMission from '../components/VisionAndMission/VisionAndMission'

const GlobalReliefPage = () => {
  return (
    <div>
      <CommonHeroComponent
        Heading="Global Relief"
        Para="Your donations support our global relief efforts, providing aid to those affected by natural disasters and other crises worldwide."
        backgroundImg="/images/creatives/GlobalRelief-Hero.jpg"
      />

      <VisionAndMission heading="About" bluespan=" Global Relief" Para="Global relief efforts are crucial in providing aid to those affected by natural disasters, conflicts, and other crises such as rising Palestine issue, we whole heartedly condemn Palestinian genocide. We are all in to save our brother and sisters no matter what it takes. Islam emphasizes helping those in distress, regardless of their location. Raza Foundation's global relief programs deliver timely assistance to those who need it most." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
        image="/images/creatives/GlobalRelief-One.png"
        button="Donate Now"
        background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" />

      <VisionAndMission flexdirection="row-reverse" heading="How Your Global Relief" bluespan=" Donation Adds Value" Para="Your donations to Raza Foundation's global relief efforts provide immediate support such as food, water, medical aid, and shelter to victims of disasters to the people of Palestine. This act of kindness reflects Islamic values of compassion and solidarity. Your contributions save lives and restore hope in times of crisis." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
      button="Donate Now"
        image="/images/creatives/GlobalRelief-Two.png" />

         <VisionAndMission  heading="Ease of Donating for" bluespan=" Global Relief and Its Impact" Para="Contributing to global relief is easy with Raza Foundation's dedicated channels. Your donations are swiftly directed to areas of need, ensuring prompt assistance. By supporting global relief, you extend your compassion beyond borders, embodying the universal spirit of Islam." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
        image="/images/creatives/GlobalRelief-Three.png"
        button="Donate Now"/>
    </div>
  )
}

export default GlobalReliefPage;