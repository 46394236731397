import React from 'react';
import PrivacyPolicy from '../components/PrivacyPolicy/PrivacyPolicy';

const PrivacyPolicyPage = () => {
  return (
    <div>
      <PrivacyPolicy />
    </div>
  );
};

export default PrivacyPolicyPage