import React from 'react';
import Wedding from '../components/KhidmateKhalq/Wedding';

const WeddingPage = () => {
  return (
    <div>
      <Wedding />
    </div>
  );
};

export default WeddingPage;