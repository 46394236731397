import React, { useEffect, useState } from 'react';
import CommonCalls from '../components/CommonAPICalls/CommonCalls';
import Loader from "../components/UI Helpers/Simple Loading/Loader";
import CustomDataGrid from '../components/UI Helpers/DataGrid/CustomDataGrid';

const ServicesUserGridPage = () => {
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const apiCall = new CommonCalls();

    const fetchServices = async () => {
        setLoading(true);
        try {
            const response = await apiCall.getServicesItems();
            setServices(response);
        } catch (error) {
            console.error('Error fetching services:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchServices();
    }, []);

    const handleCheckboxChanges = async (row, field, isChecked) => {
        console.log(row, isChecked)
        try {

            if (!row.services_id) {
                console.error('Service ID is missing!');
                return;
            }

            const success = await apiCall.updateServiceItem(row.services_id, { web_displayed: isChecked });

            if (success) {
                fetchServices();
            } else {
                console.error('Error updating service visibility');
            }
        } catch (error) {
            console.error('Error updating service visibility:', error);
        }
    };

    return (
        <div style={{ padding: "20px" }}>
            <h1 className="h1_main mt-2">All Services</h1>
            {loading ? (
                <Loader />
            ) : (
                <CustomDataGrid
                    data={services}
                    columns={[
                        { field: 'service_name', columnName: 'Service Name' },
                        { field: 'service_slug_url', columnName: 'Slug URL' },
                        {
                            field: 'web_displayed',
                            columnName: 'Displayed on Web',
                            isCheckBox: true,
                            referenceValue: true
                        }
                    ]}
                    handleCheckboxChange={handleCheckboxChanges}
                    pageSize={pageSize}
                    totalRecords={services.length}
                    currentPage={pageNumber}
                    onPageChange={setPageNumber}
                    onPageSizeChange={setPageSize}
                />
            )}
        </div>
    );
};

export default ServicesUserGridPage;
