import React, { useEffect, useState } from "react";
import "./SideSlider.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

function SideSlider({ setToggleSidebar, sideOptions }) {
  const navigate = useNavigate();
  const location = useLocation();
  const userInfo = useSelector((state) => state.userLogin.userInfo);

  const filteredOptions = userInfo?.is_admin
    ? sideOptions
    : sideOptions.filter(option =>
      userInfo?.modules_access.includes(option.module_id)
    );

  useEffect(() => {
    console.log(filteredOptions, "filtered options");
    if (location.pathname === "/portal") {
      if (filteredOptions.length > 0) {
        navigate(filteredOptions[0].link);
      }
    }
  }, [location, navigate, filteredOptions]);

  return (
    <div className="SideSlider-container">
      <div className="container">
        <div className="d-flex align-items-center flex-column gap-3">
          {filteredOptions.map((option, ind) => (
            <div
              key={ind}
              className={`card ${location.pathname === option.link ? "active" : ""}`}
              onClick={() => {
                if (option.link) {
                  navigate(option.link);
                  setToggleSidebar(window.innerWidth < 992 ? true : false);
                }
              }}
            >
              <div className="d-flex align-items-center gap-4">
                <img src={option.image} alt="title icon" className="tab-icon" />
                <p className="mb-0 tab-title">{option.title}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SideSlider;
