import React from 'react'
import Grocery from '../components/KhidmateKhalq/Grocery'

const GroceryPage = () => {
  return (
    <div>
      <Grocery />
    </div>
  );
};

export default GroceryPage;