import React from 'react'
import EducationFees from '../components/KhidmateKhalq/EducationFees'

const EducationFeesPage = () => {
  return (
    <div>
      <EducationFees />
    </div>
  )
}

export default EducationFeesPage