import React from 'react';
import "./projects.css";

const Projects = () => {
    return (
        <div className="container about-container">
            <div className="row">

                <div className="col-lg-6 right-section">
                    <img src="/images/creatives/project-banner.png" alt="Large Image" className='large-img' />
                </div>
                <div className="col-lg-6 left-section text-center text-lg-start">

                    <h1 className='h1_main'>Other Projects</h1>

                    <div className="d-flex flex-container">
                        {/* <div className="image-container">
              
              
            </div> */}
                        <div className="text-container">
                            <div className='d-flex flex-column flex-lg-row gap-2  align-items-center  text-center text-lg-start'>
                                <img src="/images/icons/plantation.png" alt="Logo 1" className='img1' />
                                <div>
                                    <h3 className='h3_main'>Plantation</h3>
                                    <p className='para_main mb-0'>The Plantation initiative by Raza Foundation is aimed at promoting a greener
                                        environment through large-scale tree planting efforts
                                    </p>
                                </div>
                            </div>
                            <div className='d-flex flex-column flex-lg-row gap-2  align-items-center  text-center text-lg-start'>
                                <img src="/images/icons/tharparkar.png" alt="Logo 1" className='img2' />
                                <div>
                                    <h3 className='h3_main'>Tharparkar</h3>
                                    <p className='para_main'>Raza Foundation’s Thar Parker initiative is committed to providing essential aid to one
                                        of Pakistan's most impoverished regions.
                                    </p>
                                </div>
                            </div>
                            <div className='d-flex flex-column flex-lg-row gap-2  align-items-center  text-center text-lg-start'>
                                <img src="/images/icons/masjid-cons.png" alt="Logo 1" className='img2' />
                                <div>
                                    <h3 className='h3_main'>masjid & Madarsa Construction</h3>
                                    <p className='para_main'>Raza Foundation's Masjid and Madrasa Construction initiative is dedicated to building
                                        and renovating places of worship and learning across communities.</p>
                                </div>
                            </div>
                            <div className='d-flex flex-column flex-lg-row gap-2  align-items-center a text-center text-lg-start'>
                                <img src="/images/icons/khana-dehlis.png" alt="Logo 1" className='img2' />
                                <div>
                                    <h3 className='h3_main'>Khana dehli's</h3>
                                    <p className='para_main'>At Raza Foundation, we ensure that everyone receives nutritious meals right at their
                                        doorstep through our Khana Delhi’s service.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Projects;
