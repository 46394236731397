let Settings = {
  apiBaseUrl: '/api/sample',
  apiUserUrl: '/api/user',
  apiBlogsUrl: '/api/blogs',
  apiQuestionsUrl: '/api/questions',
  enumTypes: '/api/enum',
  enumTypesForStatus: [{ label: 'Approved', value: 1 }, { label: 'Pending', value: 2 }, { label: "Rejected", value: 3 }],
  enumDonationType: [{ label: 'i want meat', value: 1 }, { label: 'distribute amoungst needy', value: 2 }]
}

export default Settings;