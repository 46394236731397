import React from 'react'
import VisionAndMission from '../components/VisionAndMission/VisionAndMission'
import ChildHomeHero from '../components/ChildHomeHero/ChildHomeHero';
import NewsLetter from '../components/NewsLetter/NewsLetter';


const MedicinePage = () => {
  return (
    <div>


      <ChildHomeHero
        title="Introduction" bluespan="Medicine" Para="The Medicine service at Raza Foundation is committed to offering vital medical
                assistance and healthcare resources to underserved communities. We provide essential
                medications, medical supplies, and healthcare services to individuals who might
                otherwise go without. Islam emphasizes the importance of health and caring for others,
                as seen in the Hadith, “The Prophet (PBUH) said, ‘He who does not show mercy to our
                young ones and does not recognize the honor of our elders is not one of us.’” Through
                this service, we seek to fulfill our duty of compassion and ensure that everyone has
                access to necessary medical care." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
        button="Donate Now"
        // image="/images/creatives/Medicine.webp"
        image="/images/creatives/Medicine.png"
        background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" />

        <NewsLetter />
    </div>
  )
}

export default MedicinePage;