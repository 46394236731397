import React from 'react'
import VisionAndMission from '../components/VisionAndMission/VisionAndMission'
import ChildHomeHero from '../components/ChildHomeHero/ChildHomeHero';
import NewsLetter from '../components/NewsLetter/NewsLetter';

const CoronaReliefPage = () => {
    return (
        <div>
                <ChildHomeHero 
                title="Introduction" bluespan="Corona Relief" Para="The Corona Relief program by Raza Foundation is dedicated to helping those
                impacted by the COVID-19 pandemic. This global crisis has left many struggling, and in
                these challenging times, it is our duty as Muslims to extend a helping hand. The Prophet
                Muhammad (PBUH) said: Whoever relieves a believer’s distress of the distressful
                aspects of this world, Allah will rescue him from a difficulty of the difficulties of the
                Hereafter. Through this program, we distribute essential supplies, provide medical
                care, and offer financial support to those who need it most." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
                                button="Donate Now"
                                image="/images/creatives/Corona-Relief.webp"
                                background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat"
                              />

                              <NewsLetter />
        </div>
    )
}

export default CoronaReliefPage;