import React, { useEffect, useState } from 'react';
import CommonCalls from '../components/CommonAPICalls/CommonCalls';
import Loader from "../components/UI Helpers/Simple Loading/Loader";
import { useNavigate } from 'react-router-dom';
import { gridLinkValueFormatter } from '../components/MasterComponents/CommonFunctionalities';
import CustomDataGrid from '../components/UI Helpers/DataGrid/CustomDataGrid';

const SystemUserGridPage = () => {
    const [systemUsers, setSystemUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const navigate = useNavigate();

    const fetchSystemUsers = async (page = 1, size = 10) => {
        setLoading(true);
        const apiCall = new CommonCalls();

        try {
            const response = await apiCall.getAllSystemUsers(page, size);
            setSystemUsers(response);
            setTotalRecords(response.length);
        } catch (error) {
            console.error('Error fetching system users:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSystemUsers(currentPage, pageSize);
    }, [currentPage, pageSize]);

    const onEmailClicked = (row, colKey) => {
        if (colKey === 'full_name') {
            navigate(`/portal/system-detail-view?userid=${row?.user_id}`);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
    };

    const handleDelete = async (selectedRows) => {
        const apiCall = new CommonCalls();

        for (const row of selectedRows) {
            const success = await apiCall.deleteSystemUser(row.user_id);
            if (success) {
                setSystemUsers((prevBlogs) => prevBlogs.filter(blog => blog._id !== row._id));
                setTotalRecords((prevCount) => prevCount - 1);
            } else {
                console.error(`Failed to delete blog with id: ${row._id}`);
            }
        }
    };

    return (
        <div className='listings_view_wrapper' style={{ padding: "20px" }}>
            <h1 className="h1_main mt-2">All System Users</h1>
            {loading ? (
                <Loader />
            ) : (
                <CustomDataGrid
                    data={systemUsers}
                    totalRecords={totalRecords}
                    loadingState={loading}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}

                    columns={[
                        { field: 'full_name', columnName: 'Full Name', cellRenderer: gridLinkValueFormatter, customRendererLink: true, },
                        { field: 'email', columnName: 'Email', customRendererLink: false },
                    ]}
                    onSelectedRowsDelete={handleDelete}
                    onCellClicked={onEmailClicked}
                    hideAddBtn={true}
                />
            )}
        </div>
    );
};

export default SystemUserGridPage;
