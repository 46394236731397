import React from 'react';
import './ContactPage.css';
import { Form, Button, Row, Col } from 'react-bootstrap';
import NewsLetter from '../NewsLetter/NewsLetter';

const ContactPage = () => {
    return (
        <>
            <div className="important-section">
                <div className='container contact-container'>
                    <div className="contact-info">
                        <h1 className='h1_main'>Got Questions? <span className='span-text'>We have got answers!</span></h1>
                        <p className='para_main'>Join Hands as a Volunteer or Discuss Your Generous Donations—Together, We Can Make a Difference!</p>
                        <div className="contact-details d-lg-flex gap-3">

                            <img src="/images/creatives/contact-location.svg" alt="Location" />
                            <p className='informational-para'>H Area, Saudabad, Malir, Karachi, Sindh 75080 </p>
                            {/* <p><img src="/images/gmail-icon-contact.svg" alt="Email" className='location-icon' /> hello@moosakhan.com</p> */}

                        </div>
                        {/* <div className='d-lg-flex'>
                        <p><img src="/images/location-icon-contact.svg" alt="Location" />B129, block 4a, Gulshan-e-Iqbal Karachi</p>
                    </div> */}
                        <div className='contact-details  gap-3'>
                            <img src="/images/creatives/contact-phone.svg" alt="Phone" />
                            <a className="anchor" href="https://wa.me/923442140396">+923442140396</a>
                            <a className="anchor" href="https://wa.me/923413041304">+923413041304</a>

                            <img src="/images/creatives/contact-email.svg" alt="Location" />
                            <a className='anchor' href='mailto:razafoundationwelfare@gmail.com'>razafoundationwelfare@gmail.com</a>
                        </div>

                        {/* <div className="social-icons">
                        <img src="/images/facebook.svg" alt="YouTube" className="icon" />
                        <img src="/images/linkedln.svg" alt="LinkedIn" className="icon" />
                        <img src="/images/insta.svg" alt="Instagram" className="icon" />
                        <img src="/images/you.svg" alt="Facebook" className="icon" />
                    </div> */}
                    </div>
                    <div className="contact-form">
                        <h2 className='h1_main'>Let’s get in touch!</h2>
                        <p className='para_main'>We would love to hear from you.</p>
                        <Col lg={12}>
                            <Form>
                                <Row className='gy-3'>
                                    <Col lg={12}>
                                        <Form.Group controlId="formName">
                                            <Form.Control type="text" placeholder="Your Full Name" />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12}>
                                        <Form.Group controlId="formPhoneNumber">
                                            <Form.Control type="text" placeholder="Your Phone Number" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Form.Group controlId="formEmail" className='mt-4'>
                                    <Form.Control type="email" placeholder="Your Email Address" />
                                </Form.Group>
                                <Form.Group controlId="formMessage" className='mt-5'>
                                    <Form.Control type="text" placeholder='Type Your Message' rows={3} />
                                </Form.Group>
                                <Button variant="dark" type="submit" className="mt-5 submit-button w-100">
                                    Submit
                                </Button>
                            </Form>
                        </Col>
                    </div>
                </div>
            </div>
            <NewsLetter background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" />
        </>
    );
};

export default ContactPage;
