import React from 'react';
import './RefundPolicy.css'
import CommonHeroComponent from '../CommonHeroComponent/CommonHeroComponent';

const RefundPolicy = () => {
    return (
        <div>
            <CommonHeroComponent title="Raza Foundation" Heading="REFUND POLICY PAGE" Para="Last Updated: 12th August 2024" backgroundImg="/images/creatives/about-banner.jpg" />

            <div className="refund-content">
                <div className='container'>
                    <h2>Introduction</h2>
                    <p>
                        Thank you for your generous support of Raza Foundation. We are committed to ensuring your satisfaction with our services. This Refund Policy outlines the terms and conditions under which refunds may be issued for donations made to our organization. Please review this policy carefully.
                    </p>

                    <h3>Refund Policy for Donations</h3>
                    <ul>
                        <li><strong>General Policy:</strong></li>
                        <ul>
                            <li>Donations made to Raza Foundation are considered final and non-refundable. However, we understand that exceptional circumstances may arise. Refund requests will be evaluated on a case-by-case basis and are subject to approval.</li>
                        </ul>
                        <li><strong>Requesting a Refund:</strong></li>
                        <ul>
                            <li>If you believe you are eligible for a refund or have concerns about a transaction, please contact us at <a href="mailto:razafoundation@gmail.com">razafoundation@gmail.com</a> or 905 624 6929 within 30 days of the donation date. Provide your donation details, including the amount, date, and reason for the refund request.</li>
                        </ul>
                        <li><strong>Criteria for Refunds:</strong></li>
                        <ul>
                            <li>Refunds may be considered under the following conditions:</li>
                            <ul>
                                <li>Duplicate or erroneous transactions: If a donation was made more than once or was processed in error, we will review and consider a refund.</li>
                                <li>Technical issues: If a technical error prevented the donation from being properly processed, a refund may be issued.</li>
                                <li>Miscommunication: If there was a misunderstanding regarding the nature of the donation or the services provided, we will address and consider a refund.</li>
                            </ul>
                        </ul>
                        <li><strong>Processing of Refunds:</strong></li>
                        <ul>
                            <li>Approved refunds will be processed within 15 business days from the date the request is received. Refunds will be issued using the original payment method, unless otherwise agreed upon.</li>
                        </ul>
                        <li><strong>Non-Refundable Donations:</strong></li>
                        <ul>
                            <li>Donations made to specific campaigns, projects, or events that are non-refundable as per the terms stated during the donation process or in the campaign details will not be eligible for a refund.</li>
                        </ul>
                    </ul>

                    <h3>Changes to This Refund Policy</h3>
                    <p>
                        We reserve the right to update this Refund Policy at any time. Any changes will be posted on this page with an updated effective date. We encourage you to review this policy periodically for any updates.
                    </p>

                    <h3>Contact Us</h3>
                    <p>
                        If you have any questions or concerns regarding this Refund Policy or need to request a refund, please contact us:
                    </p>
                    <p>
                        Raza Foundation<br />
                        Email: <a href="mailto:razafoundation@gmail.com">razafoundation@gmail.com</a><br />
                        Phone: 905 624 6929<br />
                        Mailing Address: 8 Strathearn Avenue, Gulshan-e-Iqbal, Karachi.
                    </p>
                    <p>
                        Thank you for your understanding and support. We value your contributions and strive to address any concerns you may have with fairness and transparency.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default RefundPolicy;