import React from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import './DarulIfttaContactForm.css';

const DarulIfttaContactForm = () => {
    return (
        <div className='container'>
            <div className="darul-contact-form justify-content-center align-items-center">
                <h2 className='h1_main'>Reach Out to Darul Iftta Fareedia</h2>
                <p className='para_main'>We would love to hear from you.</p>
                <Col lg={12}>
                    <Form>
                        <Row className='gy-3'>
                            <Col lg={12}>
                                <Form.Group controlId="formName">
                                    <Form.Control type="text" placeholder="Your Full Name" />
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Group controlId="formPhoneNumber">
                                    <Form.Control type="text" placeholder="Your Phone Number" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group controlId="formEmail" className='mt-4'>
                            <Form.Control type="email" placeholder="Your Email Address" />
                        </Form.Group>
                        <Form.Group controlId="formMessage" className="mt-4">
                            <Form.Control
                                as="textarea"
                                placeholder="Type Your Message"
                                rows={4}
                                className="message-field"
                            />
                        </Form.Group>
                        <Button variant="dark" type="submit" className="mt-5 submit-button w-100">
                            Submit
                        </Button>
                    </Form>
                </Col>
            </div>
        </div>
    )
}

export default DarulIfttaContactForm