import React from 'react'
import CommonHeroComponent from '../components/CommonHeroComponent/CommonHeroComponent'
import VisionAndMission from '../components/VisionAndMission/VisionAndMission'

const QurbaniPage = () => {
  return (
    <div>
      <CommonHeroComponent
        Heading="Qurbani"
        Para="Join us in our Qurbani services to ensure your sacrifice reaches the needy, providing essential nourishment during Eid."
        backgroundImg="/images/creatives/Qurbani-Hero.jpg"
      />

      <VisionAndMission heading="About" bluespan=" Qurbani" Para="Qurbani, or the act of sacrifice during Eid al-Adha, commemorates the willingness of Prophet Ibrahim (AS) to sacrifice his son in obedience to Allah. This ritual is a significant act of worship and charity. At Raza Foundation, we ensure your Qurbani reaches those in dire need, providing them with essential nourishment." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
        image="/images/creatives/Qurbani-One.png"
        background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" 
        button="Donate Now"/>

      <VisionAndMission flexdirection="row-reverse" heading="How Your Qurbani" bluespan=" Donation Adds Value" Para="By donating Qurbani through Raza Foundation, you fulfill a critical Islamic duty while feeding the poor and hungry. This act of charity strengthens community bonds and spreads the joy of Eid al-Adha. Your Qurbani donation provides meat to those who rarely have access to it, making their celebration special." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
        image="/images/creatives/Qurbani-Two.png" 
        button="Donate Now"/>

      <VisionAndMission heading="Ease of Donating" bluespan=" Qurbani and Its Impact" Para="Raza Foundation makes donating Qurbani easy and impactful. Our streamlined process ensures your sacrifice is conducted in accordance with Islamic principles and reaches the needy promptly. Your Qurbani not only supports the less fortunate but also purifies your wealth and brings immense spiritual rewards." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
        image="/images/creatives/Qurbani-Three.png" 
        button="Donate Now"/>
    </div>
  );
};

export default QurbaniPage;