import React from 'react'
import CommonHeroComponent from '../components/CommonHeroComponent/CommonHeroComponent'
import VisionAndMission from '../components/VisionAndMission/VisionAndMission'

const SadaqaPage = () => {
  return (
    <div>
      <CommonHeroComponent
        Heading="Sadaqa"
        Para="Your sadaqa contributions help us reach more people with essential services, making a real difference in their lives."
        backgroundImg="/images/creatives/Sadaqa-Hero.jpg"
      />

      <VisionAndMission heading="About" bluespan=" Sadaqa" Para="Sadaqa is voluntary charity given out of compassion, love, and generosity. It can be given at any time and in any amount, making it a flexible and powerful form of giving. Raza Foundation channels your Sadaqa donations to support a wide range of humanitarian projects." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
      button="Donate Now"
        image="/images/creatives/Sadaqa-One.png"
        background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" />

      <VisionAndMission flexdirection="row-reverse" heading="How Your Sadaqa" bluespan=" Donation Adds Value" Para="Donating Sadaqa through Raza Foundation helps fund essential services like education, healthcare, and disaster relief. This voluntary act of charity aligns with Islamic values of generosity and kindness. Your Sadaqa contributions bring hope and support to those in need, making a meaningful impact." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
        image="/images/creatives/Sadaqa-Two.png" 
        button="Donate Now"/>

         <VisionAndMission  heading="Ease of Donating" bluespan=" Sadaqa and Its Impact" Para="Donating Sadaqa is simple with Raza Foundation's user-friendly platform. Your contributions, no matter how small, can make a big difference. By giving Sadaqa, you not only help others but also purify your wealth and earn spiritual rewards, embodying the true spirit of Islam." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
        image="/images/creatives/Sadaqa-Three.png"
        button="Donate Now"/>
    </div>
  );
};

export default SadaqaPage;