import React from 'react';
import CommonHeroComponent from '../components/CommonHeroComponent/CommonHeroComponent';
import VisionAndMission from '../components/VisionAndMission/VisionAndMission';
import NewsLetter from '../components/NewsLetter/NewsLetter';

const NoorEducationPage = () => {
    return (
        <div>
            <CommonHeroComponent
                Heading="NOOR EDUCATION"
                Para="Spreading the light of knowledge, Noor Education provides quality Islamic and
academic education to underprivileged children."
                backgroundImg="/images/creatives/khidmat-e-khalq-banner.jpg" />

            <VisionAndMission heading="About" bluespan=" Noor Education" Para="Noor Education is dedicated to providing Islamic and academic education to children
who may not have the resources to access quality learning. Our mission is to ensure
that every child, regardless of their background, has the opportunity to learn and grow
both spiritually and intellectually. Through our carefully designed programs, we offer
students a well-rounded education that emphasizes both academic excellence and
strong Islamic values. In Islam, education is a key pillar, and Noor Education strives to
uphold this by nurturing young minds with the knowledge that will shape their future." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
                button="Donate Now"
                image="/images/creatives/khidmat-e-khalq-one.png"
                background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" />

            <VisionAndMission flexdirection="row-reverse" heading="Support Noor Education" bluespan=" Through Donations" Para="Your donations to Noor Education have the power to change lives. By contributing, you
are directly helping children access the education they deserve, empowering them to
build a brighter future. Islam places great emphasis on helping those in need, especially
in the realm of education, as it is through knowledge that we strengthen our faith and
our community. Supporting Noor Education is a form of Sadaqah Jariyah, meaning
your reward will continue as long as the knowledge is passed on and used."
                button="Donate Now"
                image="/images/creatives/khidmat-e-khalq-two.png" />

            <VisionAndMission heading="How Easy it is to" bluespan=" Contribute and Its Impact" Para="SContributing to Noor Education is simple and immensely rewarding. Every donation
helps to cover educational costs, provide books, and ensure a quality learning
environment for these children. Your generosity not only impacts the lives of the
students but also brings endless rewards to you as a donor. Your support ensures
that the light of knowledge continues to shine in our community. As the Prophet Muhammad
(PBUH) said," bluepara="🙶 The best of people are those who benefit others 🙷"
                button="Donate Now"
                image="/images/creatives/khidmat-e-khalq-three.png" />

            <NewsLetter background="transparent linear-gradient(38deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" />
        </div>
    )
}

export default NoorEducationPage;