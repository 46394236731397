import React from 'react'
import NewsLetter from '../components/NewsLetter/NewsLetter'
import ChildHomeHero from '../components/ChildHomeHero/ChildHomeHero'

const KhanaDelhiPage = () => {
  return (
    <div>


      <ChildHomeHero
        title="Introduction" bluespan="Khana Delhi’s (Food on Doorstep)" Para="Khana Delhi’s (Food on Doorstep) is a compassionate initiative by Raza Foundation
                aimed at providing hot, nutritious meals directly to the homes of those in need. This
                service is designed to ensure that no one has to face hunger alone, offering support and
                sustenance to families and individuals who are struggling. In Islam, feeding those in
                need is a highly esteemed act of charity, as the Prophet Muhammad (PBUH) said, “He
                is not a believer whose stomach is filled while his neighbor goes hungry.” Through
                Khana Delhi’s, we strive to embody this principle by delivering food and hope to those
                who need it most." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
        button="Donate Now"
        image="/images/creatives/Khana-Delhi.webp"

        background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat"
      />

      <NewsLetter />
    </div>
  )
}

export default KhanaDelhiPage