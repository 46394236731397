import React from 'react';
import EventsInner from '../components/EventsInnerFolder/EventsInner';
import VisionAndMission from '../components/VisionAndMission/VisionAndMission';

const EventsInnerPage = () => {
    return (
        <div>
            <EventsInner />
            <VisionAndMission heading="About" bluespan=" Noor Education" Para="Khidmat-e-Khalq, or service to humanity, is a core Islamic value. It involves helping others selflessly and is highly encouraged in Islam. Raza Foundation's Khidmat-e-Khalq programs provide various forms of assistance to those in need, embodying the spirit of service." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
                button="Donate Now"
                image="/images/creatives/khidmat-e-khalq-one.png"
                background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" />
            <VisionAndMission flexdirection="row-reverse" heading="How Your Khidmat-e-Khalq" bluespan=" Donation Adds Value" Para="Donating to Khidmat-e-Khalq programs through Raza Foundation allows you to help others in numerous ways, from providing food and clothing to healthcare and shelter. This act of service is deeply rooted in Islamic teachings, promoting kindness and compassion. Your contributions directly improve lives and bring relief to those in distress."
                button="Donate Now"
                image="/images/creatives/khidmat-e-khalq-two.png" />

            <VisionAndMission heading="Ease of Donating for" bluespan=" Khidmat-e-Khalq and Its Impact" Para="Supporting Khidmat-e-Khalq is easy with Raza Foundation's dedicated platform. Your donations are efficiently directed to various service projects, ensuring maximum impact. By participating in Khidmat-e-Khalq, you enhance your spiritual journey and make a significant difference in the lives of others." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
                button="Donate Now"
                image="/images/creatives/khidmat-e-khalq-three.png" />
        </div>
    )
}

export default EventsInnerPage;