import React from 'react'
import ChildHomeHero from '../components/ChildHomeHero/ChildHomeHero';
import NewsLetter from '../components/NewsLetter/NewsLetter';

const RashanDistributePage = () => {
    return (
        <div>


            <ChildHomeHero
                title="Introduction" bluespan=" Rashan Distribution" Para="Many families in Pakistan struggle to afford basic food items, leading to malnutrition and
                hunger. Through the Rashan Distribution initiative, Raza Foundation provides
                essential food supplies, ensuring that no one goes hungry. Islam places great emphasis
                on helping those in need, especially by providing food. As the Prophet Muhammad
                (PBUH) said: “He is not a believer who fills his stomach while his neighbor goes
                hungry.” By supporting this service, you become a lifeline for those less fortunate,
                fulfilling a noble Islamic duty." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
                button="Donate Now"
                image="/images/creatives/Rashan-Distribute.webp"
                background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" />

                <NewsLetter />
        </div>
    )
}

export default RashanDistributePage;