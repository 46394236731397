import React from 'react'
import ChildHomeHero from '../components/ChildHomeHero/ChildHomeHero';
import NewsLetter from '../components/NewsLetter/NewsLetter';

const FamilySupportPage = () => {
    return (
        <div>


            <ChildHomeHero
                title="Introduction" bluespan="Family Support" Para="The Family Support initiative by Raza Foundation is designed to help families
                struggling with financial difficulties, ensuring they can meet their basic needs and
                maintain their dignity. Islam encourages us to support and uplift those facing hardships.
                The Prophet Muhammad (PBUH) said: “The best of people are those that bring most
                benefit to the rest of mankind.” By assisting families in need, you fulfill an essential
                Islamic duty, providing them with hope and stability." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
                button="Donate Now"
                image="/images/creatives/Family-Support.webp"
                background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" />

                <NewsLetter />
        </div>
    )
}

export default FamilySupportPage;