import React from 'react';
import Utilities from '../components/KhidmateKhalq/Utilities';

const UtilitiesPage = () => {
  return (
    <div>
        <Utilities />
    </div>
  )
}

export default UtilitiesPage