import React from 'react'
import VisionAndMission from '../components/VisionAndMission/VisionAndMission'
import ChildHomeHero from '../components/ChildHomeHero/ChildHomeHero'
import NewsLetter from '../components/NewsLetter/NewsLetter'

const MasjidAndMadarsaConstruction = () => {
  return (
    <div>


      <ChildHomeHero
        title="Introduction" bluespan="Masjid and Madrasa Construction" Para="The Masjid and Madrasa Construction program by the Raza Foundation focuses on
        the establishment and renovation of mosques and religious schools (madrasas) for
        underprivileged communities. These are the heart of Islamic learning and worship,
        serving as centers for prayer, education, and community support. Building a masjid
        holds great significance in Islam, as Prophet Muhammad (PBUH) said: Whoever builds
        a mosque for Allah, Allah will build for him a house in Paradise. By supporting this
        initiative, you contribute to a lasting legacy that benefits the Ummah, both spiritually and
        educationally." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
        button="Donate Now"
        image="/images/creatives/Masjid-Construction.webp"
        background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat"
      />

      <NewsLetter />
    </div>
  )
}

export default MasjidAndMadarsaConstruction