import React, { useState } from 'react';
import './CommonComponentsStylings.css';

const DropDownInput = ({ options, disabled, required, label, name, defaultSelected = 'Select', onSelect }) => {
  const [selectedOption, setSelectedOption] = useState(defaultSelected);

  const handleSelect = (event) => {
    let inputVal = event.target.value
    setSelectedOption(inputVal);
    if (onSelect) {
      onSelect(event);
    }
  };

  return (
    <div className="dropdown-wrapper">
      <label htmlFor={label}>{label}{required ? <span style={{ color: 'red' }}>*</span> : ''}</label>
      <select
        className="dropdown-select"
        disable={disabled}
        value={selectedOption}
        name={name}
        onChange={(e) => handleSelect(e)}
      >
        <option value={''}>Select</option>
        {options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DropDownInput; 