import React from 'react'
import VisionAndMission from '../components/VisionAndMission/VisionAndMission'
import ChildHomeHero from '../components/ChildHomeHero/ChildHomeHero'
import NewsLetter from '../components/NewsLetter/NewsLetter'

const IslamicHistroyPage = () => {
    return (
        <div>


            <ChildHomeHero
                title="Introduction" bluespan="Islamic History" Para="The Islamic History service at Raza Foundation is designed to enlighten individuals
                about the pivotal moments and figures that have shaped the Islamic world over the
                centuries. By studying the lives of prophets, scholars, and significant events, we seek to
                revive the knowledge that guided generations. Islam places great emphasis on learning
                from the past, as the Qur'an often reminds us to reflect on the lessons from previous
                nations. This service helps Muslims reconnect with their roots and draw wisdom from
                the teachings of our forefathers." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
                button="Donate Now"
                image="/images/creatives/Islamic-History.webp"
                background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" />

                <NewsLetter />
        </div>
    )
}

export default IslamicHistroyPage