import React from 'react';
import CommonHeroComponent from '../components/CommonHeroComponent/CommonHeroComponent';
import VisionAndMission from '../components/VisionAndMission/VisionAndMission';
import NewsLetter from '../components/NewsLetter/NewsLetter';

const RazaLibraryPage = () => {
    return (
        <div>
            <CommonHeroComponent
                Heading="RAZA LIBRARY"
                Para="Empowering minds through knowledge, Raza Library offers access to Islamic books,
educational materials, and resources for all ages."
                backgroundImg="/images/creatives/raza-library-banner.webp" />

            <VisionAndMission heading="About" bluespan=" Raza Library" Para="The Raza Library is dedicated to spreading the light of knowledge within the Muslim
community. Offering a vast collection of Islamic literature, educational resources, and
scholarly works, our library serves as a hub for individuals eager to deepen their
understanding of Islam. Whether it’s religious texts, educational materials for students,
or books to inspire personal growth, the Raza Library ensures that knowledge is
accessible to all. In the spirit of Islam, gaining knowledge is a lifelong journey, and our
library helps facilitate that journey for people of all ages.
" bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
                button="Donate Now"
                image="/images/creatives/raza-library-one.webp"
                background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" />

            <VisionAndMission flexdirection="row-reverse" heading="Support Raza Library" bluespan=" Through Donations" Para="Your generous donations to the Raza Library help in expanding our collection of books,
maintaining the library facilities, and creating an environment where knowledge thrives.
In Islam, seeking knowledge is a form of worship, and by contributing to this cause, you
enable others to engage in this righteous act. Supporting the Raza Library is an
ongoing Sadaqah Jariyah — a continuous charity that will benefit generations to come,
as knowledge passed on is a gift that keeps giving."
                button="Donate Now"
                image="/images/creatives/raza-library-two.webp" />

            <VisionAndMission heading="How Easy it is to" bluespan=" Contribute and Its Impact" Para="Contributing to the Raza Library is simple, and the impact is far-reaching. With each
donation, more individuals have access to Islamic knowledge and educational
resources that they may otherwise be unable to afford. This knowledge strengthens
their connection to their faith and their understanding of Islam. Not only does this enrich
their lives, but it also brings immense spiritual reward for the donor. As the Prophet Muhammad (PBUH) said," bluepara="🙶 Seeking knowledge is an obligation upon every Muslim"
                button="Donate Now"
                image="/images/creatives/raza-library-three.webp" />

            <NewsLetter background="transparent linear-gradient(38deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" />
        </div>
    )
}

export default RazaLibraryPage;