import React from 'react';
import CommonHeroComponent from '../components/CommonHeroComponent/CommonHeroComponent'
import VisionAndMission from '../components/VisionAndMission/VisionAndMission';
import ZakatCalculatorSection from '../components/ZakatCalculator/ZakatCalculatorSection';
import NewsLetter from '../components/NewsLetter/NewsLetter'

const ZakatPage = () => {
  return (
    <div>
      <CommonHeroComponent
        Heading="ZAKAT & FITRAH"
        Para="Your zakat powers transformative projects in education, healthcare, and community development, uplifting those in need. Ensure your Fitrah reaches the most vulnerable, delivering crucial support during Ramadan and Eid."
        ParaDark="At Raza Foundation, we uphold the highest standards of integrity and transparency. We ensure that every penny of our donors' Zakat is dedicated solely to the intended causes and beneficiaries. None of your Zakat contributions are ever used to cover our operational expenses."
        backgroundImg="/images/creatives/zakat-banner.jpg" />

      {/* <VisionAndMission heading="About" bluespan=" Zakat" Para="Zakat is the amount of money that every adult, mentally stable, free, and financially able Muslim, male and female, has to pay to support specific categories people. This category of people is defined in surah at-Taubah (9) verse 60: “The alms are only for the poor and the needy, and those who collect them, and those whose hearts are to be reconciled, and to free the captives and the debtors, and for the cause of Allah, and (for) the wayfarers; a duty imposed by Allah. Allah is knower, Wise.” (Al Qur’an 9:60)." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
        button="Donate Now"
        image="/images/creatives/zakat-one.png"
        background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" /> */}

      <VisionAndMission flexdirection="row-reverse" heading="Fulfill Your Duty," bluespan=" Transform Lives" Para="Zakat is a fundamental pillar of Islam, a form of almsgiving that purifies wealth and helps those in need. At Raza Foundation, we ensure your zakat reaches the most deserving individuals, uplifting communities and bringing hope to countless lives. By donating your zakat through us, you fulfill your religious duty and contribute to a brighter future for many. Your zakat can support education, healthcare, and various other essential services, making a profound impact on those who need it the most." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
        button="Donate Now"
        serviceId="2"
        image="/images/creatives/zakat-two.png" />


      <VisionAndMission heading="How Your Fitrah" bluespan=" Donation Adds Value" Para="Donating your Fitrah to Raza Foundation ensures it directly benefits the less fortunate, allowing them to partake in the Eid festivities. This act of kindness embodies the spirit of Islam, fostering unity and compassion within the community. Your Fitrah helps provide food and essentials to those in need, making their Eid celebration as joyous as yours." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
        button="Donate Now"
        serviceId="2"
        image="/images/creatives/zakat-two.png" />




      <VisionAndMission flexdirection="row-reverse" heading="Calculate with Confidence, " bluespan=" Donate with Ease" Para="Working out zakat can be simple and straightforward with our easy-to-use tools and guidance. We provide clear instructions and calculators to help you determine the exact amount of zakat you need to give based on your assets and income. Our resources ensure that you comply with Islamic principles and fulfill your obligation accurately. By using our services, you can confidently calculate and donate your zakat, knowing that it will be used effectively to support those in need and bring positive change to their lives." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
        button="Donate Now"
        serviceId="2"
        image="/images/creatives/zakat-three.png" />



      <VisionAndMission heading="How Your Fitrah" bluespan=" Donation Adds Value" Para="Donating your Fitrah to Raza Foundation ensures it directly benefits the less fortunate, allowing them to partake in the Eid festivities. This act of kindness embodies the spirit of Islam, fostering unity and compassion within the community. Your Fitrah helps provide food and essentials to those in need, making their Eid celebration as joyous as yours." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
        button="Donate Now"
        image="/images/creatives/zakat-two.png" />




      {/* <VisionAndMission flexdirection="row-reverse" heading="About" bluespan=" Fitrah" Para="Fitrah, also known as Zakat al-Fitr, is a charitable donation given during Ramadan to ensure that everyone can celebrate Eid al-Fitr. It purifies those who fast from any indecent act or speech and helps those in need. By giving Fitrah through Raza Foundation, you ensure your contribution reaches the most vulnerable, spreading joy and fulfilling an important Islamic obligation." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
        image="/images/creatives/zakat-one.png"
        button="Donate Now"
        background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" /> */}

      {/* <VisionAndMission heading="How Your Fitrah" bluespan=" Donation Adds Value" Para="Donating your Fitrah to Raza Foundation ensures it directly benefits the less fortunate, allowing them to partake in the Eid festivities. This act of kindness embodies the spirit of Islam, fostering unity and compassion within the community. Your Fitrah helps provide food and essentials to those in need, making their Eid celebration as joyous as yours." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
      button="Donate Now"
        image="/images/creatives/zakat-two.png" /> */}

      <VisionAndMission flexdirection="row-reverse" heading="Ease of Donating " bluespan=" Fitrah and Its Impact" Para="Donating Fitrah through Raza Foundation is simple and impactful. With our straightforward donation process, you can easily fulfill your religious duty and bring happiness to many lives. Your contribution not only aids those in need but also purifies your soul, enhancing your spiritual well-being." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
        image="/images/creatives/zakat-three.png"
        button="Donate Now" />

      <ZakatCalculatorSection />

      <NewsLetter background="transparent linear-gradient(38deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" />
    </div>
  );
};

export default ZakatPage;