import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import "./NewsLetter.css";
import CommonCalls from '../../components/CommonAPICalls/CommonCalls';
import { showAlertMessage } from '../MasterComponents/CommonFunctionalities';

function NewsLetter({ background }) {
    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const apiCall = new CommonCalls();

    const formSubmitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);

        const payload = { user_email: email };

        try {
            const response = await apiCall.addNewSubscriber(payload);
            if (response.status === 201) {
                setError(false);
                showAlertMessage("Newsletter Subscribed Successfully!", 'success');
                setLoading(false);
                setEmail("");
            } else {
                showAlertMessage(response.msg, 'fail');
                setLoading(false);
                setEmail("");
            }
        } catch (error) {
            showAlertMessage("Could not Subscribed NewsLetter yet", 'fail');
            setLoading(false);
            setEmail("");
        }
    };

    return (
        <div className='NewsLetter-container standard-padding-space' style={{ background: background }}>
            <div className="container">
                <div className="form-container m-auto px-2">
                    <div className="text-container text-center">
                        <h3 className="h1_main">Subscribe Our Newsletter</h3>
                        <p className='body-paragraph mt-2'>Get the latest updates on our projects and success stories. Join our community of changemakers today!</p>
                    </div>
                    <form className='inp-container d-md-flex d-none align-items-center justify-content-between' onSubmit={formSubmitHandler}>
                        <input
                            required
                            type="email"
                            value={email}
                            maxLength="56"
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder='Enter Your Email Address'
                        />
                        <button className='genral-btn' type="submit" disabled={loading}>Subscribe
                            {loading ? (
                                <Spinner
                                    animation="border"
                                    role="status"
                                    style={{ color: "white", width: "15px", height: "15px", marginLeft: "10px" }}
                                />
                            ) : (
                                success ? (
                                    <div >
                                        {setLoading(false)}
                                        {alert(success)}
                                        {setSuccess(false)}
                                    </div>
                                ) : (
                                    error && (
                                        <div>
                                            {setLoading(false)}
                                            {alert(error)}
                                            {setError(false)}
                                        </div>
                                    )
                                )
                            )}
                        </button>
                    </form>
                    <form className='d-md-none d-flex flex-column align-items-center justify-content-between' onSubmit={formSubmitHandler}>
                        <input
                            required
                            type="email"
                            value={email}
                            maxLength="56"
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder='Your Email Address'
                            className='inp-container px-3 py-2'
                        />
                        <button className='genral-btn mt-3' type="submit" disabled={loading}>Subscribe
                            {loading ? (
                                <Spinner
                                    animation="border"
                                    role="status"
                                    style={{ color: "white", width: "15px", height: "15px", marginLeft: "10px" }}
                                />
                            ) : (
                                success ? (
                                    <div >
                                        {setLoading(false)}
                                        {showAlertMessage("NewsLetter Successfully Subscribed")}
                                        {setSuccess(false)}
                                    </div>
                                ) : (
                                    error && (
                                        <div>
                                            {setLoading(false)}
                                            {alert(error)}
                                            {setError(false)}
                                        </div>
                                    )
                                )
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default NewsLetter;
