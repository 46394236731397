import React from 'react';
import CommonHeroComponent from '../components/CommonHeroComponent/CommonHeroComponent'
import VisionAndMission from '../components/VisionAndMission/VisionAndMission';
import ZakatCalculatorSection from '../components/ZakatCalculator/ZakatCalculatorSection';
import NewsLetter from '../components/NewsLetter/NewsLetter';

const KhatmeNabowatPage = () => {
  return (
    <div>

      <CommonHeroComponent
        Heading="KHATM-E-NABUWAT"
        Para="The belief in the finality of Prophethood, Khatam-e-Nabuwat, is a core tenet of Islam,
affirming that Prophet Muhammad (PBUH) is the last messenger of Allah."
        backgroundImg="/images/creatives/banner.webp" />

      <VisionAndMission heading="About" bluespan=" Khatam-e-Nabuwat" Para="Khatam-e-Nabuwat signifies the fundamental belief that Prophet Muhammad (PBUH)
is the final prophet, and no prophet will come after him. This is a cornerstone of Islamic
faith, emphasized in the Quran, where Allah declares that Muhammad (PBUH) is the
Seal of the Prophets. The Raza Foundation is committed to educating and upholding
this critical aspect of our faith, ensuring that Muslims across the globe understand the
significance of Khatam-e-Nabuwat and defend it against misconceptions. This service
helps protect the integrity of Islam by reaffirming the finality of Prophethood in line with
Quranic teachings and the Sunnah."
        bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
        button="Donate Now"
        image="/images/creatives/1.webp"
        background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" />

      <VisionAndMission flexdirection="row-reverse" heading="Support Khatam-e-Nabuwat" bluespan=" Through Donations" Para="By donating to the Khatam-e-Nabuwat service, you are contributing to a cause that
safeguards the essence of Islam. Your donations help fund educational initiatives,
awareness campaigns, and scholarly efforts that reinforce the understanding of the
finality of Prophethood. Supporting Khatam-e-Nabuwat is not just an act of charity but
a significant step in preserving the purity of our religion. In the Quran, Allah encourages
us to support the truth, and by aiding this cause, you align yourself with the divine
command to protect the core tenets of Islam." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
        button="Donate Now"
        image="/images/creatives/2.webp" />

      <VisionAndMission heading="How Easy It Is to " bluespan=" Contribute and Its Impact" Para="Contributing to the Khatam-e-Nabuwat cause is both simple and rewarding. Your
support helps spread the message of the finality of Prophethood, countering
misinformation and ensuring that the Islamic faith remains pure and undistorted. Every
donation strengthens the efforts to educate and protect this essential belief, bringing
blessings to your life and to the wider Muslim community. As the Prophet Muhammad
(PBUH) said, “The best of deeds is to stand firm in the path of truth.” Your contributions
ensure that we continue to stand firm in safeguarding this truth." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
        button="Donate Now"
        image="/images/creatives/3.webp" />


      <NewsLetter background="transparent linear-gradient(38deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" />
    </div>
  );
};

export default KhatmeNabowatPage;