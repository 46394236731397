import React from 'react'
import ChildHomeHero from '../components/ChildHomeHero/ChildHomeHero'
import NewsLetter from '../components/NewsLetter/NewsLetter'

const PlantationPage = () => {
  return (
<div>
    <ChildHomeHero
      title="Introduction" bluespan="Plantation" Para="The Plantation program by the Raza Foundation is focused on the preservation and
rejuvenation of the environment through tree planting and reforestation efforts. In Islam,
the care and preservation of nature are considered acts of worship, and Prophet
Muhammad (PBUH) said: If a Muslim plants a tree or sows seeds, and then a bird, or a
person or an animal eats from it, it is regarded as a charitable gift for him. Through this
initiative, we aim to create a healthier environment, combat climate change, and ensure
future generations can enjoy the blessings of nature." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
      button="Donate Now"
      image="/images/creatives/Plantation.webp"

      background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat"
    />

    <NewsLetter />

</div>


  )
}

export default PlantationPage