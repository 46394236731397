import React from 'react'
import ChildHomeHero from '../components/ChildHomeHero/ChildHomeHero';
import NewsLetter from '../components/NewsLetter/NewsLetter';

function GenralServicesInnerPage({
    heading="",
    paragraph="",
    img="/images/creatives/Financial-aid.webp",
    ayah=""
}) {
  return (
    <div>
      <ChildHomeHero
        title="Introduction"
        bluespan={heading}
        Para={paragraph}
        bluepara={`🙶 ${ayah} 🙷`}
        button="Donate Now"
        image={img}
        background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat"
      />

      <NewsLetter />
    </div>
  );
}

export default GenralServicesInnerPage