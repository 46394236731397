export const showAlertMessage = (message, type) => {
  let color = "";
  switch (type) {
    case "success":
      color = "green";
      break;
    case "fail":
      color = "red";
      break;
    default:
      color = "black";
      break;
  }

  const alertDiv = document.createElement("div");
  alertDiv.textContent = message;
  alertDiv.style.position = "fixed";
  alertDiv.style.top = "5%";
  alertDiv.style.left = "50%";
  alertDiv.style.transform = "translate(-50%, -50%)";
  alertDiv.style.color = "#fff";
  alertDiv.style.backgroundColor = color;
  alertDiv.style.padding = "10px";
  alertDiv.style.borderRadius = "5px";
  alertDiv.style.zIndex = "9999";

  document.body.appendChild(alertDiv);

  setTimeout(() => {
    alertDiv.remove();
  }, 1500);
};

export const checkIfArrayIsEmpty = (arr) => {
  if (Array.isArray(arr)) return arr.length === 0;
  return false;
};

export const objectIsEmpty = (obj) => {
  if (!obj) {
    return true;
  } else if (Array.isArray(obj) || typeof obj === "string") {
    return obj.length === 0;
  } else if (typeof obj === "object") {
    return Object.keys(obj).length === 0;
  } else {
    return false;
  }
};

export const gridLinkValueFormatter = (value) => {
  return <span style={{ color: '#2d74e0', cursor: 'pointer' }}>{value}</span>
};

export const customIconInGridCell = () => {
  return <span style={{ cursor: 'pointer' }}><img style={{ display: 'inline', cursor: 'pointer', borderRadius: '12%', width: '95px' }} src="./images/icons/zoom-logo-new.png" className="img-fluid" /></span>;
};

export const convertDateTimeFormat = (dateStr) => {
  if (!dateStr) {
    return '';
  }

  const date = new Date(dateStr);

  if (isNaN(date.getTime())) {
    return '';
  }

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear()).slice(-2);

  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12;
  hours = String(hours).padStart(2, '0');

  const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;
  return formattedDateTime;
};

export const getDateFormatted = (dateVal) => {
  return dateVal ? convertDateTimeFormat(dateVal) : '-';
};