import React from 'react'
import NewsLetter from '../components/NewsLetter/NewsLetter'
import ChildHomeHero from '../components/ChildHomeHero/ChildHomeHero'

const LangurKhana = () => {
    return (
        <div>

            <ChildHomeHero
                title="Introduction" bluespan="Langur Khana - Thar Parker" Para="Langur Khana - Thar Parker is a crucial service provided by Raza Foundation to
                address the severe food shortages in the Thar Desert region. This initiative focuses on
                delivering nutritious meals and essential food supplies to the most vulnerable
                communities in Thar Parker, ensuring they receive the sustenance needed for their wellbeing. In Islam, feeding the hungry is a profound act of charity, and as the Prophet
                Muhammad (PBUH) emphasized, “The one who provides food to a fasting person will
                have a reward like the fasting person, without any reduction in his reward.” By
                supporting Langur Khana, we uphold this noble principle and work towards alleviating
                hunger in one of Pakistan's most challenging environments." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
                button="Donate Now"
                image="/images/creatives/Langar-Khana-Thar-Parker.webp"
                background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat"
            />

            <NewsLetter />
        </div>
    )
}

export default LangurKhana