import React, { useEffect, useState } from 'react';
import axios from 'axios';
import BlogInner from '../components/BlogInner/BlogInner';
import CommonHeroComponent from '../components/CommonHeroComponent/CommonHeroComponent';
import { useParams, useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import CommonCalls from '../components/CommonAPICalls/CommonCalls';

const BlogInnerPage = () => {
    const [blogsData, setBlogsData] = useState(null);
    const [recentBlogs, setRecentBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const slug = useParams().id;
    console.log(slug, useParams())

    const getBlogBySlugs = async () => {
        setLoading(true);
        const apiCall = new CommonCalls();
        try {
            const response = await apiCall.getBlogBySlug(slug);
            setBlogsData(response)
        } catch (error) {
            console.log("Error", error)
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getBlogBySlugs();
    }, [slug]);

    return (
        <div>
            {loading ? (
                <div
                    style={{ width: "100%", height: "100vh" }}
                    className="d-flex justify-content-center align-items-center"
                >
                    <Spinner
                        style={{ color: "#ea3317", width: "120px", height: "120px" }}
                    />
                </div>
            ) : (
                blogsData && (
                    <>
                        <CommonHeroComponent
                            backgroundImg="/images/creatives/googleAds-banner.jpg"
                            Heading={blogsData.blog_title}
                        />
                        <BlogInner
                            blogContent={blogsData.blog_content}
                            Heading={blogsData.blog_title}
                        />
                    </>
                )
            )}
        </div>
    );
};

export default BlogInnerPage;
