import React, { useState } from 'react';
import './CommonComponentsStylings.css';

const Input = ({
    type,
    id,
    name,
    required,
    value,
    // maxValue = 100, 
    onChange,
    disabled,
    label,
    placeholder = "",
    inputType,
    inputProps,
    inputClassName,
    placeHolder
}) => {
    const [inputValue, setInputValue] = useState(value || '');
    const [showPassword, setShowPassword] = useState(false);

    const handleChange = (event) => {
        const newValue = event.target.value;

        setInputValue(newValue);
        if (onChange) onChange(event);
    };

    const focusOutHandler = (e) => {
        // Your focusOutHandler logic here
    };

    const focusHandler = (e) => {
        // Your focusHandler logic here
    };

    const onKeyDown = (e) => {
        // Your onKeyDown logic here
    };

    const onChangeHandler = (e) => {
        handleChange(e);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    if (inputType === 'password_eye') {
        return (
            <div className="password_visibility_wrapper input-container">
                <label htmlFor={id}>
                    {label}{required && <span style={{ color: 'red' }}>*</span>}
                </label>
                <input
                    {...inputProps}
                    autoComplete="off"
                    onBlur={focusOutHandler}
                    onFocus={focusHandler}
                    onKeyDown={onKeyDown}
                    type={showPassword ? 'text' : 'password'}
                    name={name}
                    value={inputValue ? inputValue : ''}
                    onChange={onChangeHandler}
                    className={'form-control ' + (inputClassName ? inputClassName : '')}
                    placeholder={placeHolder ? placeHolder : placeholder}
                />
                <div className="password_eye_icon_wrapper" onClick={togglePasswordVisibility}>
                    {showPassword ? (
                        <img src='/images/icons/visible.png' alt='eye icon' className='password-input-eye-icon' />
                    ) : (
                        <img src='/images/icons/not-visible.png' alt='eye icon' className='password-input-eye-icon' />
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className="input-container">
            <label htmlFor={id}>
                {label}{required && <span style={{ color: 'red' }}>*</span>}
            </label>
            <input
                disabled={disabled}
                id={id}
                name={name}
                type={type}
                placeholder={placeholder}
                value={inputValue}
                onChange={handleChange}
            />
        </div>
    );
};

export default Input;
