import React from 'react';
import CommonHeroComponent from '../components/CommonHeroComponent/CommonHeroComponent';
import VisionAndMission from '../components/VisionAndMission/VisionAndMission';
import NewsLetter from '../components/NewsLetter/NewsLetter';
import DarulIfttaContactForm from '../components/DarulIfttaContactForm/DarulIfttaContactForm';

const DarulIfftaPage = () => {
    return (
        <div>
            <CommonHeroComponent
                Heading="DAR-UL-IFFTA"
                Para="Providing clear and accurate Islamic guidance, Dar-ul-Iftaa offers scholarly fatwas and
religious advice based on Quran and Sunnah
"
                backgroundImg="/images/creatives/darul-Iffta-banner.webp" />

            <VisionAndMission heading="About" bluespan=" Dar-ul-Iftaa" Para="The Dar-ul-Iftaa is a trusted source of Islamic rulings (fatwas) and religious guidance
for Muslims seeking clarity on various aspects of their lives. Whether it’s matters related
to worship, daily conduct, or complex issues in personal and social life, our scholars
provide answers grounded in the teachings of the Quran and the Sunnah. At the heart
of this service is the mission to ensure that every Muslim can live their life in accordance
with the principles of Islam, guided by the wisdom of knowledgeable and qualified
Islamic scholars. Islam encourages seeking knowledge, and the Dar-ul-Iftaa ensures
that Muslims have access to reliable religious advice" bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
               z
                image="/images/creatives/darul-iffta-one.webp"
                background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" />

            <VisionAndMission flexdirection="row-reverse" heading="Support Dar-ul-Iftaa" bluespan=" Through Donations" Para="Donating to Dar-ul-Iftaa allows us to continue offering valuable religious guidance and
fatwas to the Muslim community. Islamic rulings help individuals stay on the path of
righteousness, making informed decisions that align with their faith. By supporting this
service, you’re helping Muslims across the world access the knowledge and guidance
they need to live their lives in accordance with Islam. Contributing to the Dar-ul-Iftaa is
a form of Sadaqah Jariyah — a charity that keeps benefiting as it helps others follow the
right path."
                button="Donate Now"
                image="/images/creatives/darul-iffta-two.webp" />

            <VisionAndMission heading="How Easy It Is to" bluespan=" Contribute and Its Impact" Para="Contributing to the Dar-ul-Iftaa is simple and immensely rewarding. With your support,
            we can continue to provide accessible religious advice and fatwas to Muslims globally,
            helping them navigate life’s challenges with guidance rooted in Islamic knowledge. Your
            donations not only support the dissemination of Islamic rulings but also bring blessings
            to your own lifeAs the Prophet Muhammad (PBUH) said," bluepara="🙶 The one who shows the right
            path to someone will also get the reward of the one who acts upon it. 🙷"
                button="Donate Now"
                image="/images/creatives/darul-iffta-three.webp" />

                <DarulIfttaContactForm />

            <NewsLetter background="transparent linear-gradient(38deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" />
        </div>
    )
}

export default DarulIfftaPage;