import React from 'react'
import ChildHomeHero from '../components/ChildHomeHero/ChildHomeHero';
import NewsLetter from '../components/NewsLetter/NewsLetter';

const FinancialAidPage = () => {
    return (
        <div>
            <ChildHomeHero
                title="Introduction" bluespan="Financial Aid" Para="The Financial Aid program by the Raza Foundation is designed to support individuals
            and families struggling to meet basic needs such as food, education, healthcare, and
            shelter. Inspired by the Islamic principle of helping those in distress, this initiative is
            rooted in compassion and the responsibility to uplift those in difficult circumstances. The
            Quran teaches us: And help one another in righteousness and piety (Surah Al-
            Ma'idah, 5:2). Our Financial Aid service provides timely assistance to those facing
            financial hardships, allowing them to rebuild their lives and regain stability.
            " bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
                button="Donate Now"
                // image="/images/creatives/Financial-aid.webp"
                image="/images/creatives/Financial-aid.png"
                background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat"
            />

            <NewsLetter />
        </div>
    )
}

export default FinancialAidPage;