import React, { useState, useRef, useMemo, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import CommonCalls from '../CommonAPICalls/CommonCalls';
import './BlogsEditor.css';
import FieldsRenderer from '../UI Helpers/InputFieldsRendererCommon/InputFieldsRendererComponent';
import Settings from '../CommonAPICalls/Settings';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Loader from '../UI Helpers/Simple Loading/Loader';

const BlogsEditor = ({ placeholder }) => {
    const editor = useRef(null);
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [isEditable, setIsEditable] = useState(true);
    const [formInputData, setFormInputData] = useState({
        slugUrl: '',
        blogTitle: '',
        status: ''
    });

    const apiCalls = new CommonCalls();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const blogId = params.get("blogid");

    const userInfo = useSelector((state) => state.userLogin.userInfo);
    const user_id = userInfo?.user_id;
    const is_admin = userInfo?.is_admin;

    useEffect(() => {
        const fetchBlogById = async () => {
            if (blogId) {
                setLoading(true);
                try {
                    const response = await apiCalls.getBlogById(blogId);
                    if (response) {
                        setContent(response.blog_content || '');

                        const statusLabel = Settings.enumTypesForStatus.find(status => status.value === response.status)?.label || 'Pending';

                        setFormInputData({
                            slugUrl: response.blog_slug_url || '',
                            blogTitle: response.blog_title || '',
                            status: statusLabel,
                        });
                        setUpdating(true);

                        if (response.created_by !== user_id) {
                            setIsEditable(false);
                        }
                    }
                } catch (error) {
                    console.error('Failed to fetch blog:', error);
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchBlogById();
    }, [blogId, user_id]);

    const config = useMemo(() => ({
        readonly: !isEditable,
        placeholder: placeholder || 'Start typing...',
    }), [placeholder, isEditable]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isEditable) return;

        const statusValue = Settings.enumTypesForStatus.find(status => status.label === formInputData.status)?.value || 2;

        const payload = {
            blog_content: content,
            blog_slug_url: formInputData.slugUrl,
            blog_title: formInputData.blogTitle,
            status: is_admin ? statusValue : 2,
            updated_by: user_id,
        };

        setLoading(true);

        try {
            let res;
            if (updating) {
                res = await apiCalls.updateBlogItem(blogId, payload);
            } else {
                payload.created_by = user_id;
                res = await apiCalls.addNewBlog(payload);
            }

            if (res.status === 200 || res.status === 201) {
                alert(updating ? 'Blog updated successfully!' : 'Blog added successfully!');
            }
        } catch (error) {
            console.error('Error submitting blog:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ padding: "20px" }}>
            <h1 className='h1_main mt-2'>{isEditable ? 'Edit Blog' : 'View Blog'}</h1>
            {loading ? (
                <Loader />
            ) : (
                <form onSubmit={handleSubmit}>
                    <div className="d-flex justify-content-end p-1 mx-2">
                        {isEditable && (
                            <button type="submit" className="connect-button my-2" disabled={loading}>
                                <div>
                                    <span>{updating ? 'Update' : 'Submit'}</span>
                                    {loading ? <output className="spinner-border spinner-border-sm ms-1" aria-hidden="true"></output> : ''}
                                </div>
                            </button>
                        )}
                    </div>
                    <FieldsRenderer
                        fieldsArray={[
                            {
                                label: "Blog Title",
                                className: "col-lg-12",
                                inputType: "text",
                                name: "blogTitle",
                                id: "blogTitle",
                                required: true,
                                disabled: !isEditable  
                            },
                            {
                                label: "Blog Slug URL",
                                className: "col-lg-12",
                                inputType: "text",
                                name: "slugUrl",
                                id: "slugUrl",
                                required: true,
                                disabled: !isEditable  
                            },
                            {
                                label: "Status",
                                className: "col-lg-12",
                                inputType: `${is_admin && "dropdown"}`,
                                name: "status",
                                id: "status",
                                options: Settings.enumTypesForStatus,
                                required: true,
                                disabled: !(is_admin && isEditable),
                                value: formInputData.status
                            }
                        ]}
                        updateState={setFormInputData}
                        currentState={formInputData}
                    />
                    <div style={{ height: "10px" }}></div>

                    <JoditEditor
                        ref={editor}
                        value={content}
                        config={config}
                        tabIndex={1}
                        onBlur={newContent => setContent(newContent)}
                        onChange={newContent => { }}
                    />
                </form>
            )}
        </div>
    );
};

export default BlogsEditor;
