import React from 'react';
import './EventsInner.css';

const EventsInner = () => {
    return (
        <div className='main-section-container'>
            <div className='container'>
                <div className='events-inner-section'>
                    <button className='connect-button justify-content-center'>Tue,Aug 27 2024</button>
                    <h1 className='head-h1'>Mr. Zain Jeewanjee visited Raza Foundation</h1>
                    <p className='para_main'>Convallis vivamus at cras porta Convallis vivamus at cras porta Convallis vivamus at cras porta Convallis Convallis vivamus at cras porta Convallis vivamus at cras porta Convallis vivamus at cras porta Convallis Convallis vivamus at cras porta Convallis vivamus at cras porta Convallis vivamus at cras porta Convallis Convallis vivamus at cras porta Convallis vivamus at cras porta Convallis vivamus at cras porta Convallis</p>
                </div>
                <div className='img-conatiner'>
                    <img src='/images/creatives/Mehfil.webp' alt='' className='img-fluid w-100' />
                </div>
            </div>
        </div>
    )
}

export default EventsInner;