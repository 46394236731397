import React, { useRef, useState, useEffect } from 'react';
import "./AdminHeader.css";
import Modal from "../../Model/Modal";
import { useSelector, useDispatch } from 'react-redux';
import { logout } from "../../../store/actions/userActions";
import { useNavigate } from 'react-router-dom';
import { showAlertMessage } from '../../MasterComponents/CommonFunctionalities';

function AdminHeader({ setToggleSidebar, hideState }) {
    const dropdownRef = useRef();
    const [changeArrowcolor, setChangeArrowColor] = useState(false);
    const [isDropdownOpenDesk, setIsDropdownOpenDesk] = useState(false);
    const [showProfile, setShowProfile] = useState(false);
    const [showCart, setShowCart] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const cartItems = useSelector((state) => state.cartItems);

    const closeModalProfile = () => setShowProfile(false);
    const closeModalCart = () => setShowCart(false);

    const hasAccess = (module) => {
        return userInfo?.modules_access.includes(module);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) && isDropdownOpenDesk) {
                if (!event.target.closest('.app')) {
                    setIsDropdownOpenDesk(false);
                }
            }
        };
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isDropdownOpenDesk, dropdownRef]);

    const logoutHandler = () => {
        showAlertMessage("Logout Successfully", "success");
        dispatch(logout());
        navigate("/");
        window.location.reload(false);
    };


    return (
        <div className='AdminHeader-container py-3'>
            <div className='container-fluid my-3'>
                <div className='d-flex align-items-center justify-content-between ps-0 pe-0 ps-md-3 pe-md-3'>
                    <div className='d-flex align-items-center gap-3'>
                        <div className='menu-btn-container d-flex gap-3 align-items-center'
                            onClick={() => { setToggleSidebar(!hideState) }}>
                            {hideState ?
                                <img className='img-fluid menu-icon' src='/images/icons/Icon-hamburger.svg' alt='menu icon' />
                                :
                                <img className='img-fluid menu-icon' src='/images/icons/cross-icon.menu.png' alt='menu icon' />
                            }
                        </div>
                        <p className='h3_main d-flex align-items-start'>Menu</p>
                        <button className='connect-button mt-0' onClick={() => { navigate("/") }}>
                            <img src='/images/icons/exit-icons.png' /> Exit Portal
                        </button>

                    </div>

                    <div className='profile-section d-flex align-items-center gap-2 gap-md-4'>
                        <div className='bar'></div>
                        <div className='d-flex align-items-center gap-1'>
                            <p className='title mb-0 user'>{userInfo?.full_name}</p>

                            <div className="navigation-dropdown" ref={dropdownRef} onMouseEnter={() => { setIsDropdownOpenDesk(true) }}>
                                <div className="dropdown-trigger d-flex align-items-center"
                                    onMouseOver={() => { setChangeArrowColor(true) }}
                                    onMouseOut={() => { setChangeArrowColor(false) }}>
                                    <img alt='user icon' className='menu-icon' src='/images/icons/profile-icon.svg' />
                                    <img src={`/images/icons/icon-arrow.svg`} className={isDropdownOpenDesk ? "rotate-icon arrow-icon" : "rotate-back arrow-icon"} />
                                </div>
                                <div className={`dropdown-content ${isDropdownOpenDesk ? 'open' : 'none-delay'}`}
                                    onMouseEnter={() => { setIsDropdownOpenDesk(true) }}
                                    onMouseLeave={() => { setIsDropdownOpenDesk(false) }}>

                                    <div className="nav-links font-Open-Sans p-2" onClick={() => { setIsDropdownOpenDesk(false); setShowProfile(true) }}>
                                        <p onClick={() => {
                                            navigate('/');
                                        }} className="mb-0" style={{ textTransform: "uppercase" }}>Profile</p>
                                    </div>
                                    <div className="nav-links font-Open-Sans p-2" onClick={() => { setIsDropdownOpenDesk(false); logoutHandler() }}>
                                        <p className="mb-0" style={{ textTransform: "uppercase" }}>Logout</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                {/* {showProfile && (
                    <Modal status={showProfile}>
                        <ProfilePopup closeModal={closeModalProfile} />
                    </Modal>
                )}
                {showCart && (
                    <Modal status={showCart}>
                      
                        <CartPopup closeModal={closeModalCart} />
                    </Modal>
                )} */}
            </div>
        </div>
    );
}

export default AdminHeader;
