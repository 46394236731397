import React from 'react';
import './CommonHeroComponent.css';

const CommonHeroComponent = (props) => {
  return (
    <div className="common-hero" style={{ backgroundImage: `url(${props.backgroundImg})` }}>
      <div className='container'>
        <div className="Common-Hero-content-overlay">
          <div className='ads-hero-content'>
            <h3 className='para_main'>{props.adsTitle}</h3>
            <h1 className='ads-title'>{props.adsHeading}</h1>
          </div>
          <div className="Common-Hero-content text-center">


            <h4 className='h4_main' style={{color:`${props.textWhite? "white":"#7F7F7F"}`}}>{props.title}</h4>
            <h1 className='head-h1' style={{color:`${props.textWhite? "white":"#1972B7"}`}}>{props.Heading}</h1>
            {props.SubPara && <p className='para_main text-lg-center' style={{fontWeight:"bold",color:`${props.textWhite? "white":"#7F7F7F"}`}}>{props.SubPara}</p>}
            <p className='para_main text-lg-center' style={{color:`${props.textWhite? "white":"#7F7F7F"}`}}>{props.Para}</p>
         
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommonHeroComponent;