import React from 'react';
import CommonHeroComponent from '../components/CommonHeroComponent/CommonHeroComponent';
import VisionAndMission from '../components/VisionAndMission/VisionAndMission';
import NewsLetter from '../components/NewsLetter/NewsLetter';

const BalaghPage = () => {
    return (
        <div>
            <CommonHeroComponent
                Heading="BALAGH"
                Para="Balagh is Raza Foundation’s initiative to spread the message of Islam through
educational resources, community programs, and outreach efforts."
                backgroundImg="/images/creatives/khidmat-e-khalq-banner.jpg" />

            <VisionAndMission heading="About" bluespan=" Balagh" Para="Balagh is a critical initiative by the Raza Foundation that focuses on spreading the
message of Islam far and wide. The term Balagh is derived from the Quranic concept
of delivering a clear and profound message, as commanded by Allah in Surah AlMa'idah (5:67): “O Messenger, deliver what has been revealed to you from your Lord...”
Through Balagh, we aim to create a platform for Islamic outreach by providing
educational resources, holding seminars, distributing literature, and offering courses
that deepen the understanding of Islam. This initiative is about engaging with individuals
and communities to foster a love for Islamic teachings and encourage the application of
these teachings in daily life."
                bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
            
                image="/images/creatives/khidmat-e-khalq-one.png"
                background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" />

            <VisionAndMission flexdirection="row-reverse" heading="Donate to Support" bluespan=" Balagh’s Mission" Para="Your donations to Balagh directly contribute to spreading the light of Islam to individuals
and communities that may not have access to authentic religious knowledge. With your
help, we can print and distribute Islamic literature, organize educational events, and
offer outreach programs to teach and inspire others about the core values of Islam. As
the Prophet Muhammad (PBUH) said, “Convey from me, even if it is one verse.”
Supporting Balagh means you are part of this divine effort, delivering the message of
Allah to those who need it most. Your contributions help sustain this noble cause,
earning you endless rewards in both this life and the Hereafter."
              
                image="/images/creatives/khidmat-e-khalq-two.png" />

            <VisionAndMission heading="Easy Ways to Make an Impact" bluespan=" Through Balagh" Para="Contributing to Balagh is a simple yet powerful way to bring Islamic knowledge to more
people. Whether through financial donations or volunteering your time, you can play a
key role in spreading the message of Islam. Every contribution, no matter how small,
can create ripples of positive change, leading others to the path of faith. The Quran
reminds us in Surah Ibrahim (14:1): “This is a Book which We have revealed to you that
you might lead mankind out of darkness into light...” By supporting Balagh, you are
participating in a cause that illuminates hearts and strengthens the ummah." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
              
                image="/images/creatives/khidmat-e-khalq-three.png" />

            <NewsLetter background="transparent linear-gradient(38deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" />
        </div>
    )
}

export default BalaghPage;