import React from 'react'
import NewsLetter from '../components/NewsLetter/NewsLetter'
import ChildHomeHero from '../components/ChildHomeHero/ChildHomeHero'

const ConsultationPhysician = () => {
    return (
        <div>


            <ChildHomeHero
                title="Introduction" bluespan="Consultation Physician" Para="The Consultations Physician service at Raza Foundation is dedicated to offering
                professional medical advice and comprehensive treatment plans for various health
                conditions. Our experienced physicians provide detailed consultations to diagnose and
                address health concerns, ensuring that individuals receive the best possible care. Islam
                places great importance on seeking knowledge and guidance, as reflected in the
                Hadith, “The seeking of knowledge is obligatory for every Muslim.” Through this service,
                we aim to uphold this principle by making expert medical consultations accessible to all." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
                button="Donate Now"
                image="/images/creatives/Consultations-Physician.webp"
                background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" />

            <NewsLetter />
        </div>
    )
}

export default ConsultationPhysician