import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './EventGrid.css';

function EventGrid() {
    const cards = [
        {
            title: "Hajj",
            text: "Education",
            button: "Tue, Aug 27 2024",
            image: "/images/creatives/services-kaaba.png",
            para: "Convallis vivamus at cras porta",
            link: "#"
        },
        {
            title: "Qurbani",
            text: "Education",
            button: "Tue, Aug 27 2024",
            image: "/images/creatives/services-cow.png",
            para: "Convallis vivamus at cras porta",
            link: "/ads"
        },
        {
            title: "Rabi-awal",
            text: "Education",
            button: "Tue, Aug 27 2024",
            image: "/images/creatives/EidMiladunNabi.webp",
            para: "Convallis vivamus at cras porta",
            link: "/ads"
        },
        {
            title: "Khamt-e-Nabuwat",
            text: "Education",
            button: "Tue, Aug 27 2024",
            image: "/images/creatives/Khatm-e-Naboowat.webp",
            para: "Convallis vivamus at cras porta",
            link: "/ads"
        },
        {
            title: "Mehfil",
            text: "Education",
            button: "Tue, Aug 27 2024",
            image: "/images/creatives/Mehfil.webp",
            para: "Convallis vivamus at cras porta",
            link: "/ads"
        },
    ];

    return (
        <div className='outside'>
            <Container className='event-grid'>
                <Row>
                    {cards.map((card, index) => (
                        <Col sm={12} md={6} lg={4} key={index} className="mb-4">
                            <Card style={{ border: 'none' }}>
                                <Card.Img variant="top" src={card.image} />
                                <Card.Body className='card-body'>
                                    {/* <Card.Subtitle className="mb-2 text-muted">{card.text}</Card.Subtitle> */}
                                    <button className='connect-button mb-4'>{card.button}</button>
                                    <Card.Title className='card-title'>{card.title}</Card.Title>
                                    <p className='para_main'>{card.para}</p>
                                    {/* <Card.Link className='href' href={card.link}>Read More →</Card.Link> */}
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
}

export default EventGrid;