import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CommonHeroComponent from '../components/CommonHeroComponent/CommonHeroComponent'
import VisionAndMission from '../components/VisionAndMission/VisionAndMission';
import NewsLetter from '../components/NewsLetter/NewsLetter';
import DonationForm from '../components/DonationFormComponent/DonationForm';

const DonationPage = () => {
    const [selectedService, setSelectedService] = useState('');
    const location = useLocation();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const serviceId = query.get('service');
        if (serviceId) {
            setSelectedService(serviceId);
        }
    }, [location]);
    return (
        <div>
            <CommonHeroComponent Heading="DONATIONS"
                Para="Your Generosity Fuels Our Mission—Donate Today and Change Lives!"
                backgroundImg="/images/creatives/Donation-Banner.webp" />

            <DonationForm defaultService={selectedService} />

            <VisionAndMission heading="About" bluespan=" Donations" Para="Donations play a crucial role in fulfilling the mission of Raza Foundation. In Islam, giving charity (Sadaqa) and Zakat is not just a good deed but a fundamental duty that purifies wealth and soul. By donating, you help provide essential services like education, healthcare, and community support to those in need. Your contributions embody the spirit of compassion and selflessness that Islam promotes, ensuring that no one is left behind. Each donation brings us closer to creating a world where everyone can thrive, reflecting the core values of our faith." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
                image="/images/creatives/donation-one.png" />

            <NewsLetter background="transparent linear-gradient(38deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" />
        </div>
    )
}

export default DonationPage