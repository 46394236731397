import React from 'react';
import ChildHomeHero from '../components/ChildHomeHero/ChildHomeHero';
import NewsLetter from '../components/NewsLetter/NewsLetter';

const DastarkhowaanTharparkarPage = () => {
    return (
        <div>
            <ChildHomeHero
                title="Introduction" bluespan="Dastarkhowan Tharparker" Para="Thar Parker is one of Pakistan’s most drought-affected regions, where many families
                struggle to meet their daily food needs. The Dastarkhan Thar Parker initiative by Raza
                Foundation ensures that nutritious meals are provided to those who are most in need,
                especially during times of crisis. In Islam, feeding the hungry holds immense reward, as
                the Prophet Muhammad (PBUH) said: “He who feeds a hungry person, Allah will feed
                him from the fruits of Paradise.” By supporting this cause, we can ensure that the
                people of Thar Parker do not go to sleep hungry." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
                button="Donate Now"
                // image="/images/creatives/Dastarkhuwan-Thar-parkar.webp"
                image="/images/creatives/Dastarkhuwan-Thar-parkar.png"
                background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" />

            <NewsLetter />
        </div>
    );
};

export default DastarkhowaanTharparkarPage;