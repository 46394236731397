import React, { useState, useEffect } from "react";
import "./HeaderSliderWraper.css";
import AdminHeader from "../AdminHeader/AdminHeader";
import SideSlider from "../SideSlider/SideSlider";
import { useSelector } from "react-redux";
import { showAlertMessage } from "../../MasterComponents/CommonFunctionalities";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";

function HeaderSliderWrapper({ children }) {
  const [hideSideBar, setHideSideBar] = useState(true);
  const userInfo = useSelector((state) => state.userLogin.userInfo);
  const navigate = useNavigate();
  const location = useLocation();

  const [is_admin,setIsadmin] = useState(userInfo?.is_admin)
  const [modules_access,setModuleAccess] = useState(userInfo?.modules_access || [])
  const [loading,setLoading] = useState(true)
  
  const options = [
    { title: "Donation User", image: "/images/icons/menu-icon-one.svg", link: "/portal/donation-users", module_id: 2 },
    { title: "System User", image: "/images/icons/menu-icon-two.svg", link: "/portal/system-users", module_id: 3 },
    { title: "Services", image: "/images/icons/menu-icon-three.svg", link: "/portal/services-users", module_id: 4 },
    { title: "Blogs", image: "/images/icons/menu-icon-four.svg", link: "/portal/all-blogs", module_id: 5 },
    { title: "Pending Actions", image: "/images/icons/menu-icon-five.svg", link: "/portal/pending-blogs", module_id: 6 },
    { title: "Subscribe Users", image: "/images/icons/menu-icon-six.svg", link: "/portal/subscribe-user", module_id: 7 },
    { title: "Qurbani", image: "/images/icons/menu-icon-seven.svg", module_id: 8 },
    { title: "Ask a Mufti", image: "/images/icons/menu-icon-nine.png",link:"/portal/ask-a-mufti", module_id: 9 },
  ];

  const [moduleAr,setModuleAr] = useState(options.filter(option =>userInfo?.modules_access.includes(option.id)))
  
  const filteredOptions = is_admin
    ? options
    : options.filter(option => modules_access.includes(option.module_id));

  useEffect(() => {
    if (location.pathname === "/portal" && filteredOptions.length > 0) {
      navigate(filteredOptions[0].link);
    }
  }, [location, navigate, filteredOptions]);

  useEffect(() => {
    setHideSideBar(!is_admin);
  }, [is_admin]);

  useEffect(()=>{
    setIsadmin(userInfo?.is_admin)
    setModuleAccess(userInfo?.modules_access || [])
    setModuleAr(options.filter(option => userInfo?.modules_access.includes(option.module_id)))
  },[userInfo])

  if ((!is_admin && modules_access.length === 0) ) {
    showAlertMessage("You are not authorized to view this page. Please contact your administrator.", "fail");
    return (
      <div className="unauthorized-container">
        <button className="connect-button" onClick={() => navigate("/")}>
          Back to Home
        </button>
      </div>
    );
  }
  else if (!is_admin && !moduleAr.some(module => module.link === window.location.pathname)) 
  {
    showAlertMessage("You are not authorized to view this page. Please contact your administrator.", "fail");
    return (
      <div className="unauthorized-container">
        <button className="connect-button" onClick={() => navigate("/")}>
          Back to Home
        </button>
      </div>
    );
  }
  else
  {
    return (
      <div className="HeaderSliderWrapper-container">
        <AdminHeader setToggleSidebar={setHideSideBar} hideState={hideSideBar} />
        <div className="container-fluid">
          <div className="row">
            {!hideSideBar && (
              <div className="col-lg-3">
                <SideSlider setToggleSidebar={setHideSideBar} sideOptions={filteredOptions} />
              </div>
            )}
            <div className={hideSideBar ? "col-lg-12 m-0 p-0" : "col-lg-9 m-0 p-0"}>
              {children}
            </div>
          </div>
        </div>
      </div>
    );
  }
  
}

export default HeaderSliderWrapper;
