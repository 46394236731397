import React from 'react'
import CommonHeroComponent from '../components/CommonHeroComponent/CommonHeroComponent'
import EventsNews from '../components/EventsNewsSection/EventsNews'
import EventGrid from '../components/EventsGrids/EventGrid'

const EventsPage = () => {
    return (
        <div>
            <CommonHeroComponent Heading="EVENTS" Para="Convallis vivamus at cras porta nibh velit aliquam eget in faucibus mi tristique aliquam ultrices sit cras nascetur in elementum placerat sed viverra risus in turpis vitae sed est tincidunt vitae." backgroundImg="/images/creatives/events-banner.jpg" />

            <EventsNews />
            <EventGrid />
        </div>
    )
}

export default EventsPage