import React from 'react'
import CommonHeroComponent from '../components/CommonHeroComponent/CommonHeroComponent'
import VisionAndMission from '../components/VisionAndMission/VisionAndMission'
import NewsLetter from '../components/NewsLetter/NewsLetter'

const MehfilPage = () => {
  return (
    <div>
      <CommonHeroComponent
        Heading="Islamic Event"
        Para="Support our mehfil events that bring communities together in celebration and remembrance of religious and cultural traditions."
        backgroundImg="/images/creatives/mehfil-banner.jpg" />

      <VisionAndMission heading="About" bluespan=" Mehfil" Para="Mehfil events are gatherings for religious and cultural celebrations, fostering community spirit and spiritual enrichment. Raza Foundation organizes mehfil-e-Milad to bring people together in remembrance of Allah & Holy Prophet (SAWW) and educate people about religion to place them back on the righteous path of Islam. " bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
        image="/images/creatives/mehfil-one.png"
        button="Donate Now"
        background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" />

      <VisionAndMission flexdirection="row-reverse" heading="How Your Mehfil" bluespan=" Donation Adds Value" Para="Supporting mehfil-e-Milad events through Raza Foundation helps promote unity and spiritual growth within the community. These gatherings are a reflection of Islamic culture and values, providing a platform for communal worship and celebration. Your donations ensure the success and continuity of these cherished events."
        image="/images/creatives/mehfil-two.png"
        button="Donate Now" />

      <VisionAndMission bluespan="Donating for Mehfil-e-Milad and Its Impact" Para="Donating for mehfil events is easy with Raza Foundation's streamlined process. Your contributions help organize meaningful gatherings that strengthen community bonds and enrich spiritual lives. By supporting mehfil events, you play a vital role in preserving and celebrating Islamic traditions."
        image="/images/creatives/mehfil-three.png"
        button="Donate Now" />

      <NewsLetter background="transparent linear-gradient(38deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" />
    </div>
  );
};

export default MehfilPage;