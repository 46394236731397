import "./Articles.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from "swiper/modules";
import { Container, Row, Col, Card } from 'react-bootstrap';


// const handlePrevButtonClick = () => {
//   const swiperInstance = document.querySelector('#articles-cards-slider').swiper;
//   swiperInstance.slidePrev();
// };

// const handleNextButtonClick = () => {
//   const swiperInstance = document.querySelector('#articles-cards-slider').swiper;
//   swiperInstance.slideNext();
// };

function Articles({
  subHeading,
  heading,
  content,
  spanHeading,
  button
}) {

  const cards = [
    {
      title: "Healthcare Access initiative",
      text: "Education",
      image: "/images/creatives/cards.jpg",
      para: "Convallis vivamus at cras porta nibh velit aliquam eget",
      link: "#"
    },
    {
      title: "Healthcare Access initiative",
      text: "Education",
      image: "/images/creatives/articles-img.jpg",
      para: "Convallis vivamus at cras porta nibh velit aliquam eget",
      link: "#"
    },
    {
      title: "Healthcare Access initiative",
      text: "Education",
      image: "/images/creatives/articles-img1.jpg",
      para: "Convallis vivamus at cras porta nibh velit aliquam eget",
      link: "#"
    },

  ];

  return (
    <div className='Articles-container standard-padding-space'>
      <div className="Articles-content mb-4">
        <div className="container">
          <div className="row d-flex justify-content-between">
            <div className="col-md-6">
              <div className="Articles-text-content">
                <p className="secondary-h1 d-lg-block d-none">{subHeading}</p>
                <h3 className="head-h1 mb-0">{heading}<span className="color-yellow">{spanHeading}</span></h3>
                {content && <p className="body-paragraph">{content}</p>}

              </div>
            </div>

            <div className="col-md-6 services-content-class">
              <p className="secondary-h1 d-lg-none">{subHeading}</p>
              <p className="para_main">Charity 128 recognizes the importance of collective action in creating lasting change. We invite individuals, corporations, and communities to join us in our mission. Charity 128 recognizes the importance of collective action in creating lasting change.</p>
              <button className="connect-button  mt-3">Learn more</button>
            </div>
          </div>

          <div className='outside'>
            <Container className='main-cardgrid'>
              <Row>
                {cards.map((card, index) => (
                  <Col sm={12} md={6} lg={4} key={index} className="mb-4">
                    <Card style={{ border: 'none' }}>
                      <Card.Img variant="top" src={card.image} />
                      <Card.Body className='card-body'>
                        <Card.Subtitle className="mb-2 text-muted">{card.text}</Card.Subtitle>
                        <Card.Title className='card-title'>{card.title}</Card.Title>
                        <p className='para_main'>{card.para}</p>
                        <Card.Link className='href' href={card.link}>Read More →</Card.Link>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Articles;