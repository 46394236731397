import React from 'react'
import "./AskAMuftiWorking.css"

function AskAMuftiWorking() {
  return (
    <div className='AskAMuftiWorking-container py-5'>
        <div className='container py-2'>

            <div className='text-center'>
                <h3 className='main-heading mb-3 body-heading'>Guiding You, One Question at a Time</h3>
                <p className='para_main px-4 my-2 '>Seeking Islamic guidance has never been easier. </p>
                <h3 className='sub-heading'>Here’s how it works:</h3>
            </div>

           
            <div className="table-container mt-4">
           
            <div className="table-row">
                <div className="table-cell">
                <h3>Ask Your Question</h3>
                <p>
                    Whether it’s about daily practices, understanding a particular
                    concept, or seeking advice in your journey, simply fill out the
                    form below.
                </p>
                </div>
                <div className="table-cell">
                <h3>Reviewed By Scholars</h3>
                <p>
                    All questions are treated with the utmost respect and
                    confidentiality. We create a safe space for open, honest
                    conversations.
                </p>
                </div>
                <div className="table-cell">
                <h3>Get A Clear Answer</h3>
                <p>
                    Your question will be answered by one of our qualified Alims,
                    drawing from Islamic teachings, tradition, and wisdom.
                </p>
                </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default AskAMuftiWorking