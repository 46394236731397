import React from 'react';
import NewsLetter from '../components/NewsLetter/NewsLetter'
import ChildHomeHero from '../components/ChildHomeHero/ChildHomeHero'

const BalochistanPage = () => {
    return (
        <div>



            <ChildHomeHero
                title="Introduction" bluespan="Balochistan" Para="Balochistan, Pakistan’s largest province, faces significant challenges including poverty,
                lack of access to clean water, limited healthcare, and educational opportunities. The
                Raza Foundation is dedicated to bringing relief to the people of Balochistan by providing
                essential resources and services. In Islam, helping those in need, particularly those who
                are suffering, is a deed of great reward. The Prophet Muhammad (PBUH) said: He is
                not a believer whose stomach is filled while his neighbor goes hungry. By contributing
                to this cause, we offer hope and change to a region in dire need of support." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
                button="Donate Now"
                image="/images/creatives/Balochistan.webp"

                background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" />
                <NewsLetter />
        </div>
    )
}

export default BalochistanPage