import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './CardGrid.css';
import { useNavigate } from 'react-router-dom';
import CommonCalls from '../CommonAPICalls/CommonCalls';

function CardGrid() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const apiCall = new CommonCalls();

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await apiCall.getBlogsItems();
        setBlogs(response.blogs || response);
      } catch (error) {
        console.error('Error fetching blogs:', error);
        setError('Error fetching blogs');
      }
      setLoading(false);
    };

    fetchBlogs();
  }, []);

  const stripHtml = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  };

  const truncateContent = (content, maxWords) => {
    const plainTextContent = stripHtml(content);
    const words = plainTextContent.split(' ');
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(' ') + '...';
    }
    return plainTextContent;
  };

  const redirectUserToBlog = (blog_slug_url) => {
    if (!blog_slug_url) return;
    navigate(`/blog/${blog_slug_url}`);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className='outside'>
      <Container className='main-cardgrid'>
        <Row>
          {blogs.length > 0 ? (
            blogs.map((blog) => (
              <Col sm={12} md={6} lg={4} key={blog._id} className="mb-4">
                <Card style={{ border: 'none' }}>
                  <Card.Img variant="top" src={blog.image || '/images/creatives/cards.jpg'} />
                  <Card.Body className='card-body'>
                    <Card.Title className='card-title'>{blog.blog_title}</Card.Title>
                    <p
                      className='para_main'
                      dangerouslySetInnerHTML={{ __html: truncateContent(blog.blog_content, 20) }}
                    />
                    <Card.Link className='href' onClick={() => { redirectUserToBlog(blog.blog_slug_url) }}>Read More →</Card.Link>
                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : (
            <Col>
              <div>No blogs available</div>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
}

export default CardGrid;
