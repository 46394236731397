import React, { useEffect, useState } from "react";
import "./DataGrid.css";
import Loader from "../Simple Loading/Loader";

/*

No Pagination -> pagination prop to be false and totalrecords must be sended as the length of api response returned
checkboxRequired to be false if not needed

*/

const CustomDataGrid = ({ data, hideDeleteBtn = false, hideAddBtn = false, hideAllBtns = false, columns, pagination = false, onCellClicked, onAddClick, onSelectedRowsDelete, loadingState = false, checkboxRequired = false, pageSize = 10, totalRecords, currentPage = 1, onPageChange = () => { }, onPageSizeChange, handleCheckboxChange }) => {
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    onPageChange(1);
  }, [pageSize]);

  useEffect(() => {
    setSelectedRows([]);
  }, [data]);

  const totalPages = Math.ceil(totalRecords / pageSize);

  const handleSelectRow = (id, row) => {
    setSelectedRows((prev) =>
      prev.some((selectedRow) => selectedRow._id === id)
        ? prev.filter((selectedRow) => selectedRow._id !== id)
        : [...prev, row]
    );
  };

  const handlePageSizeChange = (e) => {
    onPageChange(1);
    onPageSizeChange(parseInt(e.target.value, 10));
  };

  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return data?.slice(startIndex, endIndex);
  };

  const onGridCellClicked = (rowObj, colKey) => {
    if (onCellClicked) {
      onCellClicked(rowObj, colKey)
    }
  }

  const onAddBtnClick = () => {
    if (onAddClick) {
      onAddClick()
    }
  };

  const onDeleteBtnClick = () => {
    if (onSelectedRowsDelete) {
      onSelectedRowsDelete(selectedRows);
    }
  };

  const rowsToRender = pagination ? data : getPaginatedData();

  const renderGrid = () => {
    return loadingState ? (
      null
    ) : (
      rowsToRender?.map((row) => (
        <tr key={row._id} className={selectedRows.some((selectedRow) => selectedRow._id === row?._id) ? "selected" : ""}>
          {columns?.map((col, index) => (
            <td key={col?.field}>
              <span className="parent_checkbox">
                {index === 0 && checkboxRequired && (
                  <input
                    type="checkbox"
                    checked={selectedRows.some((selectedRow) => selectedRow._id === row?._id)}
                    onChange={() => handleSelectRow(row?._id, row)}
                  />
                )}
              </span>
              <span onClick={() => onGridCellClicked(row, col?.field)}>
                {col?.isCheckBox ? (
                  <input
                    type="checkbox"
                    defaultChecked={row[col?.field] == col?.referenceValue}
                    // checked={row[col?.field] == col?.referenceValue}
                    onChange={(e) => handleCheckboxChange(row, col?.field, e.target.checked, col?.referenceValue)}

                  />
                ) : (
                  col?.customRendererLink
                    ? col?.cellRenderer(row[col?.field])
                    : col?.inputType === "dropdown"
                      ? col?.options?.find(item => item?.value == row[col?.field])?.label
                      : row[col?.field]
                )}
              </span>
            </td>
          ))}
        </tr>
      ))
    );
  };

  return (
    <div className="data-grid">
      {!hideAllBtns &&
        <div className="grid-action-buttons">
          {!hideAddBtn && <button onClick={onAddBtnClick}><span><img src='/images/icons/add-icon.png' /></span> Add</button>}
          {!hideDeleteBtn && <button disabled={loadingState || data?.length === 0} onClick={onDeleteBtnClick}><span><img src='/images/icons/delete-icon-red.svg' /></span> Delete</button>}
        </div>
      }

      {loadingState ? <div className='mt-5'> <Loader /> </div> : null}

      <div className="grid-main-table">
        <table >
          <thead>
            <tr>
              {columns?.map((col) => (
                <th key={col?.field}>{col?.columnName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {renderGrid()}
          </tbody>
        </table>
      </div>

      <div className='pagination-btns'>
        <div className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => onPageChange(index + 1)}
              className={currentPage === index + 1 ? "active" : ""}
            >
              {index + 1}
            </button>
          ))}
        </div>

        <div className="page-size">
          <label>
            <span> Page Size:</span>
            <select value={pageSize} onChange={handlePageSizeChange}>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </select>
          </label>
        </div>
      </div>
    </div>
  );
};

export default CustomDataGrid; 