import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// use empty={true} when you required to show blank area on DatePicker field...
// here minDate, maxDate for set maximum and minimun date set into date AgPickerField...
const InputDatePicker = ({ label, name = '', id, value, empty = false, disable = false, onChange, timePicker = false, required = false, minDate, maxDate }) => {
    return (
        <div className='datepicker-wrapper'>
            <label htmlFor={label}>{label}{required ? <span style={{ color: 'red' }}>*</span> : ''}</label>
            <div className="rs_input_box_wraper" style={disable ? { opacity: 0.3 } : {}}>
                <DatePicker
                    id={id}
                    name={name}
                    className="form-control"
                    selected={value ? new Date(value) : empty ? null : new Date()}
                    onChange={(date) => onChange(name, date)}
                    dateFormat="d/M/yyyy h:mm aa"
                    showTimeSelect={timePicker}
                    timeFormat="HH:mm"
                    disabled={disable}
                    minDate={minDate ? minDate : null}
                    maxDate={maxDate ? maxDate : null}
                />
            </div>
        </div>
    )
};

export default InputDatePicker; 