export const Links1 = [
    {
        title: "Zakat/Fitrah",
        path: "/services/zakat-fitrah",
    },
    {
        title: "Nafil/Sadaqa ",
        path: "/services/nafil-sadaqa",
    },
];
export const Links2 = [
    {
        title: "Rashan",
        path: "/rashan",
    },
    {
        title: "Medical ",
        path: "/medical",
    },
    {
        title: "Education ",
        path: "/services/education",
    },
    {
        title: "Utilities ",
        path: "/utilities",
    },
    {
        title: "Wedding ",
        path: "/wedding",
    },
    {
        title: "Global Relief ",
        path: "/global-relief",
    },
];
export const Links3 = [{
    title: "Videos",
    path: "/videos",
},
{
    title: "Events",
    path: "/events",
},
];

export const Links4 = [{
    title: "Sadqa",
    path: "/sadqa",
},
{
    title: "Rashan",
    path: "/rashan",
},
{
    title: "Medical ",
    path: "/medical ",
},
{
    title: "Education",
    path: "/services/education",
},
{
    title: "Utilities ",
    path: "/utilities ",
},
{
    title: "Wedding",
    path: "/wedding",
},
{
    title: "Global Relief ",
    path: "/global-relief"
},
{
    title: "Tamerati Masjid",
    path: "/"
},
{
    title: "Qurbani",
    path: "/services/qurbani"
},
{
    title: "Box",
    path: "/box"
},
{
    title: "Mehfil",
    path: "/services/mehfil"
},
{
    title: "Sadat",
    path: "/sadat"
},
{
    title: "Trust Expenses ",
    path: "/trust-expenses"
},
{
    title: "Others",
    path: "/"
}
];

export const links = [
    {
        to: "/who-we-are",
        text: "About Us"
    },
    {
        to: "/careers",
        text: "Careers"
    },
    {
        to: "/refund-policy",
        text: "Refund Policy"
    },
    {
        to: "/privacy-policy",
        text: "Privacy Policy"
    },
    {
        to: "/contact-us",
        text: "Contact Us"
    }
];