import React, { useRef } from 'react';
import './HomeHero.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from "swiper/modules";
import { useNavigate } from 'react-router-dom';

const Homehero = () => {
  const navigate = useNavigate();
  const swiperRef = useRef(null);

  const handleMouseEnter = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.autoplay.stop();
    }
  };

  const handleMouseLeave = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.autoplay.start();
    }
  };

  const handleClick = () => {
    navigate('/donations');
  };

  const handleClicked = () => {
    navigate('/contact');
  };
  
  return (
    <div className="home-hero" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className="container">
        <Swiper
          ref={swiperRef}
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ clickable: true }}
          autoplay={{ delay: 2500, disableOnInteraction: true }}
          loop={true}
          modules={[Autoplay, Pagination]}
          observer={true}
          observeParents={true}
          parallax={true}
          className="testimonials-list"
          id="articles-cards-slider"
        >
          <SwiperSlide>
            <div className="row align-items-center homeslider-container">
              <div className="col-lg-6">
                <div className="Homehero-content">
                  <h4 className='main-head'>Share the Blessings</h4>
                  <h1 className='head-h1'>Celebrate Eid with Us by Offering Qurbani to the Needy</h1>
                  <p className='para_main'>Our Qurbani services ensure your sacrifices reach those who need them the most. Join us in sharing the blessings of Eid with the less fortunate.</p>
                  <a onClick={handleClicked} style={{ textDecoration: "none" }}><button className="connect-button">Book Now</button></a>

                  <div className="social-media-icons d-lg-none">
                    {/* <img src='/images/icons/Titktok-icon.svg' alt='icons' className='img-fluid' />
                    <img src='/images/icons/Youtube-icon.svg' alt='icons' className='img-fluid' /> */}
                    <a href="https://www.facebook.com/RazaFoundationPakistan?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
                      <img src='/images/icons/Facebook-icon.svg' alt='Facebook' className='img-fluid' />
                    </a>
                    <a href="https://www.instagram.com/raza_foundation_?igsh=a2Vzb2ZnbnU2Yndw" target="_blank" rel="noopener noreferrer">
                      <img src='/images/icons/Instagram-icon.svg' alt='Instagram' className='img-fluid' />
                    </a>
                    {/* <img src='/images/icons/Linkedln-icon.svg' alt='icons' className='img-fluid' />
                    <img src='/images/icons/Whatsapp-icon.svg' alt='icons' className='img-fluid' /> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <img src="/images/creatives/Banner-img.png" alt="Banner" className="img-fluid" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="row align-items-center homeslider-container">
              <div className="col-lg-6">
                <div className="Homehero-content">
                  <h4 className='main-head'>Join Hands with Us</h4>
                  <h1 className='head-h1'>Making a Difference with Your Generosity</h1>
                  <p className='para_main'>At Raza Foundation, your zakat and sadaqa bring hope and change to countless lives. Let's build a brighter future together!</p>
                  <a onClick={handleClick} style={{ textDecoration: "none" }}><button className="connect-button">Donate Now</button></a>
                  <div className="social-media-icons d-lg-none justify-content-center">
                    {/* <img src='/images/icons/Titktok-icon.svg' alt='icons' className='img-fluid' />
                    <img src='/images/icons/Youtube-icon.svg' alt='icons' className='img-fluid' /> */}
                    <a href="https://www.facebook.com/RazaFoundationPakistan?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
                      <img src='/images/icons/Facebook-icon.svg' alt='Facebook' className='img-fluid' />
                    </a>
                    <a href="https://www.instagram.com/raza_foundation_?igsh=a2Vzb2ZnbnU2Yndw" target="_blank" rel="noopener noreferrer">
                      <img src='/images/icons/Instagram-icon.svg' alt='Instagram' className='img-fluid' />
                    </a>
                    {/* <img src='/images/icons/Linkedln-icon.svg' alt='icons' className='img-fluid' />
                    <img src='/images/icons/Whatsapp-icon.svg' alt='icons' className='img-fluid' /> */}
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <img src="/images/creatives/Join-Hand-With-Us-two.webp" alt="Banner" className="img-fluid" />
              </div>
            </div>

          </SwiperSlide>

          {/* <SwiperSlide>
            <div className="row align-items-center home-hero" style={{ backgroundImage: "url('/images/creatives/Banner-image-one.webp')", backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat", height: "100vh" }}>
              <div className="col-lg-6">
                <div className="Homehero-content">
                  <h4 className='main-head'>A Step Towards a Brighter Future</h4>
                  <h1 className='head-h1'>Empowering Through Knowledge</h1>
                  <p className='para_main'>Your donations help us provide quality education to children in need, paving the way for a brighter and more prosperous future.</p>
                  <a href="/contact" style={{ textDecoration: "none" }}><button className="connect-button">Book Now</button></a>
                  <div className="social-media-icons d-lg-none">
                    <img src='/images/icons/Titktok-icon.svg' alt='icons' className='img-fluid' />
                    <img src='/images/icons/Youtube-icon.svg' alt='icons' className='img-fluid' />
                    <img src='/images/icons/Facebook-icon.svg' alt='icons' className='img-fluid' />
                    <img src='/images/icons/Instagram-icon.svg' alt='icons' className='img-fluid' />
                    <img src='/images/icons/Linkedln-icon.svg' alt='icons' className='img-fluid' />
                    <img src='/images/icons/Whatsapp-icon.svg' alt='icons' className='img-fluid' />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <img src="/images/creatives/banner-img.png" alt="Banner" className="img-fluid" />
              </div>
            </div>
          </SwiperSlide> */}
          <SwiperSlide>
            <div className="row align-items-center homeslider-container">
              <div className="col-lg-6">
                <div className="Homehero-content">
                  <h4 className='main-head'>Construction of Mosques</h4>
                  <h1 className='head-h1'>Creating Sacred Spaces for Communities</h1>
                  <p className='para_main'>Help us build mosques where communities can come together to pray and grow spiritually. Your contributions make these sacred spaces possible.</p>
                  <a onClick={handleClicked} style={{ textDecoration: "none" }}><button className="connect-button">Donate Now</button></a>
                  <div className="social-media-icons d-lg-none">
                    {/* <img src='/images/icons/Titktok-icon.svg' alt='icons' className='img-fluid' />
                    <img src='/images/icons/Youtube-icon.svg' alt='icons' className='img-fluid' /> */}
                    <a href="https://www.facebook.com/RazaFoundationPakistan?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
                      <img src='/images/icons/Facebook-icon.svg' alt='Facebook' className='img-fluid' />
                    </a>
                    <a href="https://www.instagram.com/raza_foundation_?igsh=a2Vzb2ZnbnU2Yndw" target="_blank" rel="noopener noreferrer">
                      <img src='/images/icons/Instagram-icon.svg' alt='Instagram' className='img-fluid' />
                    </a>
                    {/* <img src='/images/icons/Linkedln-icon.svg' alt='icons' className='img-fluid' />
                    <img src='/images/icons/Whatsapp-icon.svg' alt='icons' className='img-fluid' /> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <img src="/images/creatives/Construction-of-mosque.png" alt="Banner" className="img-fluid" />
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="row align-items-center homeslider-container">
              <div className="col-lg-6">
                <div className="Homehero-content">
                  <h4 className='main-head'>KHATAM-E-NABUWAT</h4>
                  <h1 className='head-h1'>7th September - Declaration of Qadianis as Non-Muslims in Pakistan</h1>
                  <p className='para_main'>Honoring the Finality of Prophethood, we uphold the core tenet of Islam that Prophet Muhammad (PBUH) is the last messenger. Join us in preserving and promoting this vital belief.</p>
                  {/* <a onClick={handleClick} style={{ textDecoration: "none" }}><button className="connect-button">Donate Now</button></a> */}
                  <div className="social-media-icons d-lg-none">
                    {/* <img src='/images/icons/Titktok-icon.svg' alt='icons' className='img-fluid' />
                    <img src='/images/icons/Youtube-icon.svg' alt='icons' className='img-fluid' /> */}
                    <a href="https://www.facebook.com/RazaFoundationPakistan?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
                      <img src='/images/icons/Facebook-icon.svg' alt='Facebook' className='img-fluid' />
                    </a>
                    <a href="https://www.instagram.com/raza_foundation_?igsh=a2Vzb2ZnbnU2Yndw" target="_blank" rel="noopener noreferrer">
                      <img src='/images/icons/Instagram-icon.svg' alt='Instagram' className='img-fluid' />
                    </a>
                    {/* <img src='/images/icons/Linkedln-icon.svg' alt='icons' className='img-fluid' />
                    <img src='/images/icons/Whatsapp-icon.svg' alt='icons' className='img-fluid' /> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 d-flex">
                <img src="/images/creatives/Khatm-e-Naboowat-new.png" alt="Banner" className="img-fluid mx-auto khatam-e-nabuwat" />
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="row align-items-center homeslider-container">
              <div className="col-lg-6">
                <div className="Homehero-content">
                  <h4 className='main-head'>EID MILAD-UN-NABI</h4>
                  <h1 className='head-h1'>Celebrating Milad-un-Nabi: The Tradition of Observing Mondays</h1>
                  <p className='para_main'>Observing Mondays through fasting to celebrate Milad-un-Nabi reflects the practice of the Holy Prophet Muhammad (Allah's Grace and Peace be upon him). This tradition commemorates both his birth and the revelation of the Quran, underscoring the day's significance in Islamic practice and devotion.</p>
                  {/* <a onClick={handleClick} style={{ textDecoration: "none" }}><button className="connect-button">Donate Now</button></a> */}
                  <div className="social-media-icons d-lg-none">
                    {/* <img src='/images/icons/Titktok-icon.svg' alt='icons' className='img-fluid' />
                    <img src='/images/icons/Youtube-icon.svg' alt='icons' className='img-fluid' /> */}
                    <a href="https://www.facebook.com/RazaFoundationPakistan?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
                      <img src='/images/icons/Facebook-icon.svg' alt='Facebook' className='img-fluid' />
                    </a>
                    <a href="https://www.instagram.com/raza_foundation_?igsh=a2Vzb2ZnbnU2Yndw" target="_blank" rel="noopener noreferrer">
                      <img src='/images/icons/Instagram-icon.svg' alt='Instagram' className='img-fluid' />
                    </a>
                    {/* <img src='/images/icons/Linkedln-icon.svg' alt='icons' className='img-fluid' />
                    <img src='/images/icons/Whatsapp-icon.svg' alt='icons' className='img-fluid' /> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 d-flex">
                <img src="/images/creatives/Eid-Milad.webp" alt="Banner" className="img-fluid mx-auto khatam-e-nabuwat" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="row align-items-center homeslider-container">
              <div className="col-lg-6">
                <div className="Homehero-content">
                  {/* <h4 className='main-head'>Ramzan</h4> */}
                  <h1 className='head-h1'>Ramzan</h1>
                  <p className='para_main'>At Raza Foundation, we are dedicated to serving humanity and supporting our community. This Ramzan, we continue our mission and support underprivileged families through the Ramzan Rashan Drive program. We encourage you all to participate in our mission and earn good deeds. Inflation is at its peak, and countless families struggle to afford even necessities. With your generous donations, we can provide essential food packages to those in need. Each packet includes 5kg flour, 5kg rice, lentils, salt, sugar, oil, and other staples to sustain a family for the month. Let’s come together and help our sisters and brothers in this blessed month.</p>
                  {/* <a onClick={handleClick} style={{ textDecoration: "none" }}><button className="connect-button">Donate Now</button></a> */}
                  <div className="social-media-icons d-lg-none">
                    {/* <img src='/images/icons/Titktok-icon.svg' alt='icons' className='img-fluid' />
                    <img src='/images/icons/Youtube-icon.svg' alt='icons' className='img-fluid' /> */}
                    <a href="https://www.facebook.com/RazaFoundationPakistan?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
                      <img src='/images/icons/Facebook-icon.svg' alt='Facebook' className='img-fluid' />
                    </a>
                    <a href="https://www.instagram.com/raza_foundation_?igsh=a2Vzb2ZnbnU2Yndw" target="_blank" rel="noopener noreferrer">
                      <img src='/images/icons/Instagram-icon.svg' alt='Instagram' className='img-fluid' />
                    </a>
                    {/* <img src='/images/icons/Linkedln-icon.svg' alt='icons' className='img-fluid' />
                    <img src='/images/icons/Whatsapp-icon.svg' alt='icons' className='img-fluid' /> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 d-flex">
                <img src="/images/creatives/Ramzan-home-slider.png" alt="Banner" className="img-fluid mx-auto khatam-e-nabuwat" />
              </div>
            </div>
          </SwiperSlide>
        </Swiper>

        <div className="swiper-pagination"></div>

        <div className="social-media-icons d-none d-lg-flex">
          {/* <a href="https://www.tiktok.com/@yourprofile" target="_blank" rel="noopener noreferrer">
            <img src='/images/icons/Titktok-icon.svg' alt='TikTok' className='img-fluid' />
          </a>

          <a href="https://www.youtube.com/channel/yourchannel" target="_blank" rel="noopener noreferrer">
            <img src='/images/icons/Youtube-icon.svg' alt='YouTube' className='img-fluid' />
          </a> */}

          <a href="https://www.facebook.com/RazaFoundationPakistan?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
            <img src='/images/icons/Facebook-icon.svg' alt='Facebook' className='img-fluid' />
          </a>

          <a href="https://www.instagram.com/raza_foundation_?igsh=a2Vzb2ZnbnU2Yndw" target="_blank" rel="noopener noreferrer">
            <img src='/images/icons/Instagram-icon.svg' alt='Instagram' className='img-fluid' />
          </a>

          {/* <a href="https://www.linkedin.com/in/yourprofile" target="_blank" rel="noopener noreferrer">
            <img src='/images/icons/Linkedln-icon.svg' alt='LinkedIn' className='img-fluid' />
          </a>

          <a href="https://wa.me/yourphonenumber" target="_blank" rel="noopener noreferrer">
            <img src='/images/icons/Whatsapp-icon.svg' alt='WhatsApp' className='img-fluid' />
          </a> */}
        </div>
      </div>
    </div>
  );
};

export default Homehero;
