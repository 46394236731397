import React from 'react'
import VisionAndMission from '../components/VisionAndMission/VisionAndMission'
import NewsLetter from '../components/NewsLetter/NewsLetter'
import ChildHomeHero from '../components/ChildHomeHero/ChildHomeHero'

const OPDPage = () => {
  return (
    <div>


      <ChildHomeHero
        title="Introduction" bluespan=" OPD" Para="The OPD service at Raza Foundation is dedicated to offering outpatient medical care to
         individuals who require routine check-ups and non-emergency treatments. We provide
         consultations, diagnostic services, and follow-up care to help manage and treat a
         variety of health conditions. Islam encourages providing care and support to those in
         need, as highlighted in the Hadith, “The merciful are shown mercy by The Merciful. Be
         merciful on the earth, and you will be shown mercy from above.” Through this service,
         we strive to embody this compassion by making essential healthcare services readily
         available to all."
        button="Donate Now"
        image="/images/creatives/OPD.webp"
        background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat"
     />

      <NewsLetter />
    </div>
  )
}

export default OPDPage