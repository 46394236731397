import React from 'react'
import NewsLetter from '../components/NewsLetter/NewsLetter';
import ChildHomeHero from '../components/ChildHomeHero/ChildHomeHero';

const ConsultationSpecialistPage = () => {
    return (
        <div>
            <ChildHomeHero
                title="Introduction" bluespan="Consultation Specialist" Para="The Consultation Specialist service at Raza Foundation is designed to connect
                individuals with experienced medical specialists who provide detailed evaluations and
                specialized care for complex health concerns. Our goal is to ensure that everyone has
                access to expert advice and personalized treatment plans. In Islam, seeking specialized
                knowledge is highly valued, as the Prophet Muhammad (PBUH) said, “The example of a
                good companion is like a perfume seller. Even if you don’t buy anything, you will still get
                a pleasant scent.” By offering this service, we strive to deliver expert medical insights
                and care to those who need it." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
                button="Donate Now"
                image="/images/creatives/Consultation-Specialist.webp"
                background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat"
            />

            <NewsLetter />
        </div>
    )
}

export default ConsultationSpecialistPage;