import React, { useState } from 'react';
import { Container, Row, Col, Card, Modal, Button } from 'react-bootstrap';
import './VideosGrid.css';

function VideosGrid() {
    const [showModal, setShowModal] = useState(false);
    const [playingVideo, setPlayingVideo] = useState(null);

    // Data with YouTube video URLs
    const cards = [
        {
            title: "12 Rabi ul Awal Youm e Wafat ya Youm e Wiladat? | بارہ ربیع الاول یوم وفات یا یوم ولادت؟",
            image: "/images/creatives/youtube-one.jpg",
            videoUrl: "https://www.youtube.com/embed/5ufpNffkqEA"
        },
        {
            title: "Mehfil e Milad ki Haqeeqat | Reality of Gatherings of Milad | Eid Milad un Nabi",
            image: "/images/creatives/youtube-two.jpg",
            videoUrl: "https://www.youtube.com/embed/VATU2qlhSm8"
        },
        {
            title: "Majid With Mufti | Kia Mah e Safar Manhoos hai ? | Month of Safar | English Subtitles",
            image: "/images/creatives/youtube-three.jpg",
            videoUrl: "https://www.youtube.com/embed/x7PUd4Qv4ZA"
        },
        {
            title: "Things To Know While having Fast || روزے کے ضَروری مَسائِل || Roze ke Masail",
            image: "/images/creatives/youtube-four.jpg",
            videoUrl: "https://www.youtube.com/embed/jnqaTf2YHZM"
        },
        {
            title: "Wo Konsi Sharait Hain Jin Ka Kayal Na Kiya Jae To Zakat Ada Nahi Hogi?",
            image: "/images/creatives/youtube-five.jpg",
            videoUrl: "https://www.youtube.com/embed/1mmxrfhdRVc"
        },
        {
            title: "Bachon Ke Naam Kaise Rakhe? | How to Name Babies? | Part 2 with English Subtitles | WE SHOULD KNOW!",
            image: "/images/creatives/youtube-six.jpg",
            videoUrl: "https://www.youtube.com/embed/3Q13xcYu2fM"
        },
    ];

    const handleVideoClick = (videoUrl) => {
        setPlayingVideo(videoUrl);
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
        setPlayingVideo(null); // Reset video when modal closes
    };

    return (
        <div className='outside'>
            <Container className='video-grid'>
                <Row>
                    {cards.map((card, index) => (
                        <Col sm={12} md={6} lg={4} key={index} className="mb-5">
                            <Card className="custom-card">
                                <div className='video-thumbnail' onClick={() => handleVideoClick(card.videoUrl)}>
                                    <Card.Img variant="top" src={card.image} className="card-thumbnail-image" />
                                    <button className="play-pause-button-videos" onClick={() => handleVideoClick(card.videoUrl)}>
                                        ▶ {/* Show play icon */}
                                    </button>
                                </div>
                                <Card.Body className='card-body'>
                                    <Card.Title className='card-title'>{card.title}</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
            {/* Modal for video playback */}
            <Modal show={showModal} onHide={handleClose} size="lg" centered className="video-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Video Player</Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex justify-content-center align-items-center">
                    {playingVideo && (
                        <iframe
                            title="Video Player"
                            src={`${playingVideo}?autoplay=1`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            className="video-iframe"
                        />
                    )}
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default VideosGrid;
