import "./CardBoxAboutSection.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from "swiper/modules";
import CardBox from "./CardBox";

// const handlePrevButtonClick = () => {
//   const swiperInstance = document.querySelector('#articles-cards-slider').swiper;
//   swiperInstance.slidePrev();
// };

// const handleNextButtonClick = () => {
//   const swiperInstance = document.querySelector('#articles-cards-slider').swiper;
//   swiperInstance.slideNext();
// };

function CardTestimonial({
  subHeading,
  heading,
  content,
  spanHeading
}) {

  const blogData = [
    {
      cardImg: "/images/creatives/Become-a-monthly-donor.svg",
      title: "BECOME A MONTHLY DONOR",
      anchor: "#",
      content:
        "Make a Lasting Impact with Consistent Support—Become a Monthly Donor Today!",
    },
    {
      cardImg: "/images/creatives/corporate-partnership.svg",
      title: "CORPORATE PARTNERSHIPS",
      anchor: "#",
      content:
        "Empower Communities Together — Be a Part with Us for Greater Good!",
    },
    {
      cardImg: "/images/creatives/volunteer-your-time.svg",
      title: "VOLUNTEER YOUR TIME",
      anchor: "#",
      content:
        "Be the Change You Wish to See—Volunteer with Raza Foundation and Make a Difference!",
    },
    // {
    //   cardImg: "./images/creatives/Sponsor_to_Canada.png",
    //   title: "Sponsor to Canada",
    //   anchor:"/spousal-sponsorship-canada",
    //   content:
    //     "Do you miss the comfort of being around your loved ones? Sponsor your family to Canada with our help.",
    // },
    // {
    //   cardImg: "./images/creatives/Invest_In_Canda.png",
    //   title: "Invest In Canada",
    //   anchor:"/business-immigration-in-canada",
    //   content:
    //     "Are you interested in the financial growth and security of Canada? Explore various business and investment opportunities with our business immigration expertise in Canada.",
    // },
    // {
    //   cardImg: "./images/creatives/Not_Sure.png",
    //   title: "Not Sure",
    //   anchor:"/start-your-free-initial-assessment",
    //   content:
    //     "After you finish our assessment, we will determine your eligibility for all immigration programmes.",
    // },
  ];

  return (
    <div className='blog-container standard-padding-space'>
      <div className="blog-upper-content my-4">
        <div className="container">
        <p className="secondary-h1 text-lg-start text-center">{subHeading}</p>
          <div className="row d-flex justify-content-between align-items-center">
            <div className="col-md-6">
              <div className="blog-text-content">
                <h3 className="head-h2 mb-0">{heading}<span className="color-yellow">{spanHeading}</span></h3>
                {content && <p className="body-paragraph">{content}</p>}
              </div>
            </div>

            <div className="col-md-6">
              <p className="para_main">Join Our Mission to Transform Lives—Your Support Matters More Than Ever!</p>
            </div>
          </div>

          <Swiper
            spaceBetween={50}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            loop={true}
            autoplay={
              {
                delay: 1500,
                disableOnInteraction: false
              }
            }
            modules={[Autoplay]}
            pagination={{ clickable: true }}
            observer={true}
            observeParents={true}
            parallax={true}
            breakpoints={{
              280: {
                slidesPerView: 1,
              },
              992: {
                slidesPerView: 3,
              }
            }}
            className="card-list py-2 mt-4 px-3"
            id="articles-cards-slider"
          >
            {blogData.map((blogData, ind) => {
              return (<>
                <SwiperSlide key={ind}>
                  <CardBox cardImg={blogData.cardImg} title={blogData.title} content={blogData.content} anchor={blogData.anchor} />
                </SwiperSlide>
              </>)
            })}
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default CardTestimonial