import axios from 'axios';
import CommonCalls from '../../components/CommonAPICalls/CommonCalls';

export const listServices = () => async (dispatch) => {
    try {
        dispatch({ type: "SERVICE_LIST_REQUEST" });
        const apiCalls = new CommonCalls();
        const data = await apiCalls.getServicesItems();
        console.log(data);

        dispatch({
            type: "SERVICE_LIST_SUCCESS",
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: "SERVICE_LIST_FAIL",
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
};
