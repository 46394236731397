import React from 'react'
import "./HowAskAAlim.css"

const cardData = [
    {
        img:"/images/icons/how-ask-alim-1.png",
        title:"10 Muftis and advisors",
        para:"Our Alims offer advice with kindness, empathy, and a deep understanding of the challenges you may face."
    },
    {
        img:"/images/icons/how-ask-alim-2.png",
        title:"All questions are welcome",
        para:"We provide responses grounded in the Qur’an and Sunnah, ensuring your questions are answered with knowledge and wisdom."
    },
    {
        img:"/images/icons/how-ask-alim-3.png",
        title:"24/7 support",
        para:"By using our service, you become part of a supportive community that cares about your spiritual growth."
    },
]

function HowAskAAlim() {
  return (
    <div className='HowAskAAlim-container py-4'>
        <div className="container py-3">
            <h3 className='main-heading text-center body-heading'>How Ask an Alim helps?</h3>
       
            <div className='row mb-4 mt-3'>
                {
                    cardData.map((ele)=>{
                        return(
                            <div className='col-lg-4 text-center px-3'>
                                <img src={ele.img} alt='card icon' className='img-fluid' />
                                <h4 className='my-3'>{ele.title}</h4>
                                <p className='para_main'>{ele.para}</p>
                            </div>
                        )
                    })
                }

            </div> 
        </div>
    </div>
  )
}

export default HowAskAAlim