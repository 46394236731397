import React, { useState, useEffect } from 'react';
import CommonCalls from '../components/CommonAPICalls/CommonCalls';
import Loader from "../components/UI Helpers/Simple Loading/Loader";
import CustomDataGrid from '../components/UI Helpers/DataGrid/CustomDataGrid';
import { gridLinkValueFormatter } from '../components/MasterComponents/CommonFunctionalities';
import Settings from '../components/CommonAPICalls/Settings';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PendingBlogsGridPage = () => {
    const [pendingBlogs, setPendingBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [adminMessage, setAdminMessage] = useState("This action has been recorded to the admin, please wait until his approval");

    const apiCall = new CommonCalls();
    const navigate = useNavigate();

    // Get user data from Redux store
    const userInfo = useSelector(state => state.userLogin.userInfo);

    useEffect(() => {
        console.log("User Info from Redux:", userInfo); // Debugging log
        fetchPendingBlogs();
    }, [userInfo]);

    const fetchPendingBlogs = async () => {
        setLoading(true);

        try {
            const response = await apiCall.getPendingBlogs();
            if (response && Array.isArray(response)) {
                setPendingBlogs(response);
                setTotalRecords(response?.total_records || response.length);

                console.log("API Response:", response); // Debugging log

                // Ensure the message is always set for sub-admin users
                if (!userInfo?.is_admin && (userInfo?.modules_access && userInfo?.modules_access.length > 0)) {
                    setAdminMessage(response[0].admin_message || adminMessage);
                }
            } else {
                console.error('Unexpected API response format:', response);
            }
        } catch (error) {
            console.error('Error fetching pending blogs:', error);
        } finally {
            setLoading(false);
        }
    };

    const onClickCheck = async (row, field, value, referenceValue) => {
        if (value) {
            try {
                const success = await apiCall.updateBlogItem(row?.blog_id, {
                    status: referenceValue,
                });

                if (success) {
                    setPendingBlogs((prevBlogs) => prevBlogs.filter(blog => blog.blog_id !== row.blog_id));
                } else {
                    console.error('Failed to update blog status.');
                }
            } catch (error) {
                console.error('Error updating blog status:', error);
            }
        }
    };

    const onBlogClick = (row, key) => {
        if (key === 'blog_content') {
            navigate(`/portal/blog-editor?blogid=${row?.blog_id}`);
        }
    };

    const gridColumnHeaders = [
        { field: 'blog_content', cellRenderer: gridLinkValueFormatter, customRendererLink: true, columnName: 'Content' },
        { field: 'blog_slug_url', columnName: 'Slug URL' },
        { field: 'status', columnName: 'Approved', customRendererLink: false, isCheckBox: true, referenceValue: Settings.enumTypesForStatus[0].value },
        { field: 'status', columnName: 'Rejected', customRendererLink: false, isCheckBox: true, referenceValue: Settings.enumTypesForStatus[2].value },
    ];

    return (
        <div className='listings_view_wrapper' style={{ padding: "20px" }}>
            <h1 className="h1_main mb-2">All Pending Blogs</h1>

            {/* Always show the admin message for sub-admin users */}
            {adminMessage && !userInfo?.is_admin && userInfo?.modules_access && userInfo?.modules_access.length > 0 && (
                <h2 className='head-h2' style={{ backgroundColor: "#A5CD37", padding: "25px 30px", fontSize: "19px", borderRadius: "10px" }}>
                    {adminMessage}
                </h2>
            )}

            {loading ? (
                <Loader />
            ) : (
                <CustomDataGrid
                    data={pendingBlogs}
                    totalRecords={totalRecords}
                    loadingState={loading}
                    columns={gridColumnHeaders}
                    handleCheckboxChange={onClickCheck}
                    currentPage={pageNumber}
                    onPageChange={setPageNumber}
                    onPageSizeChange={setPageSize}
                    onCellClicked={onBlogClick}
                    hideAddBtn={true}
                    hideDeleteBtn={true}
                />
            )}
        </div>
    );
};

export default PendingBlogsGridPage;
