import React from 'react'
import CommonHeroComponent from '../CommonHeroComponent/CommonHeroComponent'

const PrivacyPolicy = () => {
    return (
        <div>
            <CommonHeroComponent title="Raza Foundation" Heading="PRIVACY POLICY PAGE" Para="Last Updated: 12th August 2024" backgroundImg="/images/creatives/about-banner.jpg" />

            <div className="privacy-content">
                <div className='container'>
                    <h2>Introduction</h2>
                    <p>
                        Welcome to Raza Foundation. We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your data when you visit our website or engage with our services. Please read this policy carefully to understand our practices regarding your personal information.
                    </p>

                    <h3>Information We Collect</h3>
                    <ul>
                        <li><strong>Personal Information:</strong></li>
                        <ul>
                            <li>Name</li>
                            <li>Contact information (email address, phone number, mailing address)</li>
                            <li>Payment details (for donations)</li>
                        </ul>
                        <li><strong>Non-Personal Information:</strong></li>
                        <ul>
                            <li>Browser type and version</li>
                            <li>Operating system</li>
                            <li>IP address</li>
                            <li>Referring website</li>
                            <li>Browsing behavior on our website</li>
                        </ul>
                    </ul>

                    <h3>How We Use Your Information</h3>
                    <ul>
                        <li><strong>To Provide and Improve Our Services:</strong></li>
                        <ul>
                            <li>Processing donations and issuing receipts</li>
                            <li>Responding to inquiries and providing customer support</li>
                            <li>Personalizing your experience on our website</li>
                        </ul>
                        <li><strong>To Communicate:</strong></li>
                        <ul>
                            <li>Sending newsletters, updates, and promotional materials</li>
                            <li>Informing you about upcoming events and initiatives</li>
                        </ul>
                        <li><strong>To Ensure Security:</strong></li>
                        <ul>
                            <li>Protecting our website from fraudulent activities</li>
                            <li>Conducting audits and monitoring for security breaches</li>
                        </ul>
                    </ul>

                    <h3>Disclosure of Your Information</h3>
                    <p>We may share your information with third parties only in the following circumstances:</p>
                    <ul>
                        <li><strong>Service Providers:</strong></li>
                        <ul>
                            <li>To companies that assist in processing donations and providing IT services.</li>
                        </ul>
                        <li><strong>Legal Obligations:</strong></li>
                        <ul>
                            <li>When required by law or to protect our legal rights.</li>
                        </ul>
                        <li><strong>With Your Consent:</strong></li>
                        <ul>
                            <li>When you have given us explicit permission to share your information.</li>
                        </ul>
                    </ul>

                    <h3>Data Security</h3>
                    <p>
                        We implement industry-standard security measures to protect your personal information. This includes encryption, secure servers, and restricted access to data. Despite these measures, we cannot guarantee absolute security due to the inherent risks of data transmission over the internet.
                    </p>

                    <h3>Your Rights</h3>
                    <p>As a user, you have the right to:</p>
                    <ul>
                        <li>Access and review your personal information</li>
                        <li>Request corrections to inaccurate data</li>
                        <li>Opt-out of receiving marketing communications</li>
                        <li>Request deletion of your personal information, subject to legal and contractual obligations</li>
                    </ul>

                    <h3>Third-Party Links</h3>
                    <p>
                        Our website may contain links to third-party websites. We are not responsible for the privacy practices of these websites. We encourage you to review the privacy policies of any third-party sites you visit.
                    </p>

                    <h3>Children’s Privacy</h3>
                    <p>
                        Raza Foundation does not knowingly collect personal information from children under the age of 13. If we become aware that we have inadvertently received personal data from a child under 13, we will take steps to delete the information as soon as possible.
                    </p>

                    <h3>Changes to This Privacy Policy</h3>
                    <p>
                        We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this policy periodically to stay informed about how we are protecting your information.
                    </p>

                    <h3>Contact Us</h3>
                    <p>
                        If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
                    </p>
                    <p>
                        Raza Foundation<br />
                        Email: <a href="mailto:razafoundation@gmail.com">razafoundation@gmail.com</a><br />
                        Phone: 905 624 6929<br />
                        Mailing Address: 8 Strathearn Avenue, Gulshan-e-Iqbal, Karachi.
                    </p>
                    <p>
                        Thank you for trusting Raza Foundation with your personal information. Your privacy and security are of utmost importance to us.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;