import React from 'react';
import './HomeGallery.css';
import { Link } from 'react-router-dom';
import Gallery from '../ProjectGallery/Gallery'

const HomeGallery = ({ homeSection = false }) => {
  return (
    <div className='HomeGallery-container standard-padding-space'>
      <div className="blog-upper-content">
        <div className="container">
          <div className="row d-flex justify-content-between align-items-end">
            <div className="col-md-6">
              <div className="blog-text-content d-none d-lg-block">
                <p className="secondary-h1">Donations</p>
                <h3 className="head-h1 mb-0">Your Generosity, Their Hope</h3>
                {/* {content && <p className="body-paragraph">{content}</p>} */}

              </div>
            </div>

            <div className="col-md-6 services-content-class">
              <div className="blog-text-content d-lg-none d-md-block">
                <p className="secondary-h1">Donations</p>
                <h3 className="head-h1 mb-0">Your Generosity, Their Hope</h3>
                {/* {content && <p className="body-paragraph">{content}</p>} */}

              </div>
              <p className="para_main">Every penny you donate changes a life. Partner with us to provide essential services and support to those in need.</p>

              <Link to="/donations">
                <button className="connect-button mt-3">Donate Now</button>
              </Link>
            </div>
          </div>
        </div>
        <Gallery homeSection={homeSection} hideTransform={true} />
      </div>
    </div>
  );
};

export default HomeGallery