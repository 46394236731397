import React from 'react'
import VisionAndMission from '../components/VisionAndMission/VisionAndMission'
import NewsLetter from '../components/NewsLetter/NewsLetter';
import ChildHomeHero from '../components/ChildHomeHero/ChildHomeHero';

const GuardianPage = () => {
    return (
        <div>
            {/* <VisionAndMission title="Introduction" bluespan="Guardian" Para="Guardian is a dedicated program by the Raza Foundation designed to safeguard and
uplift vulnerable groups, including orphans, widows, and those living in extreme poverty.
The program is inspired by the Islamic principle of caring for those in need, as
emphasized in the Quran: And they give food in spite of love for it to the needy, the
orphan, and the captive (Surah Al-Insan, 76:8). Through Guardian, we provide
essential support, including shelter, education, " bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
                button="Donate Now"
                image="/images/creatives/guardian.webp"
                paddingImg="6vh 2vh"
                background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat"
                padding="6vh 1vh 8vh 1vh"
            /> */}

            <ChildHomeHero
                title="Introduction" bluespan="Guardian" Para="Guardian is a dedicated program by the Raza Foundation designed to safeguard and
            uplift vulnerable groups, including orphans, widows, and those living in extreme poverty.
            The program is inspired by the Islamic principle of caring for those in need, as
            emphasized in the Quran: And they give food in spite of love for it to the needy, the
            orphan, and the captive (Surah Al-Insan, 76:8). Through Guardian, we provide
            essential support, including shelter, education, " bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
                button="Donate Now"
                image="/images/creatives/guardian.webp"
                background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" />

            <NewsLetter />
        </div>
    )
}

export default GuardianPage;