import React from 'react';
import CommonHeroComponent from '../components/CommonHeroComponent/CommonHeroComponent';
import VideosGrid from '../components/VideoGridFolder/VideosGrid.js';

const VideosPage = () => {
    return (
        <div>
            <CommonHeroComponent
                Heading="VIDEOS"
                Para="Join us in our mission of serving humanity, providing essential services and support to those in need."
                backgroundImg="/images/creatives/video-banner.jpg" />

            {/* <VideoGrid /> */}
            <VideosGrid />
        </div>
    )
}

export default VideosPage