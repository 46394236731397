import React from 'react'
import CommonHeroComponent from '../components/CommonHeroComponent/CommonHeroComponent'
import NewsLetter from '../components/NewsLetter/NewsLetter'
import FeedbackPage from './FeedbackPage'
import HomeGallery from '../components/HomeGallerySection/HomeGallery'
import PlainCenteredText from '../components/PlainCenteredText/PlainCenteredText'
import AskAMuftiWorking from '../components/AskAMuftiWorking/AskAMuftiWorking'
import HowAskAAlim from '../components/HowAskAAlim/HowAskAAlim'
import AskAMuftiForm from '../components/AskAMuftiForm/AskAMuftiForm'

function AskAMufti() {
  return (
    <div>
      <CommonHeroComponent
        Heading="Ask a Mufti"
        SubPara="Seek authentic Islamic guidance by asking your questions."
        Para="Our team of 10 qualified scholars, including Muftis from the esteemed British Fatwa Council, is here to provide you with well-researched, timely, and confidential answers. Whether you need clarity on Islamic teachings, daily life matters, or religious rulings, we are here to help."
        backgroundImg="/images/creatives/askamufti-banner.png"
        textWhite={true}
      />

      <PlainCenteredText
        heading="Your Support Can Light the Path for Many"
        para="Help us continue providing authentic Islamic guidance to those seeking answers and spiritual growth. Your generosity enables us to serve the community with knowledge, clarity, and wisdom."
        btn={true}
        btnText="Donate Now"
        btnRef="/donations"
      />

        <AskAMuftiWorking/>

        <HowAskAAlim/>

        <AskAMuftiForm/>

      <HomeGallery homeSection={true} />
      <NewsLetter />
      <FeedbackPage />
      <footerHero />
    </div>
  );
}

export default AskAMufti