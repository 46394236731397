import React from 'react'
import ChildHomeHero from '../components/ChildHomeHero/ChildHomeHero';
import NewsLetter from '../components/NewsLetter/NewsLetter';

const ChildrenOrientationPage = () => {
    return (
        <div>
            <ChildHomeHero
                title="Introduction" bluespan="Rf COS (Children Orientation System)" Para="The Rf COS (Children Orientation System) is dedicated to nurturing children’s
                education and moral development through a blend of Islamic teachings and modern
                academic knowledge. This program ensures that children grow up with a clear
                understanding of their faith, while also excelling in their worldly education. In Islam, the
                Prophet Muhammad (PBUH) emphasized the importance of seeking knowledge, saying,
                “Seeking knowledge is an obligation upon every Muslim.” Through Rf COS, we aim to
                fulfill this obligation, guiding children on the path of success in both worlds.
                " bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
                button="Donate Now"
                image="/images/creatives/Children-Orientation-System.webp"
                background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" />

            <NewsLetter />
        </div>
    )
}

export default ChildrenOrientationPage;