// FeedbackForm.js
import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import './FormfeedBack.css'

const FeedbackForm = (props) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [rating, setRating] = useState(5);
    const [comments, setComments] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const feedbackData = {
            name,
            email,
            rating,
            comments
        };

        try {
            const response = await fetch('https://your-backend-api.com/feedback', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(feedbackData)
            });

            const data = await response.json();
            console.log('Feedback Submitted:', data);
        } catch (error) {
            console.error('Error submitting feedback:', error);
        }

        // Clear form
        setName('');
        setEmail('');
        setRating(5);
        setComments('');
    };

    return (
        <div className='form-container' style={{ background: props.background, padding: props.padding }}>
            <Container className="container" >
                <Row className="justify-content-center">
                    <Col md={8}>
                        <h2 className="text-center head-h1">Give Us Your Feedback</h2>
                        <Form onSubmit={handleSubmit}>
                            {/* Name Field */}
                            <Form.Group controlId="formName" className="mb-3">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                    style={{borderRadius: "32px"}}
                                />
                            </Form.Group>

                            {/* Email Field */}
                            <Form.Group controlId="formEmail" className="mb-3">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    style={{borderRadius: "32px"}}
                                />
                            </Form.Group>

                            {/* Rating Field */}
                            <Form.Group controlId="formRating" className="mb-3">
                                <Form.Label>Rating</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={rating}
                                    onChange={(e) => setRating(e.target.value)}
                                    style={{borderRadius: "32px"}}
                                >
                                    <option value="5">5 - Excellent</option>
                                    <option value="4">4 - Good</option>
                                    <option value="3">3 - Average</option>
                                    <option value="2">2 - Poor</option>
                                    <option value="1">1 - Terrible</option>
                                </Form.Control>
                            </Form.Group>

                            {/* Comments Field */}
                            <Form.Group controlId="formComments" className="mb-3">
                                <Form.Label>Comments</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Write your comments here"
                                    value={comments}
                                    onChange={(e) => setComments(e.target.value)}
                                    required
                                    style={{borderRadius: "32px"}}
                                />
                            </Form.Group>

                            {/* Submit Button */}
                            <button type="submit" className="w-100 connect-button">
                                Submit Feedback
                            </button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default FeedbackForm;
