import React from 'react';
import './ScheduleEvents.css';

const ScheduleEvents = () => {
    return (
        <div className="schedule-events">
            <div className="container">
                <div className="heading-content">
                    <h1 className="head-h1">Raza Foundation</h1>
                    <p className="para-main">Charity 128 recognizes the importance of collective action in creating lasting change. We invite individuals, corporations, and communities to join us in our mission.</p>
                </div>

                <div className="events-list">
                    {/* First row */}
                    <div className="event-category">
                        <div className="connect-button">RF WELFARE</div>
                        <hr className="divider" />
                        <div className="event-items">
                            <div className="event-item">Financial Aid</div>
                            <div className="event-item">Plantation</div>
                            <div className="event-item">Masjid and Madrasa Cons.</div>
                        </div>
                    </div>

                    {/* Second row */}
                    <div className="event-category">
                        <div className="connect-button">RF DISASTER AID</div>
                        <hr className="divider" />
                        <div className="event-items">
                            <div className="event-item">Corona Relief</div>
                            <div className="event-item">Thar Parker</div>
                            <div className="event-item">Balochistan</div>
                        </div>
                    </div>

                    {/* Third row */}
                    <div className="event-category">
                        <div className="connect-button">RF FOOD</div>
                        <hr className="divider" />
                        <div className="event-items">
                            <div className="event-item">Dastarkhan Thar Parker</div>
                            <div className="event-item">Rashan Distribute</div>
                            <div className="event-item">Family Support</div>
                        </div>
                    </div>

                    {/* Fourth row */}
                    <div className="event-category">
                        <div className="connect-button">RF EDUCATION</div>
                        <hr className="divider" />
                        <div className="event-items">
                            <div className="event-item">COS (Children Orientation System)</div>
                            <div className="event-item">Islamic History</div>
                            <div className="event-item">Educated in Quran</div>
                        </div>
                    </div>

                    {/* Fifth row */}
                    <div className="event-category">
                        <div className="connect-button">RF HEALTH</div>
                        <hr className="divider" />
                        <div className="event-items">
                            <div className="event-item">Medicine</div>
                            <div className="event-item">OPD</div>
                            <div className="event-item">Consultations Physician</div>
                            <div className="event-item">Consultation Specialist</div>
                        </div>
                    </div>

                    {/* Sixth row */}
                    <div className="event-category">
                        <div className="connect-button">RF EHSAAS</div>
                        <hr className="divider" />
                        <div className="event-items">
                            <div className="event-item">Khana Delhi's (Food on Door Step)</div>
                            <div className="event-item">Langar Khana - Thar Parker</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ScheduleEvents;
