import React from 'react';
import Checkbox from './Checkbox';
import DropDownInput from './DropDownInput';
import InputDatePicker from './InputDatePicker';
import Input from './Input';
import MultiSelect from './MultiSelect';

//Component For Mapping Field Input Boxes.

// ** Props 
{/* 
        mainClassName,
        className, 
        mainTitle,
        required,
        inputClassName,
        disable,
        type,
        inputType,
        label,
        isChecked,
        value,
        gridObjectFunction,
        id 
    */}
// ----

// let textBoxInputSettings = [ 
//     {label:Helper.getLabel(props.language,'',""), className: '', inputType: "text/number/checkbox/datepicker/dropdown", name:'', id:'', }
// ]



const FieldsRenderer = ({ fieldsArray, updateState, currentState }) => {

    const amountPerPortion = 25000;
    const onInputCheckboxChangeHandler = (e) => {
        const { id, checked } = e.target;
        updateState((prevState) => {
            return {
                ...prevState,
                [id]: checked
            }
        })
    }







    const onInputDatePickerChangeHandler = (name, value) => {
        updateState((prevState) => {
            return {
                ...prevState,
                [name]: value
            }
        })
    }

    const onInputChangeHandler = (e) => {
        const { name, value } = e.target;
        updateState((prevState) => {
            return {
                ...prevState,
                [name]: value
            }
        })
    }
    const handleSelectionChange = (selectedValues, name) => {
        updateState((prevState) => {
            return {
                ...prevState,
                [name]: selectedValues
            }
        })
    };

    const incrementPortionCount = () => {
        updateState(prevState => ({
            ...prevState,
            portions: prevState.portions + 1,
            donation_amount: (prevState.portions + 1) * amountPerPortion
        }));
    };

    const decrementPortionCount = () => {
        updateState(prevState => ({
            ...prevState,
            portions: prevState.portions > 1 ? prevState.portions - 1 : 1,
            donation_amount: (prevState.portions > 1 ? prevState.portions - 1 : 1) * amountPerPortion
        }));
    };

    return (
        <div className='container-fluid m-0 p-0'>
            <div className='row Input-fileds'>
                {fieldsArray.map((element, index) => {
                    return (
                        <div className={element.className ? element.className : 'col-lg-6'} key={index}>
                            {element.inputType == "text" &&
                                <Input label={element.label} type='text' id={element.id ? element.id : null} required={element.required ? element.required : false} disabled={element.disable ? element.disable : false} name={element.name} onChange={onInputChangeHandler} value={currentState[element.name]} placeholder={element.placeholder} />
                            }
                            {element.inputType == "number" &&
                                <Input maxValue={element?.maxValue} label={element.label} type='number' id={element.id ? element.id : null} required={element.required ? element.required : false} disabled={element.disable ? element.disable : false} name={element.name} onChange={onInputChangeHandler} value={currentState[element.name] + ''} placeholder={element.placeholder} />
                            }
                            {element.inputType == "dropdown" &&
                                <DropDownInput id={element.id ? element.id : null} required={element.required ? element.required : false} disabled={element.disable ? element.disable : false} label={element.label} options={element.options ? element.options : []} value={currentState[element.name]} defaultSelected={currentState[element.name]} name={element.name} onSelect={onInputChangeHandler} />
                            }
                            {element.inputType == "multi_dropdown" &&
                                <MultiSelect options={element?.options} defaultValues={element.defaultValues} onChange={handleSelectionChange} name={element.name} Label={element.label} />
                            }
                            {element.inputType == "checkbox" &&
                                <Checkbox id={element.id ? element.id : null} type={element.inputType} name={element.name} onChange={onInputCheckboxChangeHandler} disableWithoutIcon={element.disable ? element.disable : false} label={element.label} isChecked={currentState[element.name]} />
                            }
                            {element.inputType == "datepicker" &&
                                <InputDatePicker empty={element.emptyDate} timePicker={element.timePicker} id={element.id ? element.id : null} disable={element.disable ? element.disable : false} value={currentState[element.name]} label={element.label} name={element.name} required={element.required ? element.required : false} onChange={onInputDatePickerChangeHandler} />
                            }
                            {element.inputType == "textarea" &&
                                <div className='textarea_default input-container'>
                                    <label className='mb-2'>{element.label ? element.label : null}{element?.required && <span style={{ color: 'red' }}>*</span>}</label>
                                    <textarea rows={6} id={element.id ? element.id : null} disable={element.disable ? element.disable : false} value={currentState[element.name]} name={element.name} inputClassName={element.inputClassName} required={element.required ? element.required : false} onChange={onInputChangeHandler} />
                                </div>
                            }
                            {element.inputType == "password" &&
                                <Input inputType='password_eye' id={element.id ? element.id : null} required={element.required ? element.required : false} disable={element.disable ? element.disable : false} name={element.name} label={element.label} onChange={onInputChangeHandler} value={currentState[element.name]} />
                            }
                            {element.inputType === 'portions' && (
                                <div className="form-row">
                                    <div className="form-group">
                                        <label className='form-text'>Select Portions</label>
                                        <div className="btn-group">
                                            <button type="button" className="connect-button d-flex align-items-center" onClick={decrementPortionCount}>-</button>
                                            <p className='head-h2 align-items-center'>{currentState.portions}</p>
                                            <button type="button" className="connect-button d-flex align-items-center" onClick={incrementPortionCount}>+</button>

                                            <h2 className='form-rs-price'>Rs. {currentState.donation_amount.toLocaleString()}</h2>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {element.inputType == "sacrifice" &&
                                <div key={element.name} className='under-form-container'>
                                    <div className="add-name">
                                        <p className='para_main'>Names for Sacrifice Shares</p>
                                    </div>
                                    <div className='button-container-donation'>
                                        <button className="donation-form-btn">Add</button>
                                        {/* You might want to add functionality for managing names here */}
                                    </div>
                                </div>
                            }
                        </div>
                    )
                })}
            </div>
        </div>
    )
};

export default FieldsRenderer;