import React from 'react'
import NewsAndUpdatesHero from '../components/NewsAndUpdatesHero/NewsAndUpdatesHero'
import VisionAndMission from '../components/VisionAndMission/VisionAndMission'
import NewsLetter from '../components/NewsLetter/NewsLetter'
import HappyCustomer from '../components/HappyCustomers/HappyCustomer'
import CommonHeroComponent from '../components/CommonHeroComponent/CommonHeroComponent'
import CardTestimonial from '../components/OurServices/CardBoxServicesSection'
import Homehero from '../components/HomeHero/HomeHero'
import HomeGallery from '../components/HomeGallerySection/HomeGallery'
import EventsSlider from '../components/EventsSlider/EventsSlider'
import VideoSlider from '../components/VideoSlider/VideoSlider'
import StaticHeroBanner from '../components/StaticHeroBanner/StaticHeroBanner'
import QuickDonate from '../components/QuickDonate/QuickDonate'
import OtherProject from '../components/OtherProjects/Projects'
import footerHero from "../components/Footer/footerHero"
import FeedbackPage from './FeedbackPage'
import NoticeHeader from '../components/NoticeHeader/NoticeHeader'

const HomePage = () => {
  return (
    <div>
      <StaticHeroBanner />
      {/* <NoticeHeader /> */}
      <QuickDonate />
      <Homehero />

      <VisionAndMission title="RAZA FOUNDATION" heading="Guided by Compassion, Driven by Purpose" Para="Our mission is to uplift and empower communities through generosity and goodwill. Join us in creating a brighter, more hopeful world for everyone." homeTitle="" button="About Us" image="/images/creatives/about-banner.png"
        background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat"
      />

      <CardTestimonial subHeading="OUR SERVICES"
        heading="Making a Difference with Every Contribution"
        spanHeading=""
        button="Learn more" />
      <OtherProject />
      <HappyCustomer />
      <EventsSlider />
      <VideoSlider />
      <HomeGallery homeSection={true} />
      <NewsLetter />
      <FeedbackPage />
      <footerHero />
    </div>
  )
}

export default HomePage