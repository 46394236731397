import React from 'react';
import './TeamMembers.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from "swiper/modules";

const teamMembers = [
  { name: 'Muhammad Yousuf Ansari', title: 'President', imgSrc: '/images/creatives/client-male.webp' },
  { name: 'Muhammad Majid Khan', title: 'Vice President', imgSrc: '/images/creatives/client-male.webp' },
  { name: 'Khawaja Syed Wajeeh Uddin Ahmed', title: 'General Secretary', imgSrc: '/images/creatives/client-male.webp' },
  { name: 'Muhammad Mohsin Khan', title: 'Joint Secretary', imgSrc: '/images/creatives/client-male.webp' },
  // { name: 'Syed Fahad Quttub', title: 'Member', imgSrc: '/images/creatives/client-male.webp' },
  { name: 'Furqan Rashid', title: ' Finance Secretary ', imgSrc: '/images/creatives/client-male.webp' },
  { name: 'Syed Fahad Quttub', title: ' Member ', imgSrc: '/images/creatives/client-male.webp' },
  { name: 'Farhan Elahi', title: 'Member', imgSrc: '/images/creatives/client-male.webp' },
  { name: 'Muhammad Adnan Qadri', title: 'Member', imgSrc: '/images/creatives/client-male.webp' },
  { name: 'Muhammad Asif Iqbal ', title: 'Member', imgSrc: '/images/creatives/client-male.webp' },
  { name: 'Muhammad Mudassir  ', title: 'Member', imgSrc: '/images/creatives/client-male.webp' },
];

const TeamMembers = () => {
  return (
    <div className='maindiv'>
      <div className="container text-center team-container">
        <h2 className="secondary-h1">Raza Foundation</h2>
        <h3 className="head-h1">Our Team</h3>
        <p className='body_paragraph'>Meet the Dedicated Individuals Driving Change and Making a Difference!</p>
        <div className="team-member-list">
          <Swiper
            spaceBetween={30}
            slidesPerView={1}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 40,
              },
            }}
            // pagination={{ clickable: true }}
            autoplay={{ delay: 1500, disableOnInteraction: false }}
            loop={true}
            modules={[Autoplay]}
            className="testimonials-list"
            id="articles-cards-slider"
          >
            {teamMembers.map((member, index) => (
              <SwiperSlide key={index}>
                <div className="card border-0 justify-content-center align-items-center">
                  <img src={member.imgSrc} className="card-img-top" alt={member.name} />
                  <div className="card-body">
                    <h5 className="card-title">{member.name}</h5>
                    <p className="card-text">{member.title}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default TeamMembers;
