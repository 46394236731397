import React from 'react'
import ChildHomeHero from '../components/ChildHomeHero/ChildHomeHero'
import NewsLetter from '../components/NewsLetter/NewsLetter'

const TharParkarPage = () => {
    return (
        <div>
         

                <ChildHomeHero 
                title="Introduction" bluespan="Thar Parkar" Para="The Thar Parker initiative focuses on uplifting the lives of the people in the Thar Desert,
                one of the most underdeveloped regions in Pakistan. With extreme drought conditions
                and a lack of access to clean water and basic health services, the residents face
                immense challenges daily. Islam teaches us the importance of supporting those in
                need. The Prophet Muhammad (PBUH) said: The best charity is to give water to drink.
                By supporting this initiative, we provide vital resources such as clean water, food, and
                healthcare to the people of Thar Parker." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
                                button="Donate Now"
                                image="/images/creatives/Thar-Parkar-Aid.webp"
                                background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat"
                                />

                                <NewsLetter />
        </div>
    )
}

export default TharParkarPage