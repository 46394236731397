import React from 'react'
import CommonHeroComponent from '../components/CommonHeroComponent/CommonHeroComponent'
import VisionAndMission from '../components/VisionAndMission/VisionAndMission'

const SadqaPage = () => {
  return (
    <div>
      <CommonHeroComponent
        Heading="COS"
        Para="Our COS programs focus on reaching out to communities, understanding their needs, and providing personalized support and services."
        backgroundImg="/images/creatives/COS-banner-image.jpg"
      />

      <VisionAndMission heading="About" bluespan=" Community Outreach Services (COS)" Para="Community Outreach Services (COS) aim to address the immediate needs of local communities through various programs and initiatives. Raza Foundation's COS programs include food distribution, health clinics, and educational support, all rooted in Islamic principles of service and compassion." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
        image="/images/creatives/COS-one.png"
        button="Donate Now"
        background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" />

      <VisionAndMission flexdirection="row-reverse" heading="How Your COS" bluespan=" Donation Adds Value" Para="Supporting COS through Raza Foundation helps address critical needs within the community. Your donations fund essential services that improve the quality of life for many. This act of charity reflects the Islamic value of helping those in your community, fostering a sense of solidarity and support." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
        image="/images/creatives/COS-two.png"
        button="Donate Now" />

      <VisionAndMission heading="Ease of Donating for" bluespan=" COS and Its Impact" Para="Donating to COS is made easy with Raza Foundation's efficient process. Your contributions are swiftly utilized to provide vital services, making an immediate and lasting impact. By supporting COS, you help uplift your community and enrich your own spiritual life, embodying the essence of Islamic service." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
        image="/images/creatives/COS-three.png"
        button="Donate Now" />
    </div>
  );
};

export default SadqaPage;