import React from 'react';
import CommonHeroComponent from '../components/CommonHeroComponent/CommonHeroComponent'
import VisionAndMission from '../components/VisionAndMission/VisionAndMission';
import TeamMembers from '../components/TeamMembers/teamMembers';
import CardBoxAboutSection from '../components/CardsForAboutSection/CardBoxAboutSection';
import NewsLetter from '../components/NewsLetter/NewsLetter';
import ScheduleEvents from '../components/ScheduleEventsFolder/ScheduleEvents';

const AboutPage = () => {
  return (
    <div>
      <CommonHeroComponent title="Raza Foundation" Heading="ABOUT US" Para="Discover the Heart and Soul Behind Raza Foundation—Where Compassion Meets Action!" backgroundImg="/images/creatives/about-banner.jpg" />

      <VisionAndMission aboutTitle="RAZA FOUNDATION" heading="Guided by Compassion, Driven by Purpose" Para="Our mission is to uplift and empower communities through generosity and goodwill. Join us in creating a brighter, more hopeful world for everyone."
        image="/images/creatives/about-banner.png" />

      <TeamMembers />

      <CardBoxAboutSection subHeading="Raza Foundation"
        heading="How You Can Contribute" />

      <ScheduleEvents />

      <NewsLetter />
    </div>
  );
};

export default AboutPage;