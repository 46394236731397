import React from 'react';
import CommonHeroComponent from '../components/CommonHeroComponent/CommonHeroComponent';
import BlogInner from '../components/BlogInner/BlogInner';
import Articles from '../components/NewsAndArticles/Articles';

const GoogleAds = () => {
  return (
    <div>
        <CommonHeroComponent adsTitle="BY KELLY ALLEN | PUBLISHED: JUN 4, 2024" adsHeading="Gwyneth Paltrow Currently Owns All of These Incredible Homes" 
        backgroundImg="/images/creatives/googleAds-banner.jpg"/>

<BlogInner />
<Articles subHeading="OUR BLOGS"
          heading="Latest News & Articles"
          spanHeading=""
          button="All articles"/>
    </div>
  )
}

export default GoogleAds