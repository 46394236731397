import React from 'react';
import RefundPolicy from '../components/RefundPolicy/RefundPolicy';

const RefundPolicyPage = () => {
    return (
        <div>
            <RefundPolicy />
        </div>
    );
};

export default RefundPolicyPage;