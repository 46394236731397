// CardBox.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import './CardBox.css';

function CardBox(props) {
  const navigate = useNavigate();

  return (
    <div className="services-card-box">
      <img src={props.cardImg} alt={props.title} />
      <div className="card-body">
        <h2 className='head-h2'>{props.title}</h2>
        <div className="card-content">
          <p className='para_main mb-2'>{props.content}</p>
          {/* <button>{props.button}</button> */}
        </div>

        {props.buttons && (
  <button onClick={() => navigate(props.anchor)} className="button-secondary">
    {props.buttons}
  </button>
)}
        {/* <button onClick={() => navigate(props.donateBtn)} className="button-secondary">
          Donate Now
        </button> */}
      </div>
    </div>
  );
}

export default CardBox;
