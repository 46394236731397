import React from 'react';
import './BlogInner.css';
// Import any other components if needed

const BlogInner = ({ blogTitle, blogContent }) => {
  return (
    <div className="container blog-inner-container">
      <div className="row">
        <div className="col-lg-8 col-md-12">
          <div className="content">
            {/* Display the blog title */}
            <h1 className='h1_main'>{blogTitle}</h1>

            {/* Display the blog content */}
            <div className='para_main' dangerouslySetInnerHTML={{ __html: blogContent }}></div>

            {/* Additional content and images can be added here */}
            <div className='d-lg-none d-block'>
              {/* Add any responsive images or content here */}
            </div>
            <br />
          </div>
        </div>
        <div className="col-lg-4 col-md-12">
          <div className="d-flex flex-column justify-content-between ads-container">
            {/* Add any additional ads or content here */}
            <div className='d-lg-block d-none'>
              {/* Add any responsive ads or content here */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogInner;
