import React from 'react';
import { Link } from 'react-router-dom';
import './EventsNews.css';

const EventsNews = (props) => {
    return (
        <div className='events-container'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 d-lg-block d-none">
                        <img src="/images/creatives/mehfil-one.png" alt="Haircut" className="img-responsive" />
                    </div>
                    <div className="col-lg-6 under-container">
                        <div className='top-text-btn'>
                            <h4 className='h2_main'>Upcoming Event</h4>
                            <button className='date-button'>Tue,Aug 27 2024</button>
                        </div>

                        <h2 className='h1_main'>Mr. Zain Jeewanjee visited Raza Foundation</h2>
                        <div className="col-lg-6 d-lg-none d-block mt-4 mb-4">
                            <img src="/images/creatives/mehfil-one.png" alt="Haircut" className="img-responsive" />
                        </div>
                        <p className='para_main'>Convallis vivamus at cras porta Convallis vivamus at cras porta Convallis vivamus at cras porta Convallis</p>

                        {/* <Link to="/ads">
                            <button className='connect-button'>Read More</button>
                        </Link> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EventsNews;