import React from 'react'
import FeedbackForm from '../components/FeedbackForm/FeedbackForm '

const FeedbackPage = () => {
    return (
        <div>
            <FeedbackForm
            background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat"/>
        </div>
    )
}

export default FeedbackPage