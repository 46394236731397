import React, { useState } from 'react';
import './CommonComponentsStylings.css';

const Checkbox = ({ label, id, required, disabled, isChecked, onChange }) => {
  const [checked, setChecked] = useState(isChecked || false);

  const handleCheckboxChange = (event) => {
    const newValue = !checked;
    setChecked(newValue);
    if (onChange) {
      onChange(event, newValue);
    }
  };

  return (
    <div className="checkbox-wrapper">
      <input
        type="checkbox"
        id={id}
        disabled={disabled}
        checked={checked}
        onChange={handleCheckboxChange}
      />
      <label htmlFor={label}>{label}{required ? <span style={{ color: 'red' }}>*</span> : ''}</label>
    </div>
  );
};

export default Checkbox; 