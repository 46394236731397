import React from 'react'
import CommonHeroComponent from '../components/CommonHeroComponent/CommonHeroComponent'
import VisionAndMission from '../components/VisionAndMission/VisionAndMission'

const FitrahPage = () => {
  return (
    <div>
      <CommonHeroComponent
        Heading="Construction of Mosque"
        Para="Help us build and maintain mosques, creating places of worship and community for Muslims."
        backgroundImg="/images/creatives/Construction-Mosque-Hero.jpg"
      />

      <VisionAndMission heading="About" bluespan=" Mosque Construction" Para="Building mosques is a highly rewarding deed in Islam, as these are places where Muslims gather to pray, learn, and grow spiritually. Raza Foundation is dedicated to constructing mosques in underserved areas, providing communities with sacred spaces for worship." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
        image="/images/creatives/Construction-Mosque-One.png"
        button="Donate Now"
        background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" />

      <VisionAndMission flexdirection="row-reverse" heading="How Your Mosque Construction" bluespan=" Donation Adds Value" Para="Donating to mosque construction through Raza Foundation helps create lasting places of worship and community gathering. This noble act is greatly rewarded in Islam, fostering unity and spiritual growth. Your contributions help build mosques that serve as pillars of faith for generations."
        button="Donate Now"
        image="/images/creatives/Construction-Mosque-Two.png" />

      <VisionAndMission heading="Ease of Donating for Mosque" bluespan=" Construction and Its Impact" Para="Donating for mosque construction is made simple and effective by Raza Foundation. Our efficient process ensures your contributions are used for their intended purpose. By supporting mosque construction, you contribute to the spiritual and communal well-being of Muslim communities, gaining immense spiritual rewards." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
        image="/images/creatives/Construction-Mosque-Three.png"
        button="Donate Now" />
    </div>
  );
};

export default FitrahPage;