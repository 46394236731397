import React, { useState, useEffect } from 'react';
import './ZakatCalculatorSection.css';
import axios from 'axios';

const ZakatCalculatorSection = () => {
  const [goldPrices, setGoldPrices] = useState({});
  const [silverPrices, setSilverPrices] = useState({ price_per_10_grams: 2584 });
  const [usdToPkr, setUsdToPkr] = useState(0);
  const [loading, setLoading] = useState(true);
  const [totalCash, setTotalCash] = useState('');
  const [totalGold, setTotalGold] = useState('');
  const [totalSilver, setTotalSilver] = useState('');
  const [totalLiabilities, setTotalLiabilities] = useState('');
  const [totalInvestments, setTotalInvestments] = useState('');
  const [totalTradeGoods, setTotalTradeGoods] = useState('');
  const [zakatAmount, setZakatAmount] = useState(0);

  const fetchGoldPrices = async () => {
    try {
      const goldResponse = await axios.get('https://www.goldapi.io/api/XAU/USD', {
        headers: {
          'x-access-token': 'goldapi-8bghcesm01cf91p-io',
          'Content-Type': 'application/json',
        },
      });

      const exchangeRateResponse = await axios.get('https://api.exchangerate-api.com/v4/latest/USD');

      const pkrRate = exchangeRateResponse.data.rates.PKR;
      setGoldPrices(goldResponse.data);
      setUsdToPkr(pkrRate);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGoldPrices();
  }, []);

  const convertToPkr = (usdPrice) => {
    return (usdPrice * usdToPkr).toFixed(2);
  };

  const calculateZakat = () => {
    const cash = parseFloat(totalCash) || 0;
    const gold = parseFloat(totalGold) || 0;
    const silver = parseFloat(totalSilver) || 0;
    const liabilities = parseFloat(totalLiabilities) || 0;
    const investments = parseFloat(totalInvestments) || 0;
    const tradeGoods = parseFloat(totalTradeGoods) || 0;

    const netWorth = cash + gold + silver + investments + tradeGoods - liabilities;

    const zakat = netWorth * 0.025;
    setZakatAmount(zakat);
  };

  return (
    <div className='container'>
      <div className="zakat-container">
        <h1 className="head-h1">Zakat <span className='span-black'>Calculator</span></h1>
        <p className="para_main mb-5">
          Zakat is the amount of money that every adult, mentally stable, free, and financially able Muslim, male and female, has to pay to support specific categories people. This category of people is defined in surah at-Taubah (9) verse 60: “The alms are only...
        </p>
        <div className="prices-container">
          <div className="gold-prices">
            <h2 className='head-h1'>Gold Prices (According to Carets)</h2>
            <div className='row prices para_main'>
              {loading ? (
                <p>Loading...</p>
              ) : (
                <>
                  <p className='col-lg-3'>24K -  (Rs. {convertToPkr(goldPrices.price_gram_24k)})</p>
                  <p className='col-lg-3'>22K -  (Rs. {convertToPkr(goldPrices.price_gram_22k)})</p>
                  <p className='col-lg-3'>21K -  (Rs. {convertToPkr(goldPrices.price_gram_21k)})</p>
                  <p className='col-lg-3'>20K -  (Rs. {convertToPkr(goldPrices.price_gram_20k)})</p>
                  <p className='col-lg-3'>18K -  (Rs. {convertToPkr(goldPrices.price_gram_18k)})</p>
                </>
              )}
            </div>
          </div>
          <div className="silver-prices">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <>
                <h2 className='head-h1'>Silver Prices</h2>
                <p className='para_main'>Pure Silver - 10 Grams (Rs. {(silverPrices.price_per_10_grams)})</p>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="zakat-calculation-container">
        <div className='input-container-main'>
          <h1 className="zakat-title">Zakat Calculation</h1>
          <div className="input-container-for-zakat">
            <div className="input-group">
              <label>Total Cash</label>
              <input
                type="text"
                placeholder="Rs"
                value={totalCash}
                onChange={(e) => setTotalCash(e.target.value)}
              />
            </div>
            <div className="input-group">
              <label>Total Value of Gold</label>
              <input
                type="text"
                placeholder="Rs"
                value={totalGold}
                onChange={(e) => setTotalGold(e.target.value)}
              />
            </div>
            <div className="input-group">
              <label>Total Value of Silver</label>
              <input
                type="text"
                placeholder="Rs"
                value={totalSilver}
                onChange={(e) => setTotalSilver(e.target.value)}
              />
            </div>
            <div className="input-group">
              <label>Total Liabilities Value (eg. Wages, Credit money, rent, etc.)</label>
              <input
                type="text"
                placeholder="Rs"
                value={totalLiabilities}
                onChange={(e) => setTotalLiabilities(e.target.value)}
              />
            </div>
            <div className="input-group">
              <label>Total Investments Value (Stocks, Prize Bonds, Property, etc.)</label>
              <input
                type="text"
                placeholder="Rs"
                value={totalInvestments}
                onChange={(e) => setTotalInvestments(e.target.value)}
              />
            </div>
            <div className="input-group">
              <label>Total Value of Trade Goods</label>
              <input
                type="text"
                placeholder="Rs"
                value={totalTradeGoods}
                onChange={(e) => setTotalTradeGoods(e.target.value)}
              />
            </div>
            <div className="zakat-result">
              <span className="zakat-payable-label">Zakat Payable</span>
              <span className="zakat-payable-amount">Rs. {zakatAmount.toFixed(2)}/-</span>
              <button className="calculate-button" onClick={calculateZakat}>
                Calculate Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ZakatCalculatorSection;
