import React, { useState, useEffect } from 'react';
import "./CommonComponentsStylings.css"

const MultiSelect = ({ options, defaultValues, onChange, name, Label }) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    setSelectedValues(defaultValues);
  }, [defaultValues]);

  const handleSelectChange = (value) => {
    let updatedValues;
    if (selectedValues.includes(value)) {
      updatedValues = selectedValues.filter(v => v !== value);
    } else {
      updatedValues = [...selectedValues, value];
    }
    setSelectedValues(updatedValues);
    onChange(updatedValues, name = name);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className='w-100 h-100 d-flex flex-column align-items-center justify-content-end py-2 multi-select-main'>
      {/* <select className='w-100 multi-select-inp' onClick={toggleDropdown}>
        <option value="" disabled selected hidden>{Label} </option>
    </select> */}
      <div className='w-100 multi-select-inp d-flex align-items-center justify-content-between px-2' onClick={toggleDropdown}>
        <p>{Label}</p>
        <img src='/images/icons/dropdown-icon.svg' alt="arrow icon" />
      </div>
      {isDropdownOpen && (
        <div className="dropdown-menu">
          {options.map(option => (
            <div key={option.value} className="dropdown-item">
              <input
                type="checkbox"
                checked={selectedValues.includes(option.value)}
                onChange={() => handleSelectChange(option.value)}
              />
              <p className='mb-0'>{option.label}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
