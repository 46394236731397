import React from 'react';
import Business from '../components/KhidmateKhalq/Business';

const BusinessPage = () => {
  return (
    <div>
      <Business />
    </div>
  );
};

export default BusinessPage;