import React from 'react';
import "./Footer.css";
import { NavLink, useNavigate } from 'react-router-dom';

function Footer() {

  const navigate = useNavigate();

  return (
    <>
      <footer className="footer">
        <div className='container'>

          <div className='upper-section'>
            <div className="footer-section">

              <img src="/images/icons/Raza-Foundation-Logo.png" alt="Website Logo" className="logo" />
              <p className="footer-text"> Raza Foundation is committed to making a meaningful impact through your generosity. Together, we can uplift communities, provide essential services, and create a brighter future for all. </p>

              <div className="social-icons">
                <a href="https://www.facebook.com/RazaFoundationPakistan?mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
                  <img src="/images/icons/Group-1885.svg" alt="Facebook" className="logo-left" /> </a>
                <a href="https://www.instagram.com/raza_foundation_?igsh=a2Vzb2ZnbnU2Yndw" target="_blank" rel="noopener noreferrer">
                  <img src="/images/icons/Group-1884.svg" alt="Facebook" className="logo-left" /></a>
                {/* <a href="https://youtube.com/@amediachannel?feature=shared" target="_blank" rel="noopener noreferrer">
                  <img src="/images/icons/footer-youtube-icon.svg" alt="Facebook" className="logo-left w-60" /></a> */}
              </div>
            </div>

            {/* Part 2: Pages list */}
            <div className="footer-sect">

              <ul className='ul-segment'>
                <h2 className='head-h1'>QuickLinks</h2>
                <li onClick={() => navigate("/contact")}>Contact</li>
                <li onClick={() => navigate("/blogs")}>Blog</li>
                <li onClick={() => navigate("/Videos")}>Media</li>
                <li onClick={() => navigate("/ask-a-mufti")}>Ask a Mufti</li>
                <li onClick={() => navigate("/privacy-policy")}>Privacy Policy</li>
                <li onClick={() => navigate("/refund-policy")}>Refund Policy</li>
              </ul>
            </div>
            {/* <div className="footer-second-section">

              <ul className='ul-segment-second'>
                <h2 className='head-h1'>Terms & Updates</h2>
                <li><a href="#">Refund Policy</a></li>
                <li><a href="#">Privacy Policy</a></li>
              </ul>
            </div> */}

            <div className="footer-section-right">
              <h2 className='head-h1'>Get In Touch</h2>
              <p className='specific-paragraph'>

                <img src="/images/creatives/contact-email.svg" alt="Envelope" className="contact-icon" />
                <a href="mailto:razafoundationwelfare@gmail.com" className="contact-link">razafoundationwelfare@gmail.com</a>
              </p>
              <p className='specific-paragraph'>
                <img src="/images/creatives/contact-phone.svg" alt="Phone" className="contact-icon" />
                <a href="tel:03442140396, 03413041304" className="contact-link">03442140396, 03413041304</a>
              </p>
              <p className='specific-paragraph'>
                <img src="/images/creatives/contact-location.svg" alt="Map Marker" className="contact-icon" />
                <a href="https://www.google.com/maps/place/Raza+Foundation/@24.8936395,67.1984844,17z/data=!3m1!4b1!4m6!3m5!1s0x3eb3375b7fe190ad:0x8ec6a0b48fe8335c!8m2!3d24.8936395!4d67.1984844!16s%2Fg%2F11f0w_b_62?entry=ttu&g_ep=EgoyMDI0MDgyMy4wIKXMDSoASAFQAw%3D%3D"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="contact-link"
                >
                  H Area, Saudabad, Malir, Karachi, Sindh 75080
                </a>
              </p>
            </div>

          </div>



          {/* <div className="blog-subfooter">
            <hr className="divider" />
            <div className="footer-content">
              <p className="p-text">Made With ❤️ By <a href="https://www.creativesquad.ca" target="blank">Creative Squad </a></p>
              <p className="p-text text-center">&copy; 2024 HB Care Canada  |  All rights reserved</p>

              <div className="social-icons-bottom">
                <img src="/images/Icon-awesome-cc-visa.svg" alt="Facebook" className="icons" />
                <img src="/images/Icon-awesome-stripe.svg" alt="Instagram" className="icons" />
                <img src="/images/Icon-awesome-paypal.svg" alt="LinkedIn" className="icons" />
                <img src="/images/Icon-awesome-cc-mastercard.svg" alt="LinkedIn" className="icons" />
                <img src="/images/Icon-awesome-amazon-pay.svg" alt="Twitter" className="icons" />

              </div>

            </div>

          </div> */}





        </div>
      </footer>
      <div className='py-3' style={{ background: "white" }}>
        <div className='d-flex align-items-center gap-3 justify-content-center w-100 container' >
          {/* <p className="mb-0">Made With ❤️ By <a href="https://www.creativesquad.ca" target="blank" style={{color:"#717C93",textDecoration:"none"}}>Creative Squad </a></p> */}
          <p className='body-paragraph text-center mb-0' style={{ fontSize: "14px", color: "#717C93", padding: "0px 10px", textAlign: "center" }}>Copyright © Raza Foundation 2025 | All Right Reserved</p>
        </div>
      </div>
    </>
  );
};

export default Footer;