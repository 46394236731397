import React from 'react';
import { Container, Card, Row } from 'react-bootstrap';
import './EventsSlider.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Navigation, Pagination, Parallax } from "swiper/modules";
import 'swiper/css/pagination';

function EventsSlider() {
    const cards = [
        {
            title: "Hajj",
            text: "Education",
            button: "Tue, Aug 27 2024",
            image: "/images/creatives/services-kaaba.png",
            para: "Convallis vivamus at cras porta",
            link: "#"
        },
        {
            title: "Qurbani",
            text: "Education",
            button: "Tue, Aug 27 2024",
            image: "/images/creatives/services-cow.png",
            para: "Convallis vivamus at cras porta",
            link: "/ads"
        },
        {
            title: "Rabi-awal",
            text: "Education",
            button: "Tue, Aug 27 2024",
            image: "/images/creatives/EidMiladunNabi.webp",
            para: "Convallis vivamus at cras porta",
            link: "/ads"
        },
        {
            title: "Khamt-e-Nabuwat",
            text: "Education",
            button: "Tue, Aug 27 2024",
            image: "/images/creatives/Khatm-e-Naboowat.webp",
            para: "Convallis vivamus at cras porta",
            link: "/ads"
        },
        {
            title: "Mehfil",
            text: "Education",
            button: "Tue, Aug 27 2024",
            image: "/images/creatives/Mehfil.webp",
            para: "Convallis vivamus at cras porta",
            link: "/ads"
        },
        {
            title: "Qurbani",
            text: "Education",
            button: "Tue, Aug 27 2024",
            image: "/images/creatives/services-cow.png",
            para: "Convallis vivamus at cras porta",
            link: "/ads"
        },
        {
            title: "Rabi-awal",
            text: "Education",
            button: "Tue, Aug 27 2024",
            image: "/images/creatives/EidMiladunNabi.webp",
            para: "Convallis vivamus at cras porta",
            link: "/ads"
        },
        {
            title: "Khamt-e-Nabuwat",
            text: "Education",
            button: "Tue, Aug 27 2024",
            image: "/images/creatives/Khatm-e-Naboowat.webp",
            para: "Convallis vivamus at cras porta",
            link: "/ads"
        },
    ];

    return (
        <div className='container event-slider-container'>
            <div className='event-slider-text'>
                <h2 className="head-h1 text-center">Events</h2>
                <p className='para_main text-center py-4'>
                    Convallis vivamus at cras porta nibh velit aliquam eget in faucibus mi tristique aliquam ultrices sit cras nascetur Convallis vivamus at Convallis vivamus at cras porta nibh velit aliquam eget in faucibus mi tristique aliquam ultrices
                </p>
            </div>

            <Container className='event-slider mt-2'>
                <Row>
                    <Swiper
                        spaceBetween={50}
                        // navigation={{
                        //     nextEl: '.swiper-button-next',
                        //     prevEl: '.swiper-button-prev',
                        // }}
                        loop={true}
                        autoplay={{
                            delay: 1500,
                            disableOnInteraction: false
                        }}
                        modules={[Autoplay, Navigation, Parallax]}
                        // pagination={{ clickable: true }}
                        observer={true}
                        observeParents={true}
                        parallax={true}
                        breakpoints={{
                            280: {
                                slidesPerView: 1,
                            },
                            992: {
                                slidesPerView: 3,
                            }
                        }}
                        className="card-list mt-5"
                        id="articles-cards-slider">
                        {cards.map((card, index) => (
                            <SwiperSlide key={index}>
                                <Card style={{ border: 'none' }}>
                                    <Card.Img variant="top" src={card.image} />
                                    <Card.Body className='card-body text-lg-start text-center'>
                                        <button className='connect-button mb-4'>{card.button}</button>
                                        <Card.Title className='card-title'>{card.title}</Card.Title>
                                    </Card.Body>
                                </Card>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Row>
            </Container>
        </div>
    );
}

export default EventsSlider;
