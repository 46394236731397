import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import "./AskAMuftiForm.css";
import CommonCalls from '../../components/CommonAPICalls/CommonCalls';
import { showAlertMessage } from '../MasterComponents/CommonFunctionalities';

function AskAMuftiForm() {
     const [name, setName] = useState("");
     const [email, setEmail] = useState("");
     const [subject, setSubject] = useState("");
     const [message, setMessage] = useState("");
        const [success, setSuccess] = useState(false);
        const [error, setError] = useState(false);
        const [loading, setLoading] = useState(false);
        const [messageLoading, setMessageLoading] = useState(false);
        const [pageNumber, setPageNumber] = useState(1);
        const [previousMessages, setPreviousMessages] = useState([]);
        const navigate = useNavigate();
    
        const apiCall = new CommonCalls();
    
        const formSubmitHandler = async (e) => {
            e.preventDefault();
            setLoading(true);
    
            const payload = { question: message };
    
            try {
                const response = await apiCall.sendQuestion(payload);
                if (response.status === 201) {
                    setError(false);
                    setLoading(false);
                    setMessage("");
                } else {
                    setLoading(false);
                    setMessage("");
                }
            } catch (error) {
                setLoading(false);
                setMessage("");
            }
        };
        const RecieveMessagesFromServer = async () => {
            setMessageLoading(true);
    
            try {
                const response = await apiCall.getAllQuestions(pageNumber, 3,true);
                if (response.status === 200) {
                    setPreviousMessages(response?.data,"success")
                    setMessageLoading(false);
                } else {
                    showAlertMessage(response.msg, 'fail');
                    setMessageLoading(false);
                }
            } catch (error) {
                showAlertMessage("Could not load previous message", 'fail');
                setMessageLoading(false);
            }
        };

        useEffect(()=>{
            RecieveMessagesFromServer()
        },[pageNumber])
    
  return (
    <div className="AskAMuftiForm-container py-5">
      <div className="container py-2">
        <h3 className="mb-4 body-heading" style={{color:"#1F2F54"}}>Ask your question from scholar below:</h3>
        <div className="row gy-lg-0 gy-4">
          <div className="col-lg-7">
            <form
              className="inp-container d-flex flex-column align-items-start gap-4"
              onSubmit={formSubmitHandler}
            >
              {/* <input
                required
                type="text"
                value={name}
                maxLength="56"
                onChange={(e) => setName(e.target.value)}
                placeholder="Your Name"
              />
              <input
                required
                type="email"
                value={email}
                maxLength="56"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Your Email Address"
              />
              <input
                required
                type="text"
                value={subject}
                maxLength="56"
                onChange={(e) => setSubject(e.target.value)}
                placeholder="Subject"
              /> */}
              <textarea
                required
                type="text"
                value={message}
                rows={7}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Question"
              />
              <button
                className="connect-button"
                type="submit"
                disabled={loading}
              >
                Send
                {loading ? (
                  <Spinner
                    animation="border"
                    role="status"
                    style={{
                      color: "white",
                      width: "15px",
                      height: "15px",
                      marginLeft: "10px",
                    }}
                  />
                ) : success ? (
                  <div>
                    {setLoading(false)}
                    {alert(success)}
                    {setSuccess(false)}
                  </div>
                ) : (
                  error && (
                    <div>
                      {setLoading(false)}
                      {alert(error)}
                      {setError(false)}
                    </div>
                  )
                )}
              </button>
            </form>
          </div>
          <div className="col-lg-5 px-3">
            <div className='previous-question-box p-4'>
                <div className='d-flex align-items-center gap-3'>
                    <img src='/images/icons/chat-icon.png' alt="chat icon" style={{maxWidth:"70px"}} />
                    <h3 className='mb-0'>Recent Questions & Answers</h3>
                </div>

                <div className='message-container'>
                  
                    {
                        messageLoading
                        ? 
                        <div className='d-flex align-items-center justify-content-center' style={{height:"200px"}}>
                          <Spinner></Spinner>
                        </div>
                        :
                        <>
                            {
                                previousMessages.length>0 
                                &&
                                <>
                                {
                                    previousMessages.map((message)=>{
                                        return(
                                        <div className='message-box p-3 my-2'>
                                            <h6 style={{color:"#1972BE"}}>Q: {message?.question}</h6>
                                            <p className='main-para mb-0'>A: {message?.answer}</p>
                                        </div>
                                        )
                                    })
                                }

                               

                                </>
                                
                            }

                                <div className='pagination-btn d-flex align-items-center gap-2 mt-3'>
                                    {
                                            pageNumber !==1 ?
                                            <img onClick={() => setPageNumber(prev => (prev > 1 ? prev - 1 : prev))} src='/images/icons/arrow-icon.png' alt='arrow icon' style={{width:"35px",borderRadius:"50%",padding:"10px",background:"grey",cursor:"pointer"}} />
                                            :
                                            <img src='/images/icons/arrow-icon.png' alt='arrow icon' style={{width:"35px",borderRadius:"50%",padding:"10px",background:"#E2E2E2",cursor:"not-allowed"}} />
                                    }
                                    
                                    {previousMessages.length>0 && previousMessages.length==3 ?
                                    <img onClick={()=>{setPageNumber((prev)=>{return prev+=1 })}} src='/images/icons/arrow-icon.png' alt='arrow icon' style={{width:"35px",borderRadius:"50%",padding:"10px",background:"grey",transform:"rotate(180deg)",cursor:"pointer"}} />
                                    :
                                    <img src='/images/icons/arrow-icon.png' alt='arrow icon' style={{width:"35px",borderRadius:"50%",padding:"10px",background:"#E2E2E2",transform:"rotate(180deg)",cursor:"not-allowed"}} />
                                    }
                                </div>
                        </>

                    }

                </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AskAMuftiForm