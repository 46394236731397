import React from 'react'
import CommonHeroComponent from '../components/CommonHeroComponent/CommonHeroComponent'
import VisionAndMission from '../components/VisionAndMission/VisionAndMission'

const NafilPage = () => {
  return (
    <div>
      <CommonHeroComponent
        Heading="Nafil & Sadaqa"
        Para="Contribute your nafil donations to help us expand our outreach and provide more services to those in need.Your sadaqa contributions help us reach more people with essential services, making a real difference in their lives."
        backgroundImg="/images/creatives/Nafil-Hero.jpg"
      />

      {/* <VisionAndMission heading="About" bluespan=" Nafil" Para="Nafil, or voluntary charity, goes beyond obligatory almsgiving and reflects a higher level of generosity and compassion. It is an act of extra devotion, showcasing a true commitment to helping others. At Raza Foundation, we channel your Nafil donations to support various humanitarian projects, spreading love and care where it's needed most." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
        image="/images/creatives/Nafil-One.png"
        button="Donate Now"
        background="transparent linear-gradient(313deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" /> */}

      <VisionAndMission flexdirection="row-reverse" heading="How Your Nafil " bluespan=" Donation Adds Value" Para="Your Nafil donations to Raza Foundation empower us to expand our reach and provide more services to those in need. This act of voluntary charity reflects the essence of Islamic teachings, promoting kindness and generosity. Every Nafil contribution helps fund essential projects, from education to healthcare, enhancing lives and communities." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
        button="Donate Now"
        image="/images/creatives/Nafil-Two.png" />


      <VisionAndMission heading="How Your Sadaqa" bluespan=" Donation Adds Value" Para="Donating Sadaqa through Raza Foundation helps fund essential services like education, healthcare, and disaster relief. This voluntary act of charity aligns with Islamic values of generosity and kindness. Your Sadaqa contributions bring hope and support to those in need, making a meaningful impact." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
        image="/images/creatives/Sadaqa-Two.png"
        button="Donate Now" />


      <VisionAndMission flexdirection="row-reverse" heading="Ease of Donating " bluespan=" Nafil and Its Impact" Para="Donating Nafil is easy with Raza Foundation's user-friendly platform. Your voluntary contributions, no matter how small, can make a significant difference. By giving Nafil, you not only help others but also enrich your own spiritual journey, embodying the true spirit of Islam." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
        image="/images/creatives/Nafil-Three.png"
        button="Donate Now" />

      <VisionAndMission heading="Ease of Donating" bluespan=" Sadaqa and Its Impact" Para="Donating Sadaqa is simple with Raza Foundation's user-friendly platform. Your contributions, no matter how small, can make a big difference. By giving Sadaqa, you not only help others but also purify your wealth and earn spiritual rewards, embodying the true spirit of Islam." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
        image="/images/creatives/Sadaqa-Three.png"
        button="Donate Now" />
    </div>
  )
}

export default NafilPage;