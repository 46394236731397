import "./CardBoxServicesSection.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Navigation, Pagination, Parallax } from "swiper/modules";
import CardBox from "./CardBox";

// const handlePrevButtonClick = () => {
//   const swiperInstance = document.querySelector('#articles-cards-slider').swiper;
//   swiperInstance.slidePrev();
// };

// const handleNextButtonClick = () => {
//   const swiperInstance = document.querySelector('#articles-cards-slider').swiper;
//   swiperInstance.slideNext();
// };

function CardTestimonial({
  subHeading,
  heading,
  content,
  spanHeading,
  buttons
}) {

  const blogData = [
    {
      cardImg: "/images/creatives/services-cow.png",
      title: "QURBANI",
      anchor: "/donations",
      content:
        "Join us in our Qurbani services to ensure your sacrifice reaches the needy, providing essential nourishment during Eid.",
      buttons: ""
    },
    {
      cardImg: "/images/creatives/services-mosque.png",
      title: "CONSTRUCTION OF MASJID",
      anchor: "/donations",
      content:
        "Help us build and maintain mosques, creating places of worship and community for Muslims.",
      buttons: "Donate Now"
    },
    {
      cardImg: "/images/creatives/services-kaaba.png",
      title: "HAJJ",
      anchor: "/donations",
      content:
        "Support others in fulfilling their Hajj obligations through our sponsored programs.",
      buttons: ""
    },
    {
      cardImg: "/images/creatives/Zakat.webp",
      title: "ZAKAT",
      anchor: "/donations",
      content:
        "Your zakat helps us support various projects aimed at uplifting the underprivileged, including education, healthcare, and community development.",
      buttons: "Donate Now"
    },
    {
      cardImg: "/images/creatives/Nafil.webp",
      title: "NAFIL",
      anchor: "/donations",
      content:
        "Contribute your nafil donations to help us expand our outreach and provide more services to those in need.",
      buttons: "Donate Now"
    },
    {
      cardImg: "/images/creatives/Fitra.webp",
      title: "FITRAH",
      anchor: "/donations",
      content:
        "Ensure your Fitrah reaches those in need, providing essential support during Ramadan and Eid.",
      buttons: "Donate Now"
    },
    {
      cardImg: "/images/creatives/Education.webp",
      title: "EDUCATION",
      anchor: "/donations",
      content:
        "We provide education to poor and needy children, helping them build a better future through knowledge and skills.",
      buttons: ""
    },
    {
      cardImg: "/images/creatives/Food.webp",
      title: "Food",
      anchor: "/donations",
      content:
        "We provide education to poor and needy children, helping them build a better future through knowledge and skills.",
      buttons: ""
    },
    {
      cardImg: "/images/creatives/Global-Relief.webp",
      title: "GLOBAL-RELIEF",
      anchor: "/donations",
      content:
        "Your donations support our global relief efforts, providing aid to those affected by natural disasters and other crises worldwide.",
      buttons: ""
    },
    {
      cardImg: "/images/creatives/Health.webp",
      title: "Health",
      anchor: "/donations",
      content:
        "Your donations support our global relief efforts, providing aid to those affected by natural disasters and other crises worldwide.",
      buttons: ""
    },
    {
      cardImg: "/images/creatives/Welfare.webp",
      title: "Welfare",
      anchor: "/donations",
      content:
        "Your donations support our global relief efforts, providing aid to those affected by natural disasters and other crises worldwide.",
      buttons: ""
    },
    {
      cardImg: "/images/creatives/Mehfil.webp",
      title: "MEHFIL",
      anchor: "/donations",
      content:
        "Support our mehfil events that bring communities together in celebration and remembrance of religious and cultural traditions.",
      buttons: ""
    },
    {
      cardImg: "/images/creatives/Khidmat-e-Khalq.webp",
      title: "KHIDMAT-E-KHALQ",
      anchor: "/donations",
      content:
        "Join us in our mission of serving humanity, providing essential services and support to those in need.",
      buttons: ""
    },
    {
      cardImg: "/images/creatives/Khatm-e-Naboowat.webp",
      title: "KHATAM-E-NABUWAT",
      anchor: "/donations",
      content:
        "Honoring the Finality of Prophethood, we uphold the core tenet of Islam that Prophet Muhammad (PBUH) is the last messenger. Join us in preserving and promoting this vital belief.",
      buttons: ""
    },
    {
      cardImg: "/images/creatives/EidMiladunNabi.webp",
      title: "EID MILAD-UN-NABI",
      anchor: "/donations",
      content:
        "Fasting on Mondays for Milad-un-Nabi honors the Prophet Muhammad (Allah's Grace and Peace be upon him) and marks his birth and the Quran’s revelation.",
      buttons: ""
    },
    {
      cardImg: "/images/creatives/Sadaqa.webp",
      title: "SADAQA",
      anchor: "/donations",
      content:
        "Your sadaqa contributions help us reach more people with essential services, making a real difference in their lives.",
      buttons: ""
    },
    {
      cardImg: "/images/creatives/COS.webp",
      title: "COS",
      anchor: "/donations",
      content:
        "Our COS programs focus on reaching out to communities, understanding their needs, and providing personalized support and services.",
      buttons: ""
    },
    {
      cardImg: "/images/creatives/Guardian-service.webp",
      title: "Guardian",
      anchor: "/donations",
      content:
        "Guardian is an initiative by Raza Foundation to protect and support vulnerable members of society.",
      buttons: ""
    },
    {
      cardImg: "/images/creatives/Financial-Aid-service.webp",
      title: "Financial Aid",
      anchor: "/donations",
      content:
        "The Financial Aid program by Raza Foundation offers crucial support to those facing financial hardships.",
      buttons: ""
    },
    {
      cardImg: "/images/creatives/Plantation-service.webp",
      title: "Plantation",
      anchor: "/donations",
      content:
        "The Plantation initiative by Raza Foundation is aimed at promoting a greener environment through large-scale tree planting efforts",
      buttons: ""
    },
    {
      cardImg: "/images/creatives/Masjid-Madarsa-Construction.webp",
      title: "Masjid and Madrasa Construction",
      anchor: "/donations",
      content:
        "Raza Foundation's Masjid and Madrasa Construction initiative is dedicated to building and renovating places of worship and learning across communities.",
      buttons: ""
    },
    {
      cardImg: "/images/creatives/Corona-Relief-service.webp",
      title: "Corona Relief",
      anchor: "/donations",
      content:
        "Raza Foundation's Corona Relief initiative provides critical support to those affected by the global pandemic.",
      buttons: ""
    },
    {
      cardImg: "/images/creatives/Thar-Parkar.webp",
      title: "Thar Parker",
      anchor: "/donations",
      content:
        "Raza Foundation’s Thar Parker initiative is committed to providing essential aid to one of Pakistan's most impoverished regions.",
      buttons: ""
    },
    {
      cardImg: "/images/creatives/balochistan-service.webp",
      title: "Balochistan",
      anchor: "/donations",
      content:
        "Raza Foundation’s Balochistan initiative focuses on addressing the basic needs of this underserved province.",
      buttons: ""
    },
    {
      cardImg: "/images/creatives/Dastarkhowan-Tharparker-service.webp",
      title: "Dastarkhan Thar Parker",
      anchor: "/donations",
      content:
        "Through the Dastarkhan initiative in Thar Parker, Raza Foundation provides daily meals to the most vulnerable communities in this drought-stricken region.",
      buttons: ""
    },
    {
      cardImg: "/images/creatives/Rashan-Distribution-service.webp",
      title: "Rashan Distribution",
      anchor: "/donations",
      content:
        "Raza Foundation’s Rashan Distribution program aims to provide essential food supplies to underprivileged families across Pakistan.",
      buttons: ""
    },
    {
      cardImg: "/images/creatives/Family-Support-service.webp",
      title: "Family Support",
      anchor: "/donations",
      content:
        "Raza Foundation’s Family Support program is dedicated to providing financial assistance and resources to families facing economic hardships.",
      buttons: ""
    },
    {
      cardImg: "/images/creatives/RF-COS.webp",
      title: "COS (Children Orientation System)",
      anchor: "/donations",
      content:
        "The COS (Children Orientation System) aims to provide holistic educational and spiritual guidance to children, helping them develop a strong foundation in Islamic values and modern education.",
      buttons: ""
    },
    {
      cardImg: "/images/creatives/Islamic-History-services.webp",
      title: "Islamic History",
      anchor: "/donations",
      content:
        "The Islamic History service aims to preserve and share the rich legacy of Islam’s past, educating people about the important events, figures, and teachings that shaped the Islamic world.",
      buttons: ""
    },
    {
      cardImg: "/images/creatives/Education-In-Quran-services.webp",
      title: "Educated in Quran",
      anchor: "/donations",
      content:
        "The Educated in Quran service focuses on providing comprehensive Quranic education to individuals of all ages, helping them develop a deep understanding and connection with the holy book.",
      buttons: ""
    },
    {
      cardImg: "/images/creatives/Medicine-service.webp",
      title: "Medicine",
      anchor: "/donations",
      content:
        "The Medicine service at Raza Foundation provides essential healthcare and medical support to those in need, ensuring that everyone has access to life-saving treatments and medicines.",
      buttons: ""
    },
    {
      cardImg: "/images/creatives/OPD-service.webp",
      title: "OPD",
      anchor: "/donations",
      content:
        "The OPD (Outpatient Department) service at Raza Foundation provides accessible and affordable medical consultations and treatments to those in need.",
      buttons: ""
    },
    {
      cardImg: "/images/creatives/Consultaion-Physician-services.webp",
      title: "Consultations Physician",
      anchor: "/donations",
      content:
        "Our Consultations Physician service at Raza Foundation offers expert medical advice and personalized treatment plans to individuals seeking professional healthcare.",
      buttons: ""
    },
    {
      cardImg: "/images/creatives/Consultation-Specialist-services.webp",
      title: "Consultation Specialist",
      anchor: "/donations",
      content:
        "The Consultation Specialist service at Raza Foundation provides access to expert specialists for in-depth medical evaluations and tailored treatment plans.",
      button: ""
    },
    {
      cardImg: "/images/creatives/Khana-Dehlis-service.webp",
      title: "Khana Delhi’s (Food on Doorstep)",
      anchor: "/donations",
      content:
        "At Raza Foundation, we ensure that everyone receives nutritious meals right at their doorstep through our Khana Delhi’s service.",
      buttons: ""
    },
    {
      cardImg: "/images/creatives/Langar-Khana.webp",
      title: "Langur Khana - Thar Parker",
      anchor: "/donations",
      content:
        "Langur Khana in Thar Parker is a vital initiative by Raza Foundation aimed at providing essential food resources to the underprivileged communities in the Thar Desert.",
      buttons: ""
    },
  ];

  return (
    <div className='cards-container standard-padding-space'>
      <div className="blog-upper-content">
        <div className="container">
          <div className="row d-flex justify-content-between align-items-end">
            <div className="col-md-6">
              <div className="blog-text-content">
                <p className="secondary-h1 d-lg-block d-none">{subHeading}</p>
                <h3 className="head-h1 mb-2">{heading}<span className="color-yellow">{spanHeading}</span></h3>
                {content && <p className="body-paragraph">{content}</p>}

              </div>
            </div>

            <div className="col-md-6 services-content-class">
              <p className="secondary-h1 d-lg-none">{subHeading}</p>
              <p className="para_main">From education and health to mosque construction and global relief, our services transform lives. Discover how your donations can create lasting change.</p>
              {/* <button className="connect-button  mt-3">Learn more</button> */}
            </div>
          </div>

          <Swiper
            spaceBetween={50}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            loop={true}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false
            }}
            modules={[Autoplay, Navigation, Parallax]}
            pagination={{ clickable: true }}
            observer={true}
            observeParents={true}
            parallax={true}
            breakpoints={{
              280: {
                slidesPerView: 1,
              },
              992: {
                slidesPerView: 3,
              }
            }}
            className="card-list mt-5 px-3 text-center"
            id="articles-cards-slider"
          >
            {blogData.map((blogData, ind) => (
              <SwiperSlide key={ind}>
                <CardBox cardImg={blogData.cardImg} title={blogData.title} content={blogData.content} anchor={blogData.anchor} buttons={blogData.buttons} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default CardTestimonial