import React from 'react';
import CardGrid from '../components/CardGrid/CardGrid';
import LatestNews from '../components/LatestNews/LatestNews';
import CommonHeroComponent from '../components/CommonHeroComponent/CommonHeroComponent';
import NewsLetter from '../components/NewsLetter/NewsLetter';


const Blogs = () => {
  return (
    <div>
      <CommonHeroComponent
        Heading="OUR BLOGS"
        Para="Discover the Heartbeat of Our Mission Through Inspiring Stories and Insights!"
        backgroundImg="/images/creatives/blog-main-banner.jpg" />
      <LatestNews />
      <CardGrid />
      <NewsLetter background="transparent linear-gradient(38deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" />
    </div>
  );
};

export default Blogs;