import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Links1, Links2, Links3 } from "../Navigationlinks";
import './Mobilenav.css'
import Modal from '../../components/Model/Modal';
import LoginSignup from "../LoginSignup/LoginSignup";
import Signup from "../SignupComponent/Signup";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/actions/userActions"
import { showAlertMessage } from "../MasterComponents/CommonFunctionalities";
import { useNavigate } from "react-router-dom";

const Mobilenav = (props) => {
  const [selected, setSelected] = useState(false);
  const [selected2, setSelected2] = useState(false);
  const [selected3, setSelected3] = useState(false);
  const [selected4, setSelected4] = useState(false);
  const [selected5, setSelected5] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loginpressed, setLoginpressed] = useState(false);
  const [menuState, setMenuState] = useState(false);
  const [isDropdownOpenDesk, setIsDropdownOpenDesk] = useState(false);
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [changeArrowcolor, setChangeArrowColor] = useState(false);
  const [OpenPortalDropdown, setOpenPortalDropdown] = useState(false);

  const userLoginStatus = JSON.parse(localStorage.getItem("userInfo"));
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const logoutHandler = () => {
    showAlertMessage("Logout Successfully ", "success")
    dispatch(logout());
    window.location.reload(false);
  };

  const serviceList = useSelector((state) => state.serviceList);
  const { services } = serviceList;

  useEffect(() => {
    setLoading(serviceList?.loading)
  }, [serviceList]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const dropdownRef = useRef();

  let transfer = props.transfer;
  const mobiledropdown = (state) => {
    setSelected(!state);
  };
  const mobiledropdown2 = (state) => {
    setSelected2(!state);
    setSelected3(false);
    setSelected4(false);
    setSelected5(false);
  };
  const mobiledropdown3 = (state) => {
    setSelected3(!state);
    setSelected2(false);
    setSelected4(false);
    setSelected5(false);
  };
  const mobiledropdown4 = (state) => {
    setSelected4(!state);
    setSelected5(false);
    setSelected3(false);
    setSelected2(false);
  };

  const mobiledropdown5 = (state) => {
    setSelected5(!state);
    setSelected4(false);
    setSelected3(false);
    setSelected2(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && isDropdownOpenDesk) {
        setIsDropdownOpenDesk(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isDropdownOpenDesk]);

  const menuClick = () => {
    setMenuState((prev) => !prev);
  };

  const handleLinkClick = () => {
    setSelected(false);
    setSelected2(false)
    setSelected3(false)
    setMenuState(false);
    props.hamburgerdropdown(transfer);
  };

  return (
    <>
      <div
        style={{ top: props.linear && "71px", zIndex: "9999999999" }}
        className={
          transfer === true ? "  mobilenav_cs1" : "  mobilenavclicked_cs1"
        }
      >
        <ul style={{height:"90vh",overflowY:"auto"}}>
          <div className={props.landingPage && 'd-none'}>
            <div className="d-flex justify-content-between align-content center navitems_cs1">
              <li style={{ color: "white" }}>
                <Link onClick={() => props.hamburgerdropdown(transfer)} to="/"> Home </Link>
              </li>


              {/* <i
                onClick={() => mobiledropdown(selected)}
                class="fa-solid fa-chevron-up"
                style={{
                  transform:
                    selected === true ? "rotate(0deg)" : "rotate(180deg)",
                  transition: "0.3s",
                  color: selected === true ? "#CB1212" : "white",
                }}
              ></i> */}
            </div>
            <div className={`d-flex justify-content-between align-content center navitems_cs1 ${props.landingPage && 'd-none'}`}>
              <li>
                <Link onClick={() => props.hamburgerdropdown(transfer)} to="/about">About</Link>
              </li>
            </div>

            <div className="d-flex justify-content-between align-content center navitems_cs1">
              <li style={{ color: "white" }}>
                <Link onClick={() => mobiledropdown(selected)} > Departments </Link>
              </li>


              <i
                onClick={() => mobiledropdown(selected)}
                class="fa-solid fa-chevron-up"
                style={{
                  transform:
                    selected === true ? "rotate(0deg)" : "rotate(180deg)",
                  transition: "0.3s",
                  color: selected === true ? "#A5CD37" : "white",
                }}
              ></i>
            </div>

            <div
              className={
                selected === true ? "mobsubmenu_cs1 show" : "mobsubmenu_cs1"
              }
            >

              <div>
                <h6 className="d-flex justify-content-between align-content-center">
                  {" "}
                  <li
                    style={{ color: selected2 === true ? "#A5CD37" : "white" }}
                  >
                    {
                      services ? (
                        <>
                          {services
                            .filter(item => item.web_displayed === true)
                            .map((item) => (
                              <Link
                                to={`${item.service_slug_url}`}
                                className="nav-links font-play dropdown-item text-start"
                                onClick={handleLinkClick}
                                key={item.services_id}
                              >
                                <p className="itemsof-dropdown mb-2">{item.service_name}</p>
                              </Link>
                            ))}
                        </>
                      ) : <></>
                    }

                  </li>

                </h6>
                <div
                  className={
                    selected2 === true
                      ? "mobsubmenu_cs1 show"
                      : "mobsubmenu_cs1"
                  }
                >
                  <ul id="ul2_cs1" onClick={handleLinkClick}>
                    {Links1.map((e) => (
                      <li>
                        <Link to={e.path}>{e.title}</Link>{" "}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div>
                {" "}
                <h6 className="d-flex justify-content-between align-content-center">
                  {" "}
                </h6>
                <div
                  className={
                    selected3 === true
                      ? "mobsubmenu_cs1 show"
                      : "mobsubmenu_cs1"
                  }
                >
                  <ul id="ul2_cs1" onClick={handleLinkClick}>
                    {Links2.map((e) => (
                      <li>
                        <Link to={e.path}>{e.title}</Link>{" "}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div>
                <div
                  className={
                    selected4 === true
                      ? "mobsubmenu_cs1 show"
                      : "mobsubmenu_cs1"
                  }
                >
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between align-content center navitems_cs1">
            <li style={{ color: "white" }}>
              <Link onClick={() => mobiledropdown3(selected3)} > Media </Link>
            </li>


            <i
              onClick={() => mobiledropdown3(selected3)}
              class="fa-solid fa-chevron-up"
              style={{
                transform:
                  selected3 === true ? "rotate(0deg)" : "rotate(180deg)",
                transition: "0.3s",
                color: selected3 === true ? "#A5CD37" : "white",
              }}
            ></i>
          </div>
          <div
            className={
              selected3 === true ? "mobsubmenu_cs1 show" : "mobsubmenu_cs1"
            }
          >

            <div>
              {/* <h6 className="d-flex justify-content-between align-content-center">
                  {" "}
                  <li
                    style={{ color: selected2 === true ? "#A5CD37" : "white" }}
                  >
                    {
                      services ? (
                        <>
                          {services
                            .filter(item => item.web_displayed === true)
                            .map((item) => (
                              <Link
                                to={`${item.service_slug_url}`}
                                className="nav-links font-play dropdown-item text-start"
                                onClick={handleLinkClick}
                                key={item.services_id}
                              >
                                <p className="itemsof-dropdown mb-2">{item.service_name}</p>
                              </Link>
                            ))}
                        </>
                      ) : <></>
                    }

                  </li>

                </h6> */}
              <div
                className={
                  selected3 === true
                    ? "mobsubmenu_cs1 show"
                    : "mobsubmenu_cs1"
                }
              >
                <ul id="ul2_cs1" onClick={handleLinkClick}>
                  {Links3.map((e) => (
                    <li>
                      <Link to={e.path}>{e.title}</Link>{" "}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div>
              {" "}
              <h6 className="d-flex justify-content-between align-content-center">
                {" "}
              </h6>
              {/* <div
                className={
                  selected3 === true
                    ? "mobsubmenu_cs1 show"
                    : "mobsubmenu_cs1"
                }
              >
                <ul id="ul2_cs1" onClick={handleLinkClick}>
                  {Links2.map((e) => (
                    <li>
                      <Link to={e.path}>{e.title}</Link>{" "}
                    </li>
                  ))}
                </ul>
              </div> */}
            </div>

            {/* <div>
              <div
                className={
                  selected4 === true
                    ? "mobsubmenu_cs1 show"
                    : "mobsubmenu_cs1"
                }
              >
              </div>
            </div> */}
          </div>

          <div className={`d-flex justify-content-between align-content center navitems_cs1 ${props.landingPage && 'd-none'}`}>
            <li>
              <Link onClick={() => props.hamburgerdropdown(transfer)} to="/blogs">Blogs</Link>
            </li>
          </div>

          <div className={`d-flex justify-content-between align-content center navitems_cs1 ${props.landingPage && 'd-none'}`}>
            <li>
              <Link onClick={() => props.hamburgerdropdown(transfer)} to="/ask-a-mufti">Ask a Mufti</Link>
            </li>
          </div>
          <div className={`d-flex justify-content-between align-content center navitems_cs1 ${props.landingPage && 'd-none'}`}>
            <li>
              <Link onClick={() => props.hamburgerdropdown(transfer)} to="/contact">Contact</Link>
            </li>
          </div>

          <div className="d-flex justify-content-between align-content center navitems_cs1">
            <li style={{ color: "white" }}>
              <Link onClick={() => mobiledropdown2(selected2)} > Type of Donation </Link>
            </li>


            <i
              onClick={() => mobiledropdown2(selected2)}
              class="fa-solid fa-chevron-up"
              style={{
                transform:
                  selected2 === true ? "rotate(0deg)" : "rotate(180deg)",
                transition: "0.3s",
                color: selected2 === true ? "#A5CD37" : "white",
              }}
            ></i>
          </div>
          <div
            className={
              selected2 === true ? "mobsubmenu_cs1 show" : "mobsubmenu_cs1"
            }
          >

            <div>
              {/* <h6 className="d-flex justify-content-between align-content-center">
                  {" "}
                  <li
                    style={{ color: selected2 === true ? "#A5CD37" : "white" }}
                  >
                    {
                      services ? (
                        <>
                          {services
                            .filter(item => item.web_displayed === true)
                            .map((item) => (
                              <Link
                                to={`${item.service_slug_url}`}
                                className="nav-links font-play dropdown-item text-start"
                                onClick={handleLinkClick}
                                key={item.services_id}
                              >
                                <p className="itemsof-dropdown mb-2">{item.service_name}</p>
                              </Link>
                            ))}
                        </>
                      ) : <></>
                    }

                  </li>

                </h6> */}
              <div
                className={
                  selected2 === true
                    ? "mobsubmenu_cs1 show"
                    : "mobsubmenu_cs1"
                }
              >
                <ul id="ul2_cs1" onClick={handleLinkClick}>
                  {Links1.map((e) => (
                    <li>
                      <Link to={e.path}>{e.title}</Link>{" "}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div>
              {" "}
              <h6 className="d-flex justify-content-between align-content-center">
                {" "}
              </h6>
              {/* <div
                className={
                  selected3 === true
                    ? "mobsubmenu_cs1 show"
                    : "mobsubmenu_cs1"
                }
              >
                <ul id="ul2_cs1" onClick={handleLinkClick}>
                  {Links2.map((e) => (
                    <li>
                      <Link to={e.path}>{e.title}</Link>{" "}
                    </li>
                  ))}
                </ul>
              </div> */}
            </div>

            {/* <div>
              <div
                className={
                  selected4 === true
                    ? "mobsubmenu_cs1 show"
                    : "mobsubmenu_cs1"
                }
              >
              </div>
            </div> */}
          </div>

          {userLoginStatus?.token ? (
            <>
              <div className='d-flex align-items-center gap-1'>
                <p className='title mb-0 user' style={{ color: "white" }}>
                  {userLoginStatus?.full_name}
                </p>

                <div
                  className="navigation-dropdown"
                  ref={dropdownRef}
                  onMouseEnter={() => setOpenPortalDropdown(true)}
                >
                  <div
                    className="dropdown-trigger d-flex align-items-center"
                    onMouseOver={() => setChangeArrowColor(true)}
                    onMouseOut={() => setChangeArrowColor(false)}
                  >
                    <img
                      alt='user icon'
                      className='menu-icon'
                      src='/images/icons/profile-icon.svg'
                    />
                    <img
                      src={`/images/icons/icon-arrow.svg`}
                      className={OpenPortalDropdown ? "rotate-icon arrow-icon" : "rotate-back arrow-icon"}
                    />
                  </div>

                  <div
                    className={`dropdown-content dropdown-content-profile ${OpenPortalDropdown ? 'open' : 'none-delay'}`}
                    onMouseEnter={() => setOpenPortalDropdown(true)}
                    onMouseLeave={() => setOpenPortalDropdown(false)}
                  >
                    <div
                      className="nav-links font-Open-Sans p-2"
                      onClick={() => navigate("/portal")}
                    >
                      <p className="mb-0" style={{ textTransform: "uppercase" }}>Portal</p>
                    </div>
                    <div
                      className="nav-links font-Open-Sans p-2"
                      onClick={() => {
                        setOpenPortalDropdown(false);
                        logoutHandler();
                      }}
                    >
                      <p className="mb-0" style={{ textTransform: "uppercase" }}>Logout</p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className={`d-flex justify-content-start mobilebtn_cs1 ${props.landingPage ? 'd-none' : ''}`}>
              <div style={{ width: "70%" }} className="button2_cs_1">
                <button
                  style={{ width: "100%", borderRadius: "4px" }}
                  className="button2_cs_1"
                  onClick={() => {
                    setLoginpressed(true);
                    openModal();
                  }}
                >
                  Login
                </button>
                <button
                  style={{ width: "100%", borderRadius: "4px" }}
                  className="button2_cs_1"
                  onClick={() => {
                    setLoginpressed(false);
                    openModal();
                  }}
                >
                  SignUp
                </button>
                <Modal status={isModalOpen} closeModal={closeModal}>
                  {loginpressed ? <LoginSignup closeModal={closeModal} /> : <Signup closeModal={closeModal} />}
                </Modal>
              </div>
            </div>
          )}
          {props.landingPage &&
            <div className={`d-flex justify-content-start mobilebtn_cs1`}>
              <a
                style={{ width: "70%" }}
                className="button2_cs_1"
              >
                <button
                  style={{ width: "100%", borderRadius: "4px" }}
                  className="button2_cs_1"
                  href=""
                >
                  Chat With Us
                </button>
              </a>
            </div>
          }
          {props.landingPage &&
            <div className={`d-flex justify-content-start mobilebtn_cs1`}>
              <a
                style={{ width: "70%" }}
                className="button2_cs_1"
                href="tel: +1-647-860-5083"
              >
                <button
                  style={{ width: "100%", borderRadius: "4px" }}
                  className="button2_cs_1"
                  href=""
                >
                  Get Started
                </button>
              </a>
            </div>
          }
        </ul>
      </div>
    </>
  );
};

export default Mobilenav;
