import React from 'react';
import CommonHeroComponent from '../components/CommonHeroComponent/CommonHeroComponent';
import VisionAndMission from '../components/VisionAndMission/VisionAndMission';
import NewsLetter from '../components/NewsLetter/NewsLetter';

const HajjPage = () => {
    return (
        <div>
            <CommonHeroComponent
                Heading="HAJJ"
                Para="Support others in fulfilling their Hajj obligations through our sponsored programs."
                backgroundImg="/images/creatives/hajj-banner.jpg" />

            <VisionAndMission heading="About" bluespan=" Hajj" Para="Hajj is a sacred pilgrimage and one of the five pillars of Islam. For those unable to perform it due to financial constraints, sponsoring Hajj is a noble deed. At Raza Foundation, we facilitate Hajj sponsorships, ensuring that more Muslims can fulfill this vital religious obligation." bluepara="🙶 Take from their money a charity to cleanse them and purify them – Al Qur’an 9:103 🙷"
                image="/images/creatives/hajj-one.png" 
              />

            <VisionAndMission flexdirection="row-reverse" heading="Full Fill The Dreams Of" bluespan=" less fortunate to Perform Hajj" Para="Sponsoring Hajj through Raza Foundation allows you to help fellow Muslims experience the spiritual journey of a lifetime. This generous act is highly rewarded in Islam and contributes to the collective spiritual growth of the Ummah. Your donations help cover travel, accommodation, and other expenses, making Hajj accessible to those who dream of it."
                image="/images/creatives/hajj-two.png" 
               />

            <VisionAndMission bluespan=" `Labaik Allahuma Labaik`" Para="Sponsoring Hajj is made simple with Raza Foundation. Our transparent and efficient process ensures that your contributions are used effectively. By enabling others to perform Hajj, you partake in their spiritual rewards, creating a profound and lasting impact on their lives and yours."
                image="/images/creatives/hajj-three.png" 
               />

<NewsLetter background="transparent linear-gradient(38deg, #FFFFFF00 0%, #FFFFFE00 60%, #A5CD375C 100%, #bbff00 100%) 0% 0% no-repeat" />
        </div>
    )
}

export default HajjPage